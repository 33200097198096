import React, { useState, useEffect, useRef } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Collapse,
} from "reactstrap";
import { Select } from "antd";
import { Spinner } from "reactstrap";
import axios from "axios";
import { baseUrl, baseUrl2 } from "../../../config/config";
import Swal from "sweetalert2";
import classNames from "classnames";
import { object } from "yup";

function ModuleMod({ history, toggle, modal }) {
  const { Option } = Select;
  const token = window.localStorage.getItem("adminToken");
  const myConfig = {
    headers: {
      uit: token,
    },
  };

  const [count, setCount] = useState("");
  const [clientmulti, setClientmulti] = useState();
  const [compMulti, setCompMulti] = useState([]);
  const [formCont, setFormCont] = useState([]);
  const [clinetList, setClinetList] = useState([]);
  const [formarray, setFormarray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [permOption, setPermOption] = useState([]);
  const [stats, setStats] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [allList, setAllList] = useState([]);
  const { handleSubmit, register, errors, reset, control } = useForm();
  const [disable, setDisable] = useState(false);
  const [allSub, setAllsub] = useState([]);
  const [oneData, setOneData] = useState([]);
  const [accessAr, setAccessAr] = useState([]);
  const [tempList, setTempList] = useState([]);
  const [activeSelect, setActiveSelect] = useState({
    id: "",
    accounting: "0",
    corporate: "0",
    direct_tax: "0",
    indirect_tax: "0",
    other: "0",
  });
  const [noAdded, setNoAdded] = useState(0);

  //add form field
  const addFormField = () => {
    setNoAdded(noAdded + 1);

    if (clinetList) {
      const extractedIds = oneData.map((data) => data.client_id);
      const filteredClientList = clinetList.filter(
        (client) => !extractedIds.includes(client.id)
      );

      // console.log('filteredClientList',filteredClientList);
      // console.log('formFields',formFields);

      const filteredArray = filteredClientList.filter(
        (obj) => !formFields.some((item) => item.custId === obj.id)
      );
      setAllList(filteredArray);
      // console.log("filteredArray=>", filteredArray);

      const arrayOfArrays = [];

      // if(tempList.length === 0){
      //   const arrayOfArrays = [filteredClientList];
      //   setTempList(arrayOfArrays)
      //   console.log('00000');
      // }else{
      //   const arrayOfArrays = [...tempList];
      //   arrayOfArrays.push(filteredArray);
      //   setTempList(arrayOfArrays)
      //   console.log('11111');
      // }

      if (permOption.length === 0) {
        const arrayOfArrays = [filteredClientList];
        setPermOption(arrayOfArrays);
        // console.log("00000", arrayOfArrays);
      } else {
        const arrayOfArrays = [...permOption];
        arrayOfArrays.push(filteredArray);
        setPermOption(arrayOfArrays);
        // console.log("11111", arrayOfArrays);
      }
      setFormFields([
        ...formFields,
        {
          custId: "",
          direct: "",
          indirect_tax: "",
          corporate: "",
          accounting: "",
          other: "",
        },
      ]);
    }
  };

  // useEffect(() => {
  //   console.log("noAdded=>", noAdded);
  // }, [noAdded]);

  // useEffect(() => {
  //   console.log("tempList=>", tempList);
  // }, [tempList]);

  // useEffect(() => {
  //   console.log("permOption=>", permOption);
  // }, [permOption]);

  useEffect(() => {
    // console.log("accessAr=>", accessAr);
    if (accessAr.length > 0) {
      mix();
    }
  }, [accessAr]);

  const removeFormField = (index) => {
    setNoAdded(noAdded - 1);
    // console.log("index=>", index);
    setFormFields((prevFormFields) => {
      const updatedFormFields = [...prevFormFields];
      updatedFormFields.splice(index, 1);
      return updatedFormFields;
    });
    const newArray = [...permOption];
    newArray.splice(index, 1);
    setPermOption(newArray);
  };
  //   console.log('permOption=>',permOption);

  useEffect(() => {
    if (modal == true) {
      getClientData();
      getTlinfo();
    } else {
      setActiveSelect({
        id: "",
        accounting: "0",
        corporate: "0",
        direct_tax: "0",
        indirect_tax: "0",
        other: "0",
      });
      setOneData([]);
      setFormFields([]);
      setAccessAr([]);
      setPermOption([]);
    }
    setAllsub([
      {
        id: "1",
        value: "Direct Tax",
        name: "direct_tax",
        status: true,
      },
      {
        id: "2",
        value: "Indirect Tax",
        name: "indirect_tax",
        status: false,
      },
      {
        id: "3",
        value: "Corporate",
        name: "corporate",
        status: false,
      },
      {
        id: "4",
        value: "Accounting",
        name: "accounting",
        status: false,
      },
      { id: "5", value: "Others", name: "other", status: false },
    ]);
    setFormarray([
      {
        clientId: "",
        direct_tax: "",
        indirect_tax: "",
        corporate: "",
        accounting: "",
        other: "",
      },
    ]);
  }, [modal]);

  useEffect(() => {
    if (history) {
      //   console.log("history", history);
      getTlinfo();
    }
  }, [history]);

  //   useEffect(() => {
  //     console.log('formfiled',formFields);
  //   }, [formFields])

  //   useEffect(() => {
  //     console.log("secCrif", secCrif);
  //   }, [secCrif]);

  //   useEffect(() => {
  //     console.log('clinetList=>',clinetList);
  //    }, [clinetList])

  //    useEffect(() => {
  //     console.log("compMulti=>", compMulti);
  //   }, [compMulti]);

  // useEffect(() => {
  //   console.log("UseaccessAr=>", accessAr);
  //   console.log("UseclinetList=>", clinetList);
  //   if (accessAr && clinetList) {
  //   }
  // }, [accessAr, clinetList]);

  // useEffect(() => {
  //   console.log("oneData=>", oneData);
  // }, [oneData]);

  //   useEffect(() => {
  //     console.log("fields=>", fields);
  //   }, [fields]);

  //   useEffect(() => {
  //     console.log("stats=>", stats);
  //   }, [stats]);

  useEffect(() => {
    //   console.log("clinetList=>", clinetList);
    if (oneData) {
      const extractedIds = oneData.map((data) => data.id);
      const filteredClientList = clinetList.filter(
        (client) => !extractedIds.includes(client.id)
      );
    }
  }, [clinetList]);

  const mix = () => {
    if (accessAr) {
      const newArray2 = accessAr;
      const arrayWith9Length = newArray2.filter(
        (obj) => Object.keys(obj).length > 4
      );
      const arrayWithout9Length = newArray2.filter(
        (obj) => Object.keys(obj).length < 5
      );

      const updatedArray = arrayWith9Length.map((obj) => {
        const updatedObj = { ...obj };
        if (obj.other === "1") {
          updatedObj.other = true;
        } else {
          updatedObj.other = false;
        }
        if (obj.accounting === "1") {
          updatedObj.accounting = true;
        } else {
          updatedObj.accounting = false;
        }
        if (obj.corporate === "1") {
          updatedObj.corporate = true;
        } else {
          updatedObj.corporate = false;
        }
        if (obj.direct_tax === "1") {
          updatedObj.direct_tax = true;
        } else {
          updatedObj.direct_tax = false;
        }
        if (obj.indirect_tax === "1") {
          updatedObj.indirect_tax = true;
        } else {
          updatedObj.indirect_tax = false;
        }
        return updatedObj;
      });

      const withStatus = updatedArray.map((obj) => {
        return {
          ...obj,
          status: false,
        };
      });
      setOneData(withStatus);
    } else {
      // getTlinfo();
    }
  };

  const getTlinfo = () => {
    axios
      .get(`${baseUrl}/admin/tlcompliancelist?tl_id=${history.id}`, myConfig)
      .then((res) => {
        // console.log("gettingTLaccess=>", res.data.result);
        if (res.data.code == 1) {
          let allac = res.data.result;
          setAccessAr(allac);
        }else{
          setOneData([])
        }
      });
  };

  const getClientData = () => {
    axios.get(`${baseUrl}/admin/allcomplianceclient`, myConfig).then((res) => {
      // console.log("gettingClientList=>", res.data.result);
      let resultList = res.data.result;
      setClinetList(resultList);
    });
  };

  // const gettldata = (id) => {
  //   axios
  //     .get(`${baseUrl}/admin/tlcompliancelist?tl_id=${id}`, myConfig)
  //     .then((res) => {
  //       console.log("gettingClientList=>", res.data.result);
  //       let resultList = res.data.result;
  //     });
  // };

  const handleChange = (index, e) => {
    // console.log('e.tar=>',e.target.value);
    // const { name, value } = e.target;

    const clientId = e.target.value;

    const clientL = clinetList;

    // console.log('clinetListhandleChange==>',clinetList);

    // console.log("indexhandleChange==>", index, "ehandleChange==>", clientId);

    const updatedFormFields = [...formFields];
    updatedFormFields[index] = {
      ...updatedFormFields[index],
      custId: e.target.value,
    };
    setFormFields(updatedFormFields);

    const clientData = clientL.find((client) => client.id === clientId);

    setActiveSelect(clientData);
  };

  useEffect(() => {
    console.log("activeSelecthandleChange==>", activeSelect["direct_tax"]);
    return () => {};
  }, [activeSelect]);

  const handleSubCategory = (f, e, iden) => {
    // console.log("f=>", f, "subcat=>", e.target, "id", iden);
    const { name, checked } = e.target;

    // let key =name;

    // console.log("name", name, "checked", checked);

    const updateValueForKey = (obj, keyToUpdate, newValue) => {
      return Object.keys(obj).reduce((acc, key) => {
        // If the current key matches the key to update, update its value
        // Otherwise, keep the original value
        acc[key] = key === keyToUpdate ? newValue : obj[key];
        return acc;
      }, {});
    };

    setOneData((prevState) =>
      prevState.map((item) => {
        let key = name;
        const keys = Object.keys(item);
        const updatedItem = { ...keys };
        if (item.id === iden) {
          const updatedObjArray = updateValueForKey(item, name, checked);
          // console.log("updatedObjArray", updatedObjArray);
          return updatedObjArray;
        } else {
          return item;
        }
      })
    );
  };

  const handleDirect = (index, e) => {
    let value = e.target.checked;
    // console.log("value", value);
    const updatedFormFields = [...formFields];
    updatedFormFields[index] = {
      ...updatedFormFields[index],
      direct: value,
    };
    setFormFields(updatedFormFields);
  };
  const handleIndirect = (index, e) => {
    let value = e.target.checked;
    const updatedFormFields = [...formFields];
    updatedFormFields[index] = {
      ...updatedFormFields[index],
      indirect_tax: value,
    };
    setFormFields(updatedFormFields);
  };
  const handleCorporate = (index, e) => {
    let value = e.target.checked;
    const updatedFormFields = [...formFields];
    updatedFormFields[index] = {
      ...updatedFormFields[index],
      corporate: value,
    };
    setFormFields(updatedFormFields);
  };
  const handleaccount = (index, e) => {
    let value = e.target.checked;
    const updatedFormFields = [...formFields];
    updatedFormFields[index] = {
      ...updatedFormFields[index],
      accounting: value,
    };
    setFormFields(updatedFormFields);
  };
  const handleother = (index, e) => {
    let value = e.target.checked;
    const updatedFormFields = [...formFields];
    updatedFormFields[index] = {
      ...updatedFormFields[index],
      other: value,
    };
    setFormFields(updatedFormFields);
  };

  // useEffect(() => {
  //   console.log("formFields=>", formFields);
  // }, [formFields]);

  const onSubmiting = (index) => {
    setLoading(true)
    // console.log("f0", index);

    // gettldata(history.id);

    // const newData = formFields.find(item => item.id === 1);

    const newData = formFields[index];

    // const newData = value[1];

    // console.log("newData", newData);

    let formData = new FormData();

    // formData.append("id", '');
    formData.append("client_id", newData.custId);
    formData.append("tl_id", history.id);
    if (newData.direct === true) {
      formData.append("direct_tax", 1);
    } else {
      formData.append("direct_tax", 0);
    }
    if (newData.indirect_tax === true) {
      formData.append("indirect_tax", 1);
    } else {
      formData.append("indirect_tax", 0);
    }
    if (newData.corporate === true) {
      formData.append("corporate", 1);
    } else {
      formData.append("corporate", 0);
    }
    if (newData.accounting === true) {
      formData.append("accounting", 1);
    } else {
      formData.append("accounting", 0);
    }
    if (newData.other === true) {
      formData.append("other", 1);
    } else {
      formData.append("other", 0);
    }

    // formData.append("client_id", newData.clientid);
    // formData.append("tl_id", history.id);
    // if(newData.direct_tax === 'true'){
    //     formData.append("direct_tax",1);
    // }else{
    //     formData.append("direct_tax",0);
    // }
    // if(newData.indirect_tax === 'true'){
    //     formData.append("indirect_tax",1);
    // }else{
    //     formData.append("indirect_tax",0);
    // }
    // if(newData.corporate === 'true'){
    //     formData.append("corporate",1);
    // }else{
    //     formData.append("corporate",0);
    // }
    // if(newData.accounting === 'true'){
    //     formData.append("accounting",1);
    // }else{
    //     formData.append("accounting",0);
    // }
    // if(newData.other === 'true'){
    //     formData.append("other",1);
    // }else{
    //     formData.append("other",0);
    // }

    // console.log("formDataTL=>", formData);
    axios
      .post(`${baseUrl2}/v1/admin/tlcomplianceassing`, formData, {
        headers: {
          "content-type": "multipart/form-data",
          uit: token,
        },
      })
      .then((res) => {
        if (res.data.code === 1) {
          Swal.fire({
            title: "success",
            html: "Compliance status changed successfully",
            icon: "success",
          });
          setStats(true);
          getTlinfo();
          removeFormField(index);
          setLoading(false)
        } else if (res.data.code === 0) {
          setLoading(false)
        } else {
        }
      });
  };

  const save = (id) => {
    let idToFilter = id;

    const filterObjectById = (array, idToFilter) => {
      return array.filter((obj) => obj.client_id === idToFilter);
    };

    const filteredArray = filterObjectById(oneData, idToFilter);
    const tosave = filteredArray[0];

    Swal.fire({
      title: "Are you sure?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then((result) => {
      if (result.isConfirmed == true) {
        // console.log("tosave", idToFilter, tosave);
        let formData = new FormData();
        formData.append("id", tosave.id);
        formData.append("client_id", tosave.client_id);
        formData.append("tl_id", tosave.tl_id);
        if (tosave.direct_tax === true) {
          formData.append("direct_tax", 1);
        } else {
          formData.append("direct_tax", 0);
        }
        if (tosave.indirect_tax === true) {
          formData.append("indirect_tax", 1);
        } else {
          formData.append("indirect_tax", 0);
        }
        if (tosave.corporate === true) {
          formData.append("corporate", 1);
        } else {
          formData.append("corporate", 0);
        }
        if (tosave.accounting === true) {
          formData.append("accounting", 1);
        } else {
          formData.append("accounting", 0);
        }
        if (tosave.other === true) {
          formData.append("other", 1);
        } else {
          formData.append("other", 0);
        }

        axios
          .post(`${baseUrl2}/v1/admin/tlcomplianceassing`, formData, {
            headers: {
              "content-type": "multipart/form-data",
              uit: token,
            },
          })
          .then((res) => {
            if (res.data.code === 1) {
              Swal.fire({
                title: "success",
                html: "Compliance status changed successfully",
                icon: "success",
              });
              //   setStats(true);
              getTlinfo();
            } else if (res.data.code === 0) {
              // Swal.fire({
              //   title: "success",
              //   html: "Compliance status changed successfully",
              //   icon: "success",
              // });
              // setStats(false);
            } else {
            }
          });
      }
    });
  };

  const showsub = (e) => {
    // console.log("e=>", e);

    const fo = oneData.find((item) => item.id === e);
    // console.log("fo=>", fo);
    setOneData((prevState) =>
      prevState.map((item) => {
        if (item.id === e) {
          return { ...item, status: true };
        }
        return { ...item, status: false };
      })
    );
    // setAllsub((prevState) =>
    //   prevState.map((item) => {
    //     return { ...item, status: false };
    //   })
    // );
    setAllsub((prevState) =>
      prevState.map((item) => {
        if (item.name === "direct_tax") {
          return { ...item, direct_tax: item.direct_tax };
        } else if (item.name === "indirect_tax") {
          return { ...item, indirect_tax: item.indirect_tax };
        } else if (item.name === "accounting") {
          return { ...item, accounting: item.accounting };
        } else if (item.name === "corporate") {
          return { ...item, corporate: item.corporate };
        } else {
          return { ...item, other: item.other };
        }
      })
    );
  };

  const hidesub = (e) => {
    // console.log("e=>", e);
    setOneData((prevState) =>
      prevState.map((item) => {
        if (item.id === e) {
          return { ...item, status: false };
        }
        return item;
      })
    );
  };

  const deleteCust = (e) => {
    // console.log("deteteing==>", e);

    Swal.fire({
      title: "Are you sure?",
      text: "It will permanently deleted !",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        const client_id = e.client_id;
        const tl_id = e.tl_id;
        const id = e.id;
        axios
          .get(
            `${baseUrl}/admin/tlcomplianceassingdelete?client_id=${client_id}&tl_id=${tl_id}&id=${id}`,
            myConfig
          )
          .then((res) => {
            if (res.data.code === 1) {
              Swal.fire({
                title: "success",
                html: "Client deleted successfully",
                icon: "success",
              });
              getTlinfo();
            } else {
              Swal.fire({
                title: "error",
                html: "Something went wrong , please try again",
                icon: "error",
              });
            }
          });
      }
    });
  };

  return (
    <div>
      <Modal isOpen={modal} fade={false} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}>
          <span className="ComplianceHeader">
            Client compliance access module.
            {/* <span className="ComplianceHeaderSpan">
              <span> Complinace : </span>
            </span> */}
          </span>
        </ModalHeader>
        <ModalBody
          style={{ maxHeight: "570px", overflowY: "auto", overflowX: "hidden" }}
        >
          <div className="compPrime row justify-content-start">
            <div className="compPrimeDet col-md-5">
              Team Leader Post-
              <span className="detailSpan">{history?.postname}</span>
            </div>
            <div className="compPrimeDet col-md-4">
              Team Leader Name-{" "}
              <span className="detailSpan">{history?.name}</span>
            </div>
            {/* <div className="compPrimeDet col-md-4">
              Team Leader Email-
              <span className="detailSpan">{history.email}</span>
            </div> */}
          </div>

          {/* {history != undefined ? ( */}
          <>
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: "50px" }}>S.No</th>
                  <th style={{ width: "140px" }}>Client</th>
                  <th style={{ width: "660px" }}>Compliance Category</th>
                  <th style={{ width: "50px" }}>
                    <button
                      type="button"
                      className="customBtn"
                      onClick={addFormField}
                    >
                      Add Client
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
            {formFields.map((field, index) => (
              <form onSubmit={handleSubmit()} key={index}>
                <div className="container dynform my-2 pb-1 pt-3">
                  <div className="row space-around " key={index}>
                    <div className="col-md-3 px-1">
                      <select
                        className={classNames("form-control", {
                          "is-invalid": errors.p_taxprof,
                        })}
                        // name="p_taxprof"
                        name={`client[${index}].clientid`}
                        ref={register}
                        onChange={(e) => handleChange(index, e)}
                        style={{ height: "40px" }}
                      >
                        <option value="">--Select client--</option>
                        {permOption[index].map((p, index) => (
                          <option key={index} value={p.id}>
                            {p?.user_id}
                          </option>
                        ))}
                      </select>
                      {errors.p_taxprof && (
                        <div className="invalid-feedback">
                          {errors.p_taxprof.message}
                        </div>
                      )}
                    </div>
                    <div className="col-md-7 px-1">
                      <div className="sub mb-3">
                        {/* {allSub?.map((i) => ( */}
                        {/* <input
                                type="hidden"
                                ref={register}
                                value={index}
                                name="index"
                                key={`index${index}`}
                              /> */}
                        <span className="subcheck">
                          <input
                            type="checkbox"
                            //   key={fields.id}
                            key={`direct_tax${index}`}
                            // name="direct_tax"
                            name={`client[${index}].direct_tax`}
                            // checked={true}
                            value={formFields[index].direct_tax}
                            ref={register}
                            id="hide"
                            disabled={
                              activeSelect[`direct_tax`] == 1 ? false : true
                            }
                            // checked={?.direct_tax}
                            onClick={(e) => handleDirect(index, e)}
                          ></input>
                          <label
                            htmlFor="hide"
                            style={{
                              pointerEvents: "none",
                            }}
                          >
                            Direct Tax
                          </label>
                        </span>
                        <span className="subcheck">
                          <input
                            type="checkbox"
                            // key={fields.id}
                            key={`indirect_tax${index}`}
                            // name="indirect_tax"
                            name={`client[${index}].indirect_tax`}
                            // checked={true}
                            // value={i.name}
                            ref={register}
                            id="hide"
                            disabled={
                              activeSelect[`indirect_tax`] == 1 ? false : true
                            }
                            onClick={(e) => handleIndirect(index, e)}
                          ></input>
                          <label
                            htmlFor="hide"
                            style={{
                              pointerEvents: "none",
                            }}
                          >
                            Indirect Tax
                          </label>
                        </span>
                        <span className="subcheck">
                          <input
                            type="checkbox"
                            // key={fields.id}
                            // name="corporate"
                            key={`corporate${index}`}
                            name={`client[${index}].corporate`}
                            // checked={true}
                            // value={i.name}
                            ref={register}
                            id="hide"
                            disabled={
                              activeSelect[`corporate`] == 1 ? false : true
                            }
                            onClick={(e) => handleCorporate(index, e)}
                          ></input>
                          <label
                            htmlFor="hide"
                            style={{
                              pointerEvents: "none",
                            }}
                          >
                            Corporate
                          </label>
                        </span>
                        <span className="subcheck">
                          <input
                            type="checkbox"
                            // key={fields.id}
                            // name="accounting"
                            key={`accounting${index}`}
                            name={`client[${index}].accounting`}
                            // checked={true}
                            // value={i.name}
                            ref={register}
                            id="hide"
                            disabled={
                              activeSelect[`accounting`] == 1 ? false : true
                            }
                            onClick={(e) => handleaccount(index, e)}
                          ></input>
                          <label
                            htmlFor="hide"
                            style={{
                              pointerEvents: "none",
                            }}
                          >
                            Accounting
                          </label>
                        </span>

                        <span className="subcheck">
                          <input
                            type="checkbox"
                            // key={fields.id}
                            // name="other"
                            key={`other${index}`}
                            name={`client[${index}].other`}
                            // checked={true}
                            // value={i.name}
                            ref={register}
                            id="hide"
                            disabled={activeSelect[`other`] == 1 ? false : true}
                            onClick={(e) => handleother(index, e)}
                          ></input>
                          <label
                            htmlFor="hide"
                            style={{
                              pointerEvents: "none",
                            }}
                          >
                            Others
                          </label>
                        </span>

                        {/* ))} */}
                      </div>
                    </div>
                    <div className="col-md-2 px-1">
                      <div
                        className="btn queryPlusIcon mx-3"
                        onClick={() => removeFormField(index)}
                      >
                        -
                      </div>
                      {loading == true ? (
                        <Spinner color="primary" className="ml-5" />
                      ) : (
                        <button
                        className="customBtn mx-1"
                        onClick={() => onSubmiting(index)}
                      >
                        Submit
                      </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            ))}
            <div className="modsavediv">
              {oneData?.map((item, index) => (
                <>
                  <table className="table">
                    <thead></thead>
                    <tbody>
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td style={{ width: "52px" }}>{index + 1}</td>
                          <td style={{ width: "140px" }}>{item?.user_id}</td>
                          <td style={{ width: "850px" }}>
                            <form onSubmit={handleSubmit()} autocomplete="off">
                              {item?.module_id?.length > 0 ? (
                                <input
                                  type="hidden"
                                  ref={register}
                                  value={item?.module_id}
                                  name="id"
                                />
                              ) : (
                                <input
                                  type="hidden"
                                  ref={register}
                                  value={``}
                                  name="id"
                                />
                              )}
                              <input
                                type="hidden"
                                ref={register}
                                value={item?.id}
                                name="subclient_id"
                              />
                              <input
                                type="hidden"
                                ref={register}
                                value={history.id}
                                name="client_id"
                              />
                              <div className="basicFeild compField row justify-content-around">
                                <div className="sub col-md-10 mb-3">
                                  {/* {allSub?.map((i) => (
                                <span>
                                  <input
                                    type="checkbox"
                                    name={i.name}
                                    checked={i.status}
                                    // value={i.name}
                                    ref={register}
                                    id="hide"
                                    disabled={disable}
                                    onClick={(e) => handleSubCategory(e)}
                                  ></input>
                                  <label
                                    htmlFor="hide"
                                    style={{ margin: "1px" }}
                                  >
                                    {i.value}
                                  </label>
                                </span>
                              ))} */}
                                  <span>
                                    <input
                                      type="checkbox"
                                      name="direct_tax"
                                      checked={item?.direct_tax}
                                      // value={item.direct_tax}
                                      ref={register}
                                      id="hide"
                                      disabled={
                                        item?.cdirect_tax == 1 ? false : true
                                      }
                                      onClick={(e) =>
                                        handleSubCategory(
                                          "direct_tax",
                                          e,
                                          item?.id
                                        )
                                      }
                                    ></input>
                                    <label
                                      htmlFor="hide"
                                      style={{
                                        margin: "1px",
                                        pointerEvents: "none",
                                      }}
                                    >
                                      Direct Tax
                                    </label>
                                  </span>
                                  <span>
                                    <input
                                      type="checkbox"
                                      name="indirect_tax"
                                      checked={item?.indirect_tax}
                                      // value={item.indirect_tax}
                                      ref={register}
                                      id="hide"
                                      disabled={
                                        item?.cindirect_tax == 1 ? false : true
                                      }
                                      onClick={(e) =>
                                        handleSubCategory(
                                          "indirect_tax",
                                          e,
                                          item?.id
                                        )
                                      }
                                    ></input>
                                    <label
                                      htmlFor="hide"
                                      style={{
                                        margin: "1px",
                                        pointerEvents: "none",
                                      }}
                                    >
                                      Indirect Tax
                                    </label>
                                  </span>
                                  <span>
                                    <input
                                      type="checkbox"
                                      name="corporate"
                                      checked={item?.corporate}
                                      // value={item.corporate}
                                      ref={register}
                                      id="hide"
                                      disabled={
                                        item?.ccorporate == 1 ? false : true
                                      }
                                      onClick={(e) =>
                                        handleSubCategory(
                                          "corporate",
                                          e,
                                          item?.id
                                        )
                                      }
                                    ></input>
                                    <label
                                      htmlFor="hide"
                                      style={{
                                        margin: "1px",
                                        pointerEvents: "none",
                                      }}
                                    >
                                      Corporate
                                    </label>
                                  </span>
                                  <span>
                                    <input
                                      type="checkbox"
                                      name="accounting"
                                      checked={item?.accounting}
                                      // value={item.accounting}
                                      ref={register}
                                      id="hide"
                                      disabled={
                                        item?.caccounting == 1 ? false : true
                                      }
                                      onClick={(e) =>
                                        handleSubCategory(
                                          "accounting",
                                          e,
                                          item?.id
                                        )
                                      }
                                    ></input>
                                    <label
                                      htmlFor="hide"
                                      style={{
                                        margin: "1px",
                                        pointerEvents: "none",
                                      }}
                                    >
                                      Accounting
                                    </label>
                                  </span>
                                  <span>
                                    <input
                                      type="checkbox"
                                      name="other"
                                      checked={item?.other}
                                      // value={item.other}
                                      ref={register}
                                      id="hide"
                                      disabled={
                                        item?.cother == 1 ? false : true
                                      }
                                      onClick={(e) =>
                                        handleSubCategory("other", e, item?.id)
                                      }
                                    ></input>
                                    <label
                                      htmlFor="hide"
                                      style={{
                                        margin: "1px",
                                        pointerEvents: "none",
                                      }}
                                    >
                                      Others
                                    </label>
                                  </span>
                                </div>
                                <button
                                  type="button"
                                  className="customBtn mx-sm-1 mb-2"
                                  style={{ height: "50%", width: "5rem" }}
                                  onClick={() => save(item.client_id)}
                                >
                                  Save
                                </button>
                              </div>
                            </form>
                          </td>
                          <td style={{ width: "28px" }}>
                            <div className="subcatCom mx-1 mt-2">
                              <span
                                className=""
                                onClick={() => deleteCust(item)}
                                key={item?.id}
                              >
                                <i
                                  class="fa fa-trash deleteIcon"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </tbody>
                  </table>
                </>
              ))}
            </div>
          </>
        </ModalBody>
        {/* <ModalFooter></ModalFooter> */}
      </Modal>
    </div>
  );
}

export default ModuleMod;
