import React, { useState, useEffect, useLayoutEffect } from "react";
// import Layout from "../../components/Layout/Layout";
import { Container, Grid, Paper, Box } from "@material-ui/core";
// import CrefPage from "./CrefPage";
import { Tab, Tabs, TabPanel, TabList } from "react-tabs";
import { useHistory } from "react-router-dom";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
// import LogoutHeader from "../CompliAny/LogoutHeader";
// import CustomHeading from "../Common/CustomHeading";
// import Cref from "../CompliAny/Cref";
// import CrefDec from "../CompliAny/CrefDec";
// import CrefPending from "../CompliAny/CrefPending";
// import CrefRec from "../CompliAny/CrefRec";
// import { clientLogout } from "../Logout/ClientLogout";
import Layout from "../../../components/Layout/Layout";
import tlLogout from "../TlLogout/TlLogout";
import PaperBox from "../../../components/PaperBox/PaperBox";

function TlCompliance(props) {
  let history = useHistory();
  const userid = window.localStorage.getItem("tlkey");
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [bgColor, setbgColor] = useState("#42566a");
  const [allPerms, setAllPerms] = useState([
    { name: "Direct Tax", granted: false },
    { name: "Indirect Tax", granted: false },
    { name: "Accounting", granted: false },
    { name: "Corporate", granted: false },
    { name: "Others", granted: false },
  ]);
  const tlpermor = window.localStorage.getItem("TlClientPerm");

  useEffect(() => {
    if (tlpermor) {
      const tlperm = JSON?.parse(tlpermor);
      setAllPerms((prevPerms) =>
        prevPerms.map((perm) => {
          switch (perm.name) {
            case "Direct Tax":
              return { ...perm, granted: tlperm.direct_tax === "1" };
            case "Indirect Tax":
              return { ...perm, granted: tlperm.indirect_tax === "1" };
            case "Accounting":
              return { ...perm, granted: tlperm.accounting === "1" };
            case "Corporate":
              return { ...perm, granted: tlperm.corporate === "1" };
            case "Others":
              return { ...perm, granted: tlperm.other === "1" };
            default:
              return perm;
          }
        })
      );
    }
  }, []);

  useEffect(() => {
    console.log("props==>", allPerms);
  }, [allPerms]);

  const tableIndex = (index) => {
    setTabIndex(index);
    // console.log(index);
    if (index === 0) {
      setbgColor("#42566a");
    } else if (index === 1) {
      setbgColor("#5f7b97");
    } else if (index === 2) {
      setbgColor("#5f7b97");
    } else if (index === 3) {
      setbgColor("#5f7b97");
    }
  };
  const myStyle1 = {
    margin: "10px auto",
    fontSize: "14px",
  };
  const myStyle2 = {
    margin: "10px auto",

    color: "#42566a",
    fontWeight: 1000,
  };
  return (
    <>
      {/* <Grid container spacing={2}>
        <Grid item lg={12}>
          <LogoutHeader name="Compliance Dashboard" />
        </Grid>
      </Grid> */}
      <Layout TlcompDashboard="TlcompDashboard" TLuserId={userid}>
        <Container
          maxWidth="xl"
          style={{ margin: "10px 0px 0px 0px", padding: "0px 0px 0px 0px" }}
        >
          <Card>
            {/* <CardHeader style={{ backgroundColor: "#fff" }}>
        </CardHeader> */}
            <CardBody style={{ minHeight: "700px" }}>
              {loading && (
                <Grid
                  item
                  sm={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="100vh"
                  style={{ margin: "auto" }}
                >
                  <span className="HeadingSpan">
                    <h1>Client Data Management Service</h1>
                  </span>
                  <Box m={1} mt={5}>
                    <span className="PaperBoat">
                  {allPerms.map((item, index) => (
                    <PaperBox props={item} />
                  ))}
                  </span>
                  </Box>
                </Grid>
              )}
              <span className="noticespan">
                <span style={{ color: "red" }}></span>For access to various
                services,please contact{" "}
                <span style={{ color: "red" }}>admin</span>
              </span>
            </CardBody>
          </Card>
        </Container>
      </Layout>
    </>
  );
}

export default TlCompliance;
