import React, {useEffect} from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import axios from "axios";
import { baseUrl2 } from "../../../config/config";
import { FileIconSmaller} from "../../../components/Common/MessageIcon";


function DetailModal({ showDetailModal, detailModal, selectedData,downloadpdf}) {
  const token = window.localStorage.getItem("clientToken");

  useEffect(() => {
    console.log('selectedData',selectedData);
  }, [selectedData])

  // const downloadpdf = (row) => {
  //   // console.log("DownloadAll",row);

  //   if(row.file){
  //     let filename =row.query_id;
  //     let location = row.file;
  //     const file = location.split(".");
  //     const length = file.length;
  //     const filetype = file[length - 1];

  //   // setCopy(0);
  //   const myConfig2 = {
  //     headers: {
  //       uit: token,
  //     },
  //     responseType: "blob",
  //   };

  //   // window.open(`${baseUrl2}/${url}`,'_blank', `rel=crefReport_${filename}.pdf`)

  //   axios.get(`${baseUrl2}/v1/crfcustomers/crfdocument?id=${row.id}`, myConfig2).then((res) => {
  //     if (res.status === 200) {
  //       window.URL = window.URL || window.webkitURL;
  //       var url = window.URL.createObjectURL(res.data);
  //       var a = document.createElement("a");
  //       document.body.appendChild(a);
  //       a.style = "display: none";
  //       a.href = url;
  //       a.download = `crefReport_${filename}.${filetype}`;
  //       //    a.download = "invoice"+{qno}+{installmentNumber}+'.pdf';
  //       a.target = "_blank";
  //       a.click();
  //     }
  //   });

  // }
  // };

  return (
    <div>
      <Modal isOpen={detailModal} toggle={showDetailModal} size="lg">
        <ModalHeader toggle={showDetailModal}>Query Detail</ModalHeader>
        <ModalBody>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="row">Query Number</th>
                <th scope="row">{selectedData.query_id}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Date of creation:</th>
                <td>{selectedData.created_date}</td>
              </tr>
              <tr>
                <th scope="row">Entity Name:</th>
                <td>{selectedData.entity_name}</td>
              </tr>
              <tr>
                <th scope="row">Entity Type:</th>
                <td>{selectedData.entity_type}</td>
              </tr>
              <tr>
                <th scope="row">Report Status:</th>
                <td>{selectedData.status}</td>
              </tr>
              <tr>
                <th scope="row">Date Received/declined:</th>
                {(selectedData.report_received_date) ? (
                  <td>{selectedData.report_received_date}</td>
                ) : (
                <td>{selectedData.report_decline_date}</td> )
                }
              </tr>
              <tr>
                <th scope="row">CIN</th>
                <td>{selectedData.cin}</td>
              </tr>
              <tr>
                <th scope="row">PAN</th>
                <td>{selectedData.pan}</td>
              </tr>
              <tr>
                <th scope="row">GST</th>
                <td>{selectedData.gst}</td>
              </tr>
              <tr>
                <th scope="row">Address:</th>
                <td>{selectedData.address}</td>
              </tr>
              {(selectedData.file) ? (
              <tr>
                <th scope="row">Download Report</th>
                <td><span
                onClick={() =>
                  downloadpdf(selectedData)
                }
                style={{ cursor: "pointer" }}
                title="Download Invoice"
              >
                  <FileIconSmaller name={`name.pdf`}/>
              </span></td>
              </tr>) : ""}
            </tbody>
          </table>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default DetailModal;
