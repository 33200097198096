import React, { useState, useEffect, useLayoutEffect } from "react";
// import Layout from "../../components/Layout/Layout";
import { Container, Grid, Paper, Box } from "@material-ui/core";
// import CrefPage from "./CrefPage";
import { Tab, Tabs, TabPanel, TabList } from "react-tabs";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import PaperBox from "../../../components/PaperBox/PaperBox";
// import LogoutHeader from "../CompliAny/LogoutHeader";
// import CustomHeading from "../Common/CustomHeading";
// import Cref from "../CompliAny/Cref";
// import CrefDec from "../CompliAny/CrefDec";
// import CrefPending from "../CompliAny/CrefPending";
// import CrefRec from "../CompliAny/CrefRec";
// import { clientLogout } from "../Logout/ClientLogout";
import Layout from "../../../components/Layout/Layout";
// import tlLogout from "../TlLogout/TlLogout";


function AdminCompliance(props) {
  let history = useHistory();
  const userId = window.localStorage.getItem("adminkey");
  const [tabIndex, setTabIndex] = useState(0);
  const [bgColor, setbgColor] = useState("#42566a");
  const [permDirect, setPermDirect] = useState(false);
  const [perminDirect, setPerminDirect] = useState(false);
  const [permAcc, setPermAcc] = useState(false);
  const [permCorp, setPermCorp] = useState(false);
  const [permOther, setPermOther] = useState(false);
  const [allPerms, setAllPerms] = useState([
    { name: "Direct Tax", granted: true },
    { name: "Indirect Tax", granted: true },
    { name: "Accounting", granted: true },
    { name: "Corporate", granted: true },
    { name: "Others", granted: true },
  ]);
  const [loading, setLoading] = useState(true);

  const tableIndex = (index) => {
    setTabIndex(index);
    // console.log(index);
    if (index === 0) {
      setbgColor("#42566a");
    } else if (index === 1) {
      setbgColor("#5f7b97");
    } else if (index === 2) {
      setbgColor("#5f7b97");
    } else if (index === 3) {
      setbgColor("#5f7b97");
    }
  };
  const myStyle1 = {
    margin: "10px auto",
    fontSize: "14px",
  };
  const myStyle2 = {
    margin: "10px auto",

    color: "#42566a",
    fontWeight: 1000,
  };
  return (
    <>
      {/* <Grid container spacing={2}>
        <Grid item lg={12}>
          <LogoutHeader name="Compliance Dashboard" />
        </Grid>
      </Grid> */}
      <Layout AdmincompDashboard="AdmincompDashboard" adminUserId={userId}>
        <Container maxWidth="xl" style={{ margin: "10px 0px 0px 0px", padding: "0px 0px 0px 0px" }}>
        <Card>
            {/* <CardHeader style={{ backgroundColor: "#fff" }}>
        </CardHeader> */}
            <CardBody style={{ minHeight: "700px" }}>
              {loading && (
                <Grid
                  item
                  sm={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="100vh"
                  style={{ margin: "auto" }}
                >
                  <span className="HeadingSpan">
                    <h1>Client Data Management Service</h1>
                  </span>
                  <Box m={1} mt={5}>
                    <span className="PaperBoat">
                      {allPerms.map((item, index) => (
                        <PaperBox props={item} />
                      ))}
                    </span>
                  </Box>
                </Grid>
              )}
            </CardBody>
          </Card>
        </Container>

        </Layout>
    </>
  );
}

export default AdminCompliance;
