import React, { useEffect, useState } from "react";
import { Grid, Box } from "@material-ui/core";
import LogoutHeader from "./LogoutHeader";
import { useHistory } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";
import { styled, makeStyles } from "@material-ui/styles";
// import CustomTypography from "../../components/Common/CustomTypography";
import CustomTypography from "../../../components/Common/CustomTypography";
import SubHeading from "../../../components/Common/SubHeading";
import CloudImg from "../../../views/Login/images/video.png";
import PaperLess from "../../../views/Login/images/ppp.png";
import whatp from "../../../views/Login/images/cloud1.png";
import costEffective from "../../../views/Login/images/costEffective.png";
import servicesImg from "../../../views/Login/images/service.png";
// import { clientLogout } from "../Logout/ClientLogout";
import { clientLogout } from "../../../components/Logout/ClientLogout";
// import MainContent from "../Common/MainContent";
import MainContent from "../../../components/Common/MainContent";
import { Link } from "react-router-dom";
import mazarlogo from "../../../assets/images/mazars-logo2.png";
import logo from "../../../assets/images/crif-logo2.png";
import Swal from "sweetalert2";
import tlLogout from "../../TL/TlLogout/TlLogout.js";

import axios from "axios";
const MyBox = styled(Box)({
  display: "flex",
  maxWidth: "1000px",
  width: "100%",
  flexDirection: "column",
  margin: "auto",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1rem 0 1rem 0",
});
const ImgBox = styled(Box)({
  display: "flex",
  width: "20%",
  flexDirection: "column",

  padding: "10px",
});

const useStyle = makeStyles({
  imgResponsive: {
    display: "flex",
    maxWidth: "50%",
    margin: "10px 0px",
    alignItems: "space-between",
    justifyContent: "center",
    textAlign: "justify",
  },
});
const TLMasterDash = (props) => {
  const classes = useStyle();
  let history = useHistory();
  const compPer = JSON.parse(window.localStorage.getItem("compliance_module"));
  const [showcrif, setShowcrif] = useState(false);

  useEffect(() => {
    // console.log('=>');
    if (Number(compPer) === 1) {
      setShowcrif(true);
    } else {
      setShowcrif(false);
    }
    localStorage.removeItem("TlCompClientId");
    localStorage.removeItem("TlClientPerm");
    localStorage.removeItem("TlClientAccess");
    localStorage.removeItem("TlClientList");
  }, []);

  const logout = () => {
    // clientLogout(axios, history);
    tlLogout(history);
  };
  const removecrif = () => {
    setShowcrif(!showcrif);
  };
  const noComp = () => {
    Swal.fire({
      title: "error",
      html: `To access compliance service,Please contact admin`,
      icon: "error",
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <LogoutHeader name="Mazars Advisory Solutions" />
        </Grid>

        <div className="StartPage">
          <MainContent>
            <div className="signIn">
              <div className="signBtn">
                <div className="boxOverlay"></div>
              </div>
            </div>

            <div className="signUp">
              <div className="customForm2">
                {/* <button onClick={removecrif}>Turn</button> */}
                <Grid
                  item
                  lg={12}
                  align="center"
                  spacing={0}
                  className="MasterDashBorderGrid"
                  style={{ padding: "75px 0px" }}
                >
                  <Box
                    m={1}
                    style={{
                      display: "flex",
                      marginRight: "0px",
                      marginLeft: "0px",
                      justifyContent: "space-around",
                    }}
                  >
                    <span className="companyLogoSpan">
                      <img
                        src={mazarlogo}
                        alt="mazar"
                        className="companyLogo"
                      />
                    </span>
                    <span>
                      <Link to="/teamleader/dashboard" className="d-flex">
                        <span className="linkWhite">
                          <i
                            class="fa fa-hand-o-right"
                            aria-hidden="true"
                            style={{ marginRight: "10px" }}
                          />
                          To continue as 'Mazars Advisory Solutions', click here
                        </span>
                      </Link>
                      <Link
                        to="/teamleader/compliance"
                        style={{ display: "flex" }}
                      >
                        <span className="linkWhite">
                          <i
                            class="fa fa-hand-o-right"
                            aria-hidden="true"
                            style={{ marginRight: "10px" }}
                          />
                          To visit 'Client Data Managment Services',click here
                        </span>
                      </Link>
                    </span>
                  </Box>

                  {/* <Box m={1} style={{ display: "flex" }}>
                      <Link to="/customer/crefpage" style={{ display: "flex" }}>
                        <img src={logo} alt="cref" className="companyLogo" />
                        <span className="linkWhite">
                          For CRIF reports click here
                        </span>
                      </Link>
                    </Box> */}

                  {/* <Box m={1} style={{ display: "flex" }}>
                        <img
                          src={mazarlogo}
                          alt="mazar"
                          className="companyLogo"
                        />
                        <Link
                        to="/teamleader/compliance"
                        style={{ display: "flex" }}
                      >
                        <span className="linkWhite">
                          To visit Compliance Services,click here
                        </span>
                      </Link>
                    </Box> */}
                  <Box style={{ margin: "0px 22px" }}>
                    <button
                      className="btnWhite"
                      onClick={(e) => logout()}
                      type="button"
                    >
                      Logout
                    </button>
                  </Box>
                </Grid>
              </div>
            </div>
          </MainContent>
          <MyBox>
            <CustomTypography>
              Mazars Advisory Solutions backed by group of professionals with
              extensive industry knowledge and experience in taxation matters,
              provides solutions to all direct & indirect tax queries.
            </CustomTypography>
            <div style={{ display: "flex" }}>
              <ImgBox>
                <img src={servicesImg} className={classes.imgResponsive} />

                <SubHeading>Services</SubHeading>
                <CustomTypography margin="10px">
                  Offers solutions to all compliance requirements, transfer
                  pricing matters, assessment proceedings, appeal & litigation
                  matters, opinions and other advisory needs.
                </CustomTypography>
              </ImgBox>
              <ImgBox>
                <img src={costEffective} className={classes.imgResponsive} />
                <SubHeading>Cost effective</SubHeading>
                <CustomTypography margin="10px">
                  Provides cost effective solution, designed exclusively for
                  client.
                </CustomTypography>
              </ImgBox>
              <ImgBox>
                <img src={whatp} className={classes.imgResponsive} />
                <SubHeading>Video conference</SubHeading>
                <CustomTypography margin="10px">
                  Offers video conferencing facility to hold meetings with
                  clients.
                </CustomTypography>
              </ImgBox>
              <ImgBox>
                <img src={CloudImg} className={classes.imgResponsive} />
                <SubHeading>Secure platform</SubHeading>
                <CustomTypography margin="10px">
                  Ensures total privacy of client’s data.
                </CustomTypography>
              </ImgBox>{" "}
              <ImgBox>
                <img src={PaperLess} className={classes.imgResponsive} />
                <SubHeading>Paperless</SubHeading>
                <CustomTypography margin="10px">
                  Operates completely in paperless environment.
                </CustomTypography>
              </ImgBox>
            </div>
          </MyBox>
        </div>
        <Grid item lg={12}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};
export default TLMasterDash;
