import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import Layout from "../../components/Layout/Layout";
import { Container, Grid, Paper, Box } from "@material-ui/core";
// import CrefPage from "./CrefPage";
import { Tab, Tabs, TabPanel, TabList } from "react-tabs";
import { useHistory } from "react-router-dom";
import LogoutHeader from "../CompliAny/LogoutHeader";
import CustomHeading from "../Common/CustomHeading";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
// import Cref from "../CompliAny/Cref";
// import CrefDec from "../CompliAny/CrefDec";
// import CrefPending from "../CompliAny/CrefPending";
// import CrefRec from "../CompliAny/CrefRec";
import { clientLogout } from "../Logout/ClientLogout";
import { baseUrl } from "../../config/config";
import PaperBox from "../../components/PaperBox/PaperBox";

function Compliance(props) {
  let history = useHistory();
  const compPer = JSON.parse(window.localStorage.getItem("compliance_module"));
  const userId = window.localStorage.getItem("userid");
  const [tabIndex, setTabIndex] = useState(0);
  const [bgColor, setbgColor] = useState("#42566a");
  const custpermor = window.localStorage.getItem("cust_access");
  const custperm = window.localStorage.getItem("cust_rootfolderlist");
  const [loading, setLoading] = useState(true);
  const [permDirect, setPermDirect] = useState(false);
  const [perminDirect, setPerminDirect] = useState(false);
  const [permAcc, setPermAcc] = useState(false);
  const [permCorp, setPermCorp] = useState(false);
  const [permOther, setPermOther] = useState(false);
  const [allPerms, setAllPerms] = useState([
    { id: "1", name: "Direct Tax", code: "direct_tax", granted: false },
    { id: "2", name: "Indirect Tax", code: "indirect_tax", granted: false },
    { id: "3", name: "Accounting", code: "accounting", granted: false },
    { id: "4", name: "Corporate", code: "corporate", granted: false },
    { id: "5", name: "Others", code: "other", granted: false },
  ]);
  const token = window.localStorage.getItem("clientToken");
  const myConfig = {
    headers: {
      uit: token,
    },
  };

  const tableIndex = (index) => {
    setTabIndex(index);
    // console.log(index);
    if (index === 0) {
      setbgColor("#42566a");
    } else if (index === 1) {
      setbgColor("#5f7b97");
    } else if (index === 2) {
      setbgColor("#5f7b97");
    } else if (index === 3) {
      setbgColor("#5f7b97");
    }
  };
  const myStyle1 = {
    margin: "10px auto",
    fontSize: "14px",
  };
  const myStyle2 = {
    margin: "10px auto",

    color: "#42566a",
    fontWeight: 1000,
  };

  useEffect(() => {
    if (compPer == 0) {
      history.push("/customer/masterdashboard");
    }
    showfile();
  }, []);

  useEffect(() => {
    if (custpermor) {
      const tlperm = JSON?.parse(custpermor);
      setAllPerms((prevPerms) =>
        prevPerms.map((perm) => {
          switch (perm.name) {
            case "Direct Tax":
              return { ...perm, granted: tlperm.direct_tax == 1 };
            case "Indirect Tax":
              return { ...perm, granted: tlperm.indirect_tax == 1 };
            case "Accounting":
              return { ...perm, granted: tlperm.accounting == 1 };
            case "Corporate":
              return { ...perm, granted: tlperm.corporate == 1 };
            case "Others":
              return { ...perm, granted: tlperm.other == 1 };
            default:
              return perm;
          }
        })
      );
    }
  }, []);

  // useEffect(() => {
  //   if (custperm) {
  //     const xyz = JSON?.parse(custperm);
  //     if (Array.isArray(xyz)) {
  //       const updatedPerms = allPerms.map((perm) => ({
  //         ...perm,
  //         granted: xyz.some((cp) => cp.folder === perm.code),
  //       }));
  //       setAllPerms(updatedPerms);
  //     } else {
  //       console.error("custperm is not an array/==>");
  //     }
  //   }
  // }, [custperm]);

  const showfile = (id) => {
    var confToken = "";
    axios.get(`${baseUrl}/compliance/rootfolderlist`, myConfig).then((res) => {
      if (res.data.code === 1) {
        // console.log("resFile==>", res.data.result);
        const data = res.data.result;
        const updatedPerms = allPerms.map((perm) => ({
          ...perm,
          granted: data.some((cp) => cp.folder === perm.code),
        }));
        setAllPerms(updatedPerms);
        localStorage.setItem("cust_rootfolderlist", JSON.stringify(data));
      }
    });
  };

  return (
    <>
      <Layout compDashboard="compDashboard" custUserId={userId}>
        <Container maxWidth="xl">
          <Card>
            {/* <CardHeader style={{ backgroundColor: "#fff" }}>
        </CardHeader> */}
            <CardBody style={{ minHeight: "700px" }}>
              {loading && (
                <Grid
                  item
                  sm={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="100vh"
                  style={{ margin: "auto" }}
                >
                  <span className="HeadingSpan">
                    <h1>Client Data Management Service</h1>
                  </span>
                  <Box m={1} mt={5}>
                    <span className="PaperBoat">
                      {allPerms.map((item, index) => (
                        <PaperBox props={item} />
                      ))}
                    </span>
                  </Box>
                </Grid>
              )}
              <span className="noticespan">
                <span style={{ color: "red" }}></span>For access to various
                services,please contact{" "}
                <span style={{ color: "red" }}>admin</span>
              </span>
            </CardBody>
          </Card>
        </Container>
      </Layout>
    </>
  );
}

export default Compliance;
