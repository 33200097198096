import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { baseUrl } from "../../config/config";
import {
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import CommonServices from "../../common/common";
import CustomerFilter from "../../components/Search-Filter/CustomerFilter";
import Records from "../../components/Records/Records";
import DiscardReport from "../AssignmentTab/DiscardReport";
import PaymentComponent from "./PaymentComponent";
import PaginatorCust from "../../components/Paginator/PaginatorCust";
import "./index.css";
import ModalManual from "../ModalManual/AllComponentManual";
import MessageIcon, {
  ViewDiscussionIcon,
  HelpIcon,
  Payment,
} from "../../components/Common/MessageIcon";

import DataTablepopulated from "../../components/DataTablepopulated/DataTabel";
import { useHistory } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  isActive: {
    backgroundColor: "green",
    color: "#fff",
    margin: "0px 2px",
  },
}));
function Unpaid() {
  const userId = window.localStorage.getItem("userid");
  let history = useHistory();
  const [records, setRecords] = useState([]);
  const [scrolledTo, setScrolledTo] = useState("");
  const myRef = useRef([]);

  const [count, setCount] = useState("");
  const [payment, setPayment] = useState([]);
  const [modal, setModal] = useState(false);

  const allEnd = Number(localStorage.getItem("cust_record_per_page"));
  const classes = useStyles();
  // const allEnd = 50;
  const [onPage, setOnPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sortVal, setSortVal] = useState(0);
  const [sortField, setSortField] = useState("");
  const [resetTrigger, setresetTrigger] = useState(false);
  const [accend, setAccend] = useState(false);
  const [turnGreen, setTurnGreen] = useState(false);
  const [isActive, setIsActive] = useState("");
  const [prev, setPrev] = useState("");

  const [pay, setPay] = useState({
    pay: "",
    amount: "",
    accepted_amount: "",
    paid_amount: "",
    assign_id: "",

    amount_type: "",
    amount_fixed: "",
    amount_hourly: "",

    payment_terms: "",
    no_of_installment: "",
    installment_amount: "",
    due_date: "",
  });

  const [addPaymentModal, setPaymentModal] = useState(false);
  const paymentHandler = (key) => {
    setPaymentModal(!addPaymentModal);
    setPay({
      amount: key.accepted_amount,
      assign_id: key.assign_id,
      accepted_amount: key.accepted_amount,
      paid_amount: key.paid_amount,

      amount_type: key.amount_type,
      amount_fixed: key.amount_fixed,
      amount_hourly: key.amount_hourly,

      payment_terms: key.payment_terms,
      no_of_installment: key.no_of_installment,
      installment_amount: key.installment_amount,
      due_date: key.due_date,
    });
  };

  const [assignNo, setAssignNo] = useState("");
  const [ViewDiscussion, setViewDiscussion] = useState(false);
  const [openManual, setManual] = useState(false);
  const token = window.localStorage.getItem("clientToken");
  const myConfig = {
    headers: {
      uit: token,
    },
  };
  const needHelp = () => {
    setManual(!openManual);
  };
  const ViewDiscussionToggel = (key) => {
    setViewDiscussion(!ViewDiscussion);
    setAssignNo(key);
    if (ViewDiscussion === false) {
      setScrolledTo(key);
    }
  };

  useEffect(() => {
    let runTo = myRef.current[scrolledTo];
    runTo?.scrollIntoView(false);
    runTo?.scrollIntoView({ block: "center" });
  }, [ViewDiscussion]);

  function headerLabelFormatter(column, colIndex) {
    let isActive = true;

    if (
      localStorage.getItem("custArrowPay3") === column.dataField ||
      localStorage.getItem("prevcustpay3") === column.dataField
    ) {
      isActive = true;
      setPrev(column.dataField);
      localStorage.setItem("prevcustpay3", column.dataField);
    } else {
      isActive = false;
    }
    return (
      <div className="d-flex text-white w-100 flex-wrap">
        <div style={{ display: "flex", color: "#fff" }}>
          {column.text}
          {localStorage.getItem("custArrowPay3") === column.dataField ? (
            <ArrowDropUpIcon
              className={isActive === true ? classes.isActive : ""}
            />
          ) : (
            <ArrowDropDownIcon
              className={isActive === true ? classes.isActive : ""}
            />
          )}
        </div>
      </div>
    );
  }

  useEffect(() => {
    let local = JSON.parse(localStorage.getItem(`searchDatacustPay3`));
    let pageno = JSON.parse(localStorage.getItem("custPay3"));
    let arrow = localStorage.getItem("custArrowPay3");
    let pre = localStorage.getItem("prevcustpay3");
    if (pre) {
      setPrev(pre);
    }
    if (arrow) {
      setAccend(arrow);
      setIsActive(arrow);
      setTurnGreen(true);
    }
    if (pageno) {
      getPaymentStatus(pageno);
    } else {
      getPaymentStatus(1);
    }
  }, []);

  const getPaymentStatus = (e) => {
    if (e === undefined) {
      console.log(e, "e");
      e = 1;
    }
    let data = JSON.parse(localStorage.getItem("searchDatacustPay3"));
    let pagetry = JSON.parse(localStorage.getItem("freezecustPay3"));
    localStorage.setItem(`custPay3`, JSON.stringify(e));
    let val = pagetry?.val;
    let field = pagetry?.field;
    let remainApiPath = "";
    setOnPage(e);
    setLoading(true);
    if (data && pagetry) {
      remainApiPath = `customers/getUploadedProposals?page=${e}&cid=${JSON.parse(
        userId
      )}&cat_id=${data.store}&from=${data.fromDate}&to=${
        data.toDate
      }&status=2&pcat_id=${data.pcatId}&orderby=${val}&orderbyfield=${field}`;
    } else if (data && !pagetry) {
      remainApiPath = `customers/getUploadedProposals?page=${e}&cid=${JSON.parse(
        userId
      )}&cat_id=${data.store}&from=${data.fromDate}&to=${
        data.toDate
      }&status=2&pcat_id=${data.pcatId}`;
    } else if (!data && pagetry) {
      remainApiPath = `customers/getUploadedProposals?page=${e}&cid=${JSON.parse(
        userId
      )}&status=2&orderby=${val}&orderbyfield=${field}`;
    } else {
      remainApiPath = `customers/getUploadedProposals?page=${e}&cid=${JSON.parse(
        userId
      )}&status=2`;
    }

    axios.get(`${baseUrl}/${remainApiPath}`, myConfig).then((res) => {
      if (res.data.code === 1) {
        let all = [];
        let customId = 1;
        if (e > 1) {
          customId = allEnd * (e - 1) + 1;
        }
        let data = res.data.result;
        data.map((i) => {
          let data = {
            ...i,
            cid: customId,
          };
          customId++;
          all.push(data);
        });
        setPayment(all);
        setCount(res.data.total);
        setRecords(res.data.result.length);
      } else if (res.data.code === 0) {
        CommonServices.clientLogout(history);
      }
    });
  };

  const toggle = (key) => {
    setModal(!modal);

    fetch(`${baseUrl}/admin/getPaymentDetail?id=${key}`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/vnd.github.cloak-preview",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setPay(response.payment_detail);
      })
      .catch((error) => console.log(error));
  };

  const sortMessage = (val, field) => {
    let remainApiPath = "";
    setSortVal(val);
    setSortField(field);
    localStorage.setItem(`custPay3`, JSON.stringify(1));
    let obj = {
      // pageno: pageno,
      val: val,
      field: field,
    };
    localStorage.setItem(`freezecustPay3`, JSON.stringify(obj));
    let data = JSON.parse(localStorage.getItem("searchDatacustPay3"));

    if (data) {
      remainApiPath = `customers/getUploadedProposals?page=1&cid=${JSON.parse(
        userId
      )}&cat_id=${data.store}&from=${data.fromDate}&to=${
        data.toDate
      }&status=2&pcat_id=${data.pcatId}&orderby=${val}&orderbyfield=${field}`;
    } else {
      remainApiPath = `customers/getUploadedProposals?page=1&cid=${JSON.parse(
        userId
      )}&status=2&orderby=${val}&orderbyfield=${field}`;
    }

    axios.get(`${baseUrl}/${remainApiPath}`, myConfig).then((res) => {
      if (res.data.code === 1) {
        let all = [];
        let sortId = 1;
        // let record =Number(localStorage.getItem("tp_record_per_page"))
        // let startAt = 1;
        // if (onPage > 1) {
        //   sortId = 1;
        // }
        res.data.result.map((i) => {
          let data = {
            ...i,
            cid: sortId,
          };
          sortId++;
          all.push(data);
        });
        setPayment(all);
        setTurnGreen(true);
        setresetTrigger(!resetTrigger);
      }
    });
  };

  const columns = [
    {
      dataField: "",
      text: "S.No",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            id={row.assign_no}
            ref={(el) => (myRef.current[row.assign_no] = el)}
          >
            {row.cid}
          </div>
        );
      },
      headerStyle: () => {
        return {
          width: "50px",
        };
      },
    },
    {
      dataField: "query_created_date",
      text: "Date",
      headerFormatter: headerLabelFormatter,
      sort: true,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          setIsActive(field);
          localStorage.setItem("custArrowPay3", field);
        } else {
          setAccend("");
          localStorage.removeItem("custArrowPay3");
        }
        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 1);
      },

      formatter: function dateFormat(cell, row) {
        var oldDate = row.query_created_date;
        if (oldDate == null) {
          return null;
        }
        return oldDate.slice(0, 10).toString().split("-").reverse().join("-");
      },
    },
    {
      dataField: "assign_no",
      text: "Query No",
      // headerFormatter: headerLabelFormatter,
      // sort: true,
      // onSort: (field, order) => {
      //   let val = 0;
      //   if (accend !== field) {
      //     setAccend(field);
      //     setIsActive(field);
      //     localStorage.setItem("custArrowPay3", field);
      //   } else {
      //     setAccend("");
      //     localStorage.removeItem("custArrowPay3");
      //   }
      //   if (accend === field) {
      //     val = 0;
      //   } else {
      //     val = 1;
      //   }
      //   sortMessage(val, 2);
      // },

      formatter: function nameFormatter(cell, row) {
        return (
          <>
            <Link
              to={{
                pathname: `/customer_my-assingment/${row.assign_id}`,
                index: 2,
                routes: "paymentstatus",
              }}
            >
              {row.assign_no}
            </Link>
          </>
        );
      },
    },
    {
      dataField: "parent_id",
      text: "Category",
      headerFormatter: headerLabelFormatter,
      sort: true,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          setIsActive(field);
          localStorage.setItem("custArrowPay3", field);
        } else {
          setAccend("");
          localStorage.removeItem("custArrowPay3");
        }
        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 3);
      },
    },
    {
      dataField: "cat_name",
      text: "Sub Category",
      headerFormatter: headerLabelFormatter,
      sort: true,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          setIsActive(field);
          localStorage.setItem("custArrowPay3", field);
        } else {
          setAccend("");
          localStorage.removeItem("custArrowPay3");
        }
        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 4);
      },
    },
    {
      text: "Date of acceptance of Proposal",
      dataField: "cust_accept_date",
      headerFormatter: headerLabelFormatter,
      sort: true,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          setIsActive(field);
          localStorage.setItem("custArrowPay3", field);
        } else {
          setAccend("");
          localStorage.removeItem("custArrowPay3");
        }
        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 5);
      },

      formatter: function dateFormat(cell, row) {
        var oldDate = row.cust_accept_date;
        if (oldDate == null) {
          return null;
        }
        return oldDate.slice(0, 10).toString().split("-").reverse().join("-");
      },
    },
    {
      text: "Status",
      dataField: "status",

      formatter: function (cell, row) {
        return (
          <>
            {row.paid_status == "2" ? (
              <p className="declined">{row.status} </p>
            ) : (
              <p>{row.status}</p>
            )}
          </>
        );
      },
    },
    {
      dataField: "accepted_amount",
      text: "Accepted Amount ",
      headerFormatter: headerLabelFormatter,
      sort: true,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          setIsActive(field);
          localStorage.setItem("custArrowPay3", field);
        } else {
          setAccend("");
          localStorage.removeItem("custArrowPay3");
        }
        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 7);
      },

      formatter: function nameFormatter(cell, row) {
        var nfObject = new Intl.NumberFormat("hi-IN");
        var x = row.accepted_amount;
        // console.log(nfObject.format(x));
        return <p className="rightAli">{nfObject.format(x)}</p>;
      },
    },
    {
      text: "Amount Paid",
      dataField: "paid_amount",
      headerFormatter: headerLabelFormatter,
      sort: true,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          setIsActive(field);
          localStorage.setItem("custArrowPay3", field);
        } else {
          setAccend("");
          localStorage.removeItem("custArrowPay3");
        }
        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 8);
      },

      formatter: function nameFormatter(cell, row) {
        var nfObject = new Intl.NumberFormat("hi-IN");
        var x = row.paid_amount;

        return <p className="rightAli">{nfObject.format(x)}</p>;
      },
    },
    {
      text: "Amount Outstanding",
      dataField: "amount_outstanding",
      headerFormatter: headerLabelFormatter,
      sort: true,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          setIsActive(field);
          localStorage.setItem("custArrowPay3", field);
        } else {
          setAccend("");
          localStorage.removeItem("custArrowPay3");
        }
        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 9);
      },

      formatter: function nameFormatter(cell, row) {
        var nfObject = new Intl.NumberFormat("hi-IN");
        var x = row.amount_outstanding;

        return <p className="rightAli">{nfObject.format(x)}</p>;
      },
    },
    {
      text: "Date of Payment",
      dataField: "cust_paid_date",
      headerFormatter: headerLabelFormatter,
      sort: true,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          setIsActive(field);
          localStorage.setItem("custArrowPay3", field);
        } else {
          setAccend("");
          localStorage.removeItem("custArrowPay3");
        }
        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 10);
      },

      formatter: function dateFormat(cell, row) {
        var oldDate = row.cust_paid_date;
        if (oldDate == null) {
          return null;
        }
        return oldDate.slice(0, 10).toString().split("-").reverse().join("-");
      },
    },

    {
      text: "Action",
      dataField: "",

      formatter: function (cell, row) {
        return (
          <>
            {row.paid_status === "2" ? (
              <>
                <Link
                  to={{
                    pathname: `/customer_paydetails/${row.assign_id}`,
                    index: 2,
                    routes: "paymentstatus",
                  }}
                >
                  <Payment />
                </Link>

                <span
                  onClick={() => ViewDiscussionToggel(row.assign_no)}
                  className="ml-1"
                >
                  <ViewDiscussionIcon />
                </span>
              </>
            ) : (
              <>
                {row.paid_status == "0" ? (
                  <Link
                    to={{
                      pathname: `/customer_paydetails/${row.assign_id}`,
                      index: 2,
                      routes: "paymentstatus",
                    }}
                  >
                    <Payment />
                  </Link>
                ) : null}

                {row.paid_amount > 0 && row.paid_status > 0 ? (
                  <Link
                    to={{
                      pathname: `/customer_paydetails/${row.assign_id}`,
                      index: 2,
                      routes: "paymentstatus",
                    }}
                  >
                    <Payment />
                  </Link>
                ) : null}

                <Link
                  className="ml-2"
                  to={{
                    pathname: `/customer_chatting/${row.assign_id}`,
                    index: 2,
                    routes: "paymentstatus",

                    obj: {
                      message_type: "5",
                      query_No: row.assign_no,
                      query_id: row.assign_id,
                      routes: `/customer/paymentstatus`,
                    },
                  }}
                >
                  <MessageIcon />
                </Link>

                <span
                  onClick={() => ViewDiscussionToggel(row.assign_no)}
                  className="ml-1"
                >
                  <ViewDiscussionIcon />
                </span>
              </>
            )}
          </>
        );
      },
    },
  ];

  const resetTriggerFunc = () => {
    setresetTrigger(!resetTrigger);
    setAccend("");
    setTurnGreen(false);
    localStorage.removeItem("custPay3");
    localStorage.removeItem(`freezecustPay3`);
    localStorage.removeItem("custArrowPay3");
    localStorage.removeItem("prevcustpay3");
    setPrev("");
  };

  return (
    <>
      <>
        <Card>
          <CardHeader>
            <span onClick={(e) => needHelp()}>
              {" "}
              <HelpIcon />
            </span>
            <CustomerFilter
              setData={setPayment}
              getData={getPaymentStatus}
              paid="paid"
              setRecords={setRecords}
              records={records}
              id={userId}
              index="custPay3"
              resetTriggerFunc={resetTriggerFunc}
              setCount={setCount}
            />
          </CardHeader>
          <CardBody>
            <Modal isOpen={openManual} toggle={needHelp} size="lg">
              <ModalHeader toggle={needHelp}>Mazars</ModalHeader>
              <ModalBody>
                <ModalManual tar={"paymentProcess"} />
              </ModalBody>
            </Modal>
            {/* <Records records={records} /> */}
            <Row className="mb-2">
              <Col md="12" align="right">
                <PaginatorCust
                  count={count}
                  id={userId}
                  setData={setPayment}
                  getData={getPaymentStatus}
                  paid="paid"
                  index="custPay3"
                  setOnPage={setOnPage}
                  resetTrigger={resetTrigger}
                  setresetTrigger={setresetTrigger}
                />
              </Col>
            </Row>
            <DataTablepopulated
              bgColor="#3e8678"
              keyField={"assign_no"}
              data={payment}
              columns={columns}
            ></DataTablepopulated>

            <PaymentComponent
              paymentHandler={paymentHandler}
              addPaymentModal={addPaymentModal}
              pay={pay}
              getPaymentStatus={getPaymentStatus}
            />

            <DiscardReport
              ViewDiscussionToggel={ViewDiscussionToggel}
              ViewDiscussion={ViewDiscussion}
              report={assignNo}
              getData={getPaymentStatus}
              headColor="#3e8678"
            />

            <Modal isOpen={modal} fade={false} toggle={toggle}>
              <ModalHeader toggle={toggle}>History</ModalHeader>
              <ModalBody>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="row">S.No</th>
                      <th scope="row">Date</th>
                      <th scope="row">Amount</th>
                    </tr>
                  </thead>
                  {pay.length > 0
                    ? pay.map((p, i) => (
                        <tbody>
                          <tr>
                            <td>{i + 1}</td>
                            <td>{CommonServices.removeTime(p.payment_date)}</td>
                            <td>{p.paid_amount}</td>
                          </tr>
                        </tbody>
                      ))
                    : null}
                </table>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </CardBody>
        </Card>
      </>
    </>
  );
}

export default Unpaid;
