import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/styles";
import { baseUrl, baseUrl3 } from "../../../config/config";
import Layout from "../../../components/Layout/Layout";
import axios from "axios";
import { Container } from "@material-ui/core";
import { useHistory } from "react-router";
import { DeleteIcon } from "../../../components/Common/MessageIcon";
import Swal from "sweetalert2";
import { Row, Col } from "reactstrap";
import { AiOutlinePlaySquare } from "react-icons/ai";
import ReactPlayer from "react-player";
import CloseIcon from "@material-ui/icons/Close";
import CustomHeading from "../../../components/Common/CustomHeading";
const MyContainer = styled(Container)({});
const Groupvideo = () => {
  const [galleryData, setGalleryData] = useState({
    files: [],
    result: [],
  });
  const [videoId, setVideoId] = useState();
  const [play, isPlay] = useState(false);
  const userId = window.localStorage.getItem("adminkey");
  let history = useHistory();
  const token = localStorage.getItem("token");
  const myConfig = {
    headers: {
      uit: token,
    },
  };

  useEffect(() => {
    getImages();
  }, []);

  const getImages = () => {
    if (history.location.index) {
      axios
        .get(
          `${baseUrl}/cms/getgallarylist?uid=${JSON.parse(
            userId
          )}&type=video&id=${history.location.index.id}`,
          myConfig
        )
        .then((res) => {
          setGalleryData({
            files: res.data.files,
            result: res.data.result,
          });
        });
    }
  };
  const del = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Want to delete media? Yes, delete it!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios
          .get(
            `${baseUrl}/cms/deleteimage?uid=${JSON.parse(userId)}&id=${
              galleryData.result[0].id
            }&imageid=${e.imageid}`,
            myConfig
          )
          .then((res) => {
            if (res.data.code === 1) {
              Swal.fire({
                title: "success",
                html: "Media deleted successfully",
                icon: "success",
              });
              getImages();
            } else {
              Swal.fire({
                title: "error",
                html: "Something went wrong , please try again",
                icon: "error",
              });
            }
          });
      }
    });
  };
  const playVideo2 = (e) => {
    isPlay(true);
    setVideoId(`${baseUrl3}/assets/gallery/${e}`);
  };

  return (
    <>
      <Layout cmsDashboard="cmsDashboard">
        <MyContainer>
          <div className="py-2">
            <Row>
              <Col md="4">
                <button
                  className="autoWidthBtn"
                  onClick={() => history.goBack()}
                >
                  Go Back
                </button>
              </Col>
              <Col md="4" align="center">
                <CustomHeading>Video gallery</CustomHeading>
              </Col>
            </Row>
          </div>
          <div className="galleryContainer">
            {galleryData.files.map((i) => (
              <div className="galleryBox" key={i.id}>
                {(i.name.split(".")[1] === "mp4") === true ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        position: "relative",
                      }}
                    >
                      <span
                        style={{
                          maxHeight: "220px",
                          minHeight: "220px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <video
                          onClick={(e) => playVideo2(i.name)}
                          style={{
                            // display: "flex",
                            zIndex: 1,
                            width: "60%",
                            display: "block",
                            margin: "auto auto auto 0px",
                            maxWidth: "100%",
                            maxHeight: "100%",
                            // width: "fit-content",
                            height: "auto",
                          }}
                          id={i.id}
                          src={`${baseUrl3}/assets/gallery/${i.name}`}
                        />
                      </span>
                      <span onClick={(e) => playVideo2(i.name)}>
                        <AiOutlinePlaySquare
                          style={{
                            display: "flex",
                            color: "red",
                            width: "20%",
                            height: "20%",
                            position: "absolute",
                            top: "35%",
                            left: "23%",
                            zIndex: "1",
                          }}
                        />
                      </span>
                      <div
                        className="delIcon"
                        style={{ padding: "2px 0px", maxHeight: "24px" }}
                      >
                        <span title="Delete Media" onClick={() => del(i)}>
                          <DeleteIcon />
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      style={{
                        display: "block",
                        margin: "auto auto auto 0px",
                        maxWidth: "100%",
                        maxHeight: "100%",
                        width: "fit-content",
                        height: "auto",
                        overflow: "hidden",
                      }}
                      id={i.id}
                      src={`${baseUrl3}/assets/gallery/${i.name}`}
                    />
                    <div className="delIcon" style={{ padding: "2px 0px" }}>
                      <span title="Delete Media" onClick={() => del(i)}>
                        <DeleteIcon />
                      </span>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          {play === true ? (
            <div className="modalBox">
              <div className="boxContainer">
                <div className="canBtn" title="cancel">
                  <span>
                    <h4>Recording player</h4>
                  </span>
                  <span>
                    <CloseIcon onClick={() => isPlay(false)} id="myBtn" />
                  </span>
                </div>

                <div className="my2">
                  <ReactPlayer
                    url={videoId}
                    controls={true}
                    playing={true}
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </MyContainer>
      </Layout>
    </>
  );
};
export default Groupvideo;
