import React, { useState, useEffect, useRef } from "react";
import { Card, CardHeader, CardBody, Col } from "reactstrap";
import axios from "axios";
import { baseUrl } from "../../../config/config";
import "./map.css";
import Swal from "sweetalert2";
import Layout from "../../../components/Layout/Layout";
import CrefModal from "./crefModal";
import CrefUpload from "./crefupload";
import { Grid } from "@mui/material";
import CrefFilter from "./crefCmsFilter";

import {
  DeleteIcon,
  FileIconSmaller,
  UploadDocument,
} from "../../../components/Common/MessageIcon";
import DataTablepopulated from "../../../components/DataTablepopulated/DataTabel";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  isActive: {
    backgroundColor: "green",
    color: "#fff",
    margin: "0px 2px",
  },
}));

const CrefManage = () => {
  const myRef = useRef([]);
  const tableId = React.createRef("");
  const [data, setData] = useState([]);
  const [detailModal, setDetailModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [records, setRecords] = useState([]);
  const [page, setPage] = useState(1);
  const [atPage, setAtpage] = useState(1);
  const [countNotification, setCountNotification] = useState("");
  const [big, setBig] = useState(1);
  const [end, setEnd] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  // const [turnGreen, setTurnGreen] = useState(false);
  const [resetTrigger, setresetTrigger] = useState(false);
  // const [isActive, setIsActive] = useState("");
  // const [prev, setPrev] = useState("");
  const [accend, setAccend] = useState(false);
  const [defaultPage, setDefaultPage] = useState(["1", "2", "3", "4", "5"]);
  const [scrolledTo, setScrolledTo] = useState("");

  let allEnd = 50;

  const token = localStorage.getItem("token");
  const myConfig = {
    headers: {
      uit: token,
    },
  };

  const classes = useStyles();

  function headerLabelFormatter(column, colIndex) {
    let isActive = true;
    if (
      localStorage.getItem("cmsArrowCref1") === column.dataField ||
      localStorage.getItem("prevcmscref1") === column.dataField
    ) {
      isActive = true;
      // setPrev(column.dataField);
      localStorage.setItem("prevcmscref1", column.dataField);
    } else {
      isActive = false;
    }
    return (
      <div className="d-flex text-white w-100 flex-wrap">
        <div style={{ display: "flex", color: "#fff" }}>
          {column.text}
          {localStorage.getItem("cmsArrowCref1") === column.dataField ? (
            <ArrowDropUpIcon
              className={isActive === true ? classes.isActive : ""}
            />
          ) : (
            <ArrowDropDownIcon
              className={isActive === true ? classes.isActive : ""}
            />
          )}
        </div>
      </div>
    );
  }

  const firstChunk = () => {
    setAtpage(1);
    setPage(1);
    getAllcref(1);
  };
  const prevChunk = () => {
    if (atPage > 1) {
      setAtpage((atPage) => atPage - 1);
    }
    setPage(Number(page) - 1);
    getAllcref(page - 1);
  };
  const nextChunk = () => {
    if (atPage < totalPages) {
      setAtpage((atPage) => atPage + 1);
    }
    setPage(Number(page) + 1);
    getAllcref(page + 1);
  };
  const lastChunk = () => {
    setPage(defaultPage.at(-1));
    getAllcref(defaultPage.at(-1));
    setAtpage(totalPages);
    localStorage.setItem("cmscref1", defaultPage.at(-1));
  };

  useEffect(() => {
    localStorage.removeItem(`searchcmscref1`);
    localStorage.removeItem(`cmsArrowCref1`);
    localStorage.removeItem(`prevcmscref1`);
    localStorage.removeItem(`cmscreftotal1`);
    // let pageno = JSON.parse(localStorage.getItem("cmscref1"));
    // let arrow = localStorage.getItem("cmsArrowCref1");
    // let pre = localStorage.getItem("prevcmscref1");
    // let total = localStorage.getItem("cmscreftotal1");
    // if(total){
    //   setCountNotification(total)
    // }
    // if (pre) {
    //   // setPrev(pre);
    // }
    // if (arrow) {
    //   setAccend(arrow);
    //   // setIsActive(arrow);
    //   // setTurnGreen(true);
    // }
    // if(pageno){
    // getAllcref(pageno);
    // }else{
    getAllcref(1);
    // }
  }, []);

  const getAllcref = (e) => {
    let droppage = [];
    let remainApiPath = "";
    localStorage.setItem("cmscref1", e);
    // console.log("eee", e);

    let data = JSON.parse(localStorage.getItem(`searchcmscref1`));
    let sort = JSON.parse(localStorage.getItem("cmssortcref1"));
    // let total = localStorage.getItem("cmscreftotal1");

    let val = sort?.orderBy;
    let field = sort?.fieldBy;

    if (e === 1) {
      if (data && sort) {
        // console.log("data and sort");
        remainApiPath = `/cms/crfquerylist?page=${e}&from=${data.fromDate
          ?.split("-")
          .reverse()
          .join("-")}&to=${data.toDate
          ?.split("-")
          .reverse()
          .join("-")}&status=${data?.p_status}&qno=${
          data?.query_no
        }&entity_name=${data?.entity_no}&user_id=${
          data?.user_id
        }&orderby=${val}&orderbyfield=${field}`;
      } else if (!data && sort) {
        // console.log("sort");
        remainApiPath = `/cms/crfquerylist?page=${e}&orderby=${val}&orderbyfield=${field}`;
      } else if (data && !sort) {
        // console.log("data");
        remainApiPath = `/cms/crfquerylist?page=${e}&from=${data.fromDate
          ?.split("-")
          .reverse()
          .join("-")}&to=${data.toDate
          ?.split("-")
          .reverse()
          .join("-")}&status=${data?.p_status}&qno=${
          data?.query_no
        }&entity_name=${data?.entity_no}&user_id=${
          data?.user_id
        }&orderby=0&orderbyfield=2`;
      } else {
        remainApiPath = `/cms/crfquerylist?page=${e}&orderby=0&orderbyfield=2`;
      }
    } else {
      if (countNotification) {
        if (data && sort) {
          // console.log("data and sort");
          remainApiPath = `/cms/crfquerylist?page=${e}&from=${data.fromDate
            ?.split("-")
            .reverse()
            .join("-")}&to=${data.toDate
            ?.split("-")
            .reverse()
            .join("-")}&status=${data?.p_status}&qno=${
            data?.query_no
          }&entity_name=${data?.entity_no}&user_id=${
            data?.user_id
          }&orderby=${val}&orderbyfield=${field}&total=${countNotification}`;
        } else if (!data && sort) {
          // console.log("sort");
          remainApiPath = `/cms/crfquerylist?page=${e}&orderby=${val}&orderbyfield=${field}&total=${countNotification}`;
        } else if (data && !sort) {
          // console.log("data");
          remainApiPath = `/cms/crfquerylist?page=${e}&from=${data.fromDate
            ?.split("-")
            .reverse()
            .join("-")}&to=${data.toDate
            ?.split("-")
            .reverse()
            .join("-")}&status=${data?.p_status}&qno=${
            data?.query_no
          }&entity_name=${data?.entity_no}&user_id=${
            data?.user_id
          }&orderby=0&orderbyfield=2&total=${countNotification}`;
        } else {
          // console.log("neither");
          remainApiPath = `/cms/crfquerylist?page=${e}&orderby=0&orderbyfield=2&total=${countNotification}`;
        }
      } else {
        if (data && sort) {
          remainApiPath = `/cms/crfquerylist?page=${e}&from=${data.fromDate
            ?.split("-")
            .reverse()
            .join("-")}&to=${data.toDate
            ?.split("-")
            .reverse()
            .join("-")}&status=${data?.p_status}&qno=${
            data?.query_no
          }&entity_name=${data?.entity_no}&user_id=${
            data?.user_id
          }&orderby=${val}&orderbyfield=${field}`;
        } else if (!data && sort) {
          remainApiPath = `/cms/crfquerylist?page=${e}&orderby=${val}&orderbyfield=${field}`;
        } else if (data && !sort) {
          remainApiPath = `/cms/crfquerylist?page=${e}&from=${data.fromDate
            ?.split("-")
            .reverse()
            .join("-")}&to=${data.toDate
            ?.split("-")
            .reverse()
            .join("-")}&status=${data?.p_status}&qno=${
            data?.query_no
          }&entity_name=${data?.entity_no}&user_id=${
            data?.user_id
          }&orderby=0&orderbyfield=2`;
        } else {
          remainApiPath = `/cms/crfquerylist?page=${e}&orderby=0&orderbyfield=2`;
        }
      }
    }

    axios.get(`${baseUrl}${remainApiPath}`, myConfig).then((res) => {
      if (res.data.code === 1) {
        let data = res.data.result;
        setCountNotification(res.data.total);
        setRecords(res.data.total);

        let all = [];
        let cmsomId = 1;
        if (e > 1) {
          cmsomId = allEnd * (e - 1) + 1;
        }

        data.map((i) => {
          let data = {
            ...i,
            cid: cmsomId,
          };
          cmsomId++;
          all.push(data);
        });
        setData(all);
        let end = e * allEnd;
        if (end > res.data.total) {
          end = res.data.total;
        }
        let dynamicPage = Math.ceil(res.data.total / allEnd);
        localStorage.setItem("cmscreftotal1", res.data.total);

        let rem = (e - 1) * allEnd;

        if (e === 1) {
          setBig(rem + e);
          setEnd(end);
        } else {
          setBig(rem + 1);
          setEnd(end);
        }
        for (let i = 1; i <= dynamicPage; i++) {
          droppage.push(i);
        }
        setPage(e);
        setDefaultPage(droppage);
        setData(all);
      }
      //    else if (res.data.code === 102) {
      //     clientLogout(axios, history);
      //   }
      else {
        console.log("error", res.data.result);
      }
    });
  };

  const sortMessage = (val, field) => {
    let sort = {
      orderBy: val,
      fieldBy: field,
    };

    localStorage.setItem("cmscref1", 1);
    localStorage.setItem("cmssortcref1", JSON.stringify(sort));

    let data = JSON.parse(localStorage.getItem(`searchcmscref1`));

    let remainApiPath = "/cms/crfquerylist";
    if (data) {
      remainApiPath = `/cms/crfquerylist?page=1&from=${data.fromDate
        ?.split("-")
        .reverse()
        .join("-")}&to=${data.toDate?.split("-").reverse().join("-")}&status=${
        data?.p_status
      }&qno=${data?.query_no}&entity_name=${data?.entity_no}&user_id=${
        data?.user_id
      }&orderby=${val}&orderbyfield=${field}&total=${countNotification}`;
    } else {
      remainApiPath = `/cms/crfquerylist?page=1&orderby=${val}&orderbyfield=${field}&total=${countNotification}`;
    }
    axios.get(`${baseUrl}${remainApiPath}`, myConfig).then((res) => {
      if (res.data.code === 1) {
        let total = res.data?.total;
        setPage(1);
        setBig(1);
        if (total < allEnd) {
          setEnd(total);
        } else {
          setEnd(allEnd);
        }
        let all = [];
        let sortId = 1;

        res.data.result.map((i) => {
          let data = {
            ...i,
            cid: sortId,
          };
          sortId++;
          all.push(data);
        });

        setData(all);
      }
    });
  };

  // useEffect(() => {
  //   console.log("dataCMSCref", data);
  // }, [data]);

  useEffect(() => {
    var element = document.getElementById(scrolledTo);
    if (element) {
      let runTo = myRef.current[scrolledTo];
      runTo?.scrollIntoView(false);
      runTo?.scrollIntoView({ block: "center" });
    }
  }, [detailModal]);

  useEffect(() => {
    var element = document.getElementById(scrolledTo);
    if (element) {
      let runTo = myRef.current[scrolledTo];
      runTo?.scrollIntoView(false);
      runTo?.scrollIntoView({ block: "center" });
    }
  }, [uploadModal]);

  const handleobject = (row) => {
    let stage = "";
    const substring = row.created_date;
    if (row.created_date && row.report_received_date) {
      let rec = row.report_received_date;
      var onlyDate = substring.split(" ");
      var fields = onlyDate[0].split("-");
      let result = `${fields[2]}-${fields[1]}-${fields[0]}`;

      var onlyDaterec = rec.split(" ");
      var fieldsrec = onlyDaterec[0].split("-");
      let retrec = `${fieldsrec[2]}-${fieldsrec[1]}-${fieldsrec[0]}`;

      if (row.status == 0) {
        stage = "Pending";
      } else if (row.status == 1) {
        stage = "Sent to be Prepared";
      } else if (row.status == 2) {
        stage = "Recived";
      } else {
        stage = "Declined";
      }

      setModalData({
        ...row,
        created_date: result,
        status: stage,
        report_received_date: retrec,
      });
    } else if (row.created_date && row.report_decline_date) {
      let dec = row.report_decline_date;

      var onlyDate2 = dec.split(" ");
      var fields2 = onlyDate2[0].split("-");
      let result = `${fields2[2]}-${fields2[1]}-${fields2[0]}`;

      var onlyDatedec = dec.split(" ");
      var fieldsdec = onlyDatedec[0].split("-");
      let resultdec = `${fieldsdec[2]}-${fieldsdec[1]}-${fieldsdec[0]}`;

      if (row.status == 0) {
        stage = "Pending";
      } else if (row.status == 1) {
        stage = "Sent to be Prepared";
      } else if (row.status == 2) {
        stage = "Received";
      } else {
        stage = "Declined";
      }

      setModalData({
        ...row,
        created_date: result,
        status: stage,
        report_decline_date: resultdec,
      });
    } else {
      var onlyDate3 = substring.split(" ");
      var fields3 = onlyDate3[0].split("-");
      let result = `${fields3[2]}-${fields3[1]}-${fields3[0]}`;

      if (row.status == 0) {
        stage = "Pending";
      } else if (row.status == 1) {
        stage = "Sent to be Prepared";
      } else if (row.status == 2) {
        stage = "Received";
      } else {
        stage = "Declined";
      }

      setModalData({ ...row, created_date: result, status: stage });
    }
  };

  const columns = [
    {
      text: "S.No",
      dataField: "",
      headerStyle: () => {
        return { width: "50px" };
      },
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            id={row.query_id}
            ref={(el) => (myRef.current[row.query_id] = el)}
          >
            {row.cid}
          </div>
        );
      },
    },
    {
      text: "Query number",
      headerStyle: () => {
        return { width: "100px" };
      },
      dataField: "query_number",
      sort: true,
      headerFormatter: headerLabelFormatter,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          localStorage.setItem("cmsArrowCref1", field);
        } else {
          setAccend("");
          localStorage.removeItem("cmsArrowCref1");
        }

        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 0);
      },
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div onClick={(e) => showDetailModal(row)} className="querydiv">
            {row.query_id}
          </div>
        );
      },
    },
    {
      text: "User id",
      headerStyle: () => {
        return { width: "200px" };
      },
      dataField: "user_id",
      sort: true,
      headerFormatter: headerLabelFormatter,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          localStorage.setItem("cmsArrowCref1", field);
        } else {
          setAccend("");
          localStorage.removeItem("cmsArrowCref1");
        }

        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 1);
      },
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div onClick={(e) => showDetailModal(row)} className="querydiv">
            {row.user_id}
          </div>
        );
      },
    },
    {
      text: "Query date",
      dataField: "query_date",
      headerStyle: () => {
        return { width: "100px" };
      },
      sort: true,
      headerFormatter: headerLabelFormatter,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          localStorage.setItem("cmsArrowCref1", field);
        } else {
          setAccend("");
          localStorage.removeItem("cmsArrowCref1");
        }

        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 2);
      },
      formatter: (cellContent, row, rowIndex) => {
        const substring = row.created_date;
        var onlyDate = substring.split(" ");
        var fields = onlyDate[0].split("-");
        let result = `${fields[2]}-${fields[1]}-${fields[0]}`;
        return <div>{result}</div>;
      },
    },
    {
      text: "Entity name",
      headerStyle: () => {
        return { width: "200px" };
      },
      dataField: "entity_name",
      sort: true,
      headerFormatter: headerLabelFormatter,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          localStorage.setItem("cmsArrowCref1", field);
        } else {
          setAccend("");
          localStorage.removeItem("cmsArrowCref1");
        }

        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 3);
      },
      formatter: (cellContent, row, rowIndex) => {
        return <div>{row.entity_name}</div>;
      },
    },
    {
      text: "Entity type",
      dataField: "entity_type",
      headerStyle: () => {
        return { width: "100px" };
      },
      sort: true,
      headerFormatter: headerLabelFormatter,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          localStorage.setItem("cmsArrowCref1", field);
        } else {
          setAccend("");
          localStorage.removeItem("cmsArrowCref1");
        }

        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 4);
      },
      formatter: (cellContent, row, rowIndex) => {
        return <div>{row.entity_type}</div>;
      },
    },
    {
      text: "Report status",
      headerStyle: () => {
        return { width: "110px" };
      },
      dataField: "report_status",
      sort: true,
      headerFormatter: headerLabelFormatter,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          localStorage.setItem("cmsArrowCref1", field);
        } else {
          setAccend("");
          localStorage.removeItem("cmsArrowCref1");
        }

        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 5);
      },
      formatter: (cellContent, row, rowIndex) => {
        let status = "";
        if (row.status == 0) {
          status = "Pending";
        } else if (row.status == 1) {
          status = "Sent to be Prepared";
        } else if (row.status == 2) {
          status = "Received";
        } else {
          status = "Declined";
        }
        return <div>{status}</div>;
      },
    },
    {
      text: "Date received/declined",
      dataField: "other_date",
      headerStyle: () => {
        return { width: "130px" };
      },
      sort: true,
      headerFormatter: headerLabelFormatter,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          localStorage.setItem("cmsArrowCref1", field);
        } else {
          setAccend("");
          localStorage.removeItem("cmsArrowCref1");
        }

        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 6);
      },
      formatter: (cellContent, row, rowIndex) => {
        if (row.report_received_date) {
          const substring = row.report_received_date;
          var onlyDate = substring.split(" ");
          var fields = onlyDate[0].split("-");
          let result = `${fields[2]}-${fields[1]}-${fields[0]}`;
          return <div>{result}</div>;
        } else {
          return <div></div>;
        }
      },
    },
    {
      text: "Action",
      headerStyle: () => {
        return { width: "150px" };
      },
      sort: true,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          localStorage.setItem("cmsArrowCref1", field);
        } else {
          setAccend("");
          localStorage.removeItem("cmsArrowCref1");
        }

        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 6);
      },
      formatter: function CmsAction(cell, row) {
        return (
          <>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              {row.file ? (
                <span onClick={(e) => del(row)} className="ml-2">
                  <DeleteIcon />
                </span>
              ) : (
                <span onClick={(e) => showUploadModal(row)} className="ml-1">
                  <UploadDocument />
                </span>
              )}

              {row.file ? (
                <span
                  onClick={() => downloadpdf(row)}
                  style={{ cursor: "pointer" }}
                  title="Download Invoice"
                >
                  <FileIconSmaller name={`name.pdf`} />
                </span>
              ) : (
                <span className="ml-1  pl-3"></span>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const showDetailModal = (row) => {
    setDetailModal(!detailModal);
    handleobject(row);
    setScrolledTo(row.query_id);
    if (detailModal === "true") {
      // setSelectedData(row);
    }
  };

  const showUploadModal = (row) => {
    setUploadModal(!uploadModal);
    handleobject(row);
    setScrolledTo(row.query_id);
    // console.log("test=>", uploadModal);
  };

  const downloadpdf = (row) => {
    // console.log('row===>',row);
    if (row.file) {
      let filename = row.query_id;

      let location = row.file;
      const file = location.split(".");
      const length = file.length;
      const filetype = file[length - 1];

      // console.log('type==>',filetype,row.file);

      const myConfig2 = {
        headers: {
          uit: token,
        },
        responseType: "blob",
      };

      // window.open(
      //   `${baseUrl2}/${url}`,
      //   "_blank",
      //   `rel=crefReport_${filename}.pdf`
      // );
      // console.log(`${baseUrl}/cms/crfdocument?id=${row.id}`);

      axios
        .get(`${baseUrl}/cms/crfdocument?id=${row.id}`, myConfig2)
        .then((res) => {
          if (res.status === 200) {
            window.URL = window.URL || window.webkitURL;
            var url = window.URL.createObjectURL(res.data);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            // console.log(`crefReport_${filename}.${filetype}`);
            a.download = `crefReport_${filename}.${filetype}`;
            //    a.download = "invoice"+{qno}+{installmentNumber}+'.pdf';
            a.target = "_blank";
            a.click();
          }
        });
    }
  };

  const del = (value) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Want to delete query? Yes, delete it!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      // console.log('deleteing',value);
      if (result.value) {
        axios
          .get(`${baseUrl}/cms/deletecrfd?id=${value.id}`, myConfig)
          .then((res) => {
            if (res.data.code === 1) {
              Swal.fire({
                title: "success",
                html: "Query deleted successfully",
                icon: "success",
              });
              getAllcref(page);
            } else {
              Swal.fire({
                title: "error",
                html: "Something went wrong , please try again",
                icon: "error",
              });
            }
          });
      }
    });
  };

  const resetPaging = () => {
    setPage(1);
    setBig(1);
    // setTurnGreen(false);
    // setIsActive(false);
    setresetTrigger(!resetTrigger);
    localStorage.removeItem("cmssortcref1");
    localStorage.removeItem("cmsArrowCref1");
    localStorage.removeItem("prevcmscref1");

    // localStorage.removeItem("searchcref1");
    // getAllcref(1);
  };

  const trigger = () => {
    let pageno = JSON.parse(localStorage.getItem("cmscref1"));
    if (pageno) {
      getAllcref(pageno);
    } else {
      getAllcref(1);
    }
  };

  return (
    <Layout cmsDashboard="cmsDashboard">
      <Card ref={tableId}>
        <CardHeader>
          <Grid item lg={12} mx={0}>
            <Col md="12">
              <CrefFilter
                setData={setData}
                getData={getAllcref}
                allCref="allCref"
                setRecords={setRecords}
                records={records}
                setDefaultPage={setDefaultPage}
                resetPaging={resetPaging}
                setCountNotification={setCountNotification}
                page={page}
                setBig={setBig}
                setEnd={setEnd}
                index="cmscref1"
              />
            </Col>
          </Grid>
        </CardHeader>
        <CardBody>
          <Grid item lg={12} mx={0} my={1}>
            <Col md="12" align="right">
              <div className="customPagination">
                {countNotification > 0 ? (
                  <div className="ml-auto d-flex w-100 align-items-center justify-content-end">
                    <span className="customPaginationSpan">
                      {big}-{end} of {countNotification}
                    </span>
                    <span className="d-flex">
                      {page > 1 ? (
                        <>
                          <button
                            className="navButton"
                            onClick={(e) => firstChunk()}
                          >
                            <KeyboardDoubleArrowLeftIcon />
                          </button>
                          <button
                            className="navButton"
                            onClick={(e) => prevChunk()}
                          >
                            <KeyboardArrowLeftIcon />
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="navButtonSelectDiv">
                        <select
                          value={page}
                          onChange={(e) => {
                            setPage(Number(e.target.value));
                            getAllcref(Number(e.target.value));
                          }}
                          className="form-control"
                        >
                          {defaultPage?.map((i) => (
                            <option value={i}>{i}</option>
                          ))}
                        </select>
                      </div>
                      {defaultPage?.length > page ? (
                        <>
                          <button
                            className="navButton"
                            onClick={(e) => nextChunk()}
                          >
                            <KeyboardArrowRightIcon />
                          </button>
                          <button
                            className="navButton"
                            onClick={(e) => lastChunk()}
                          >
                            <KeyboardDoubleArrowRightIcon />
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                ) : (
                  <div className="ml-auto d-flex w-100 align-items-center justify-content-end">
                    <span className="customPaginationSpan nullClass">
                      0 - 0 of 0
                    </span>
                  </div>
                )}
              </div>
            </Col>
          </Grid>
          <Grid item lg={12} mx={1}>
            <div className="mx-1">
              <DataTablepopulated
                bgColor="#5f7b97"
                keyField={"assign_no"}
                data={data}
                columns={columns}
              ></DataTablepopulated>
              <CrefModal
                showDetailModal={showDetailModal}
                detailModal={detailModal}
                selectedData={modalData}
                downloadpdf={downloadpdf}
              />
              <CrefUpload
                showUploadModal={showUploadModal}
                uploadModal={uploadModal}
                selectedData={modalData}
                trigger={trigger}
              />
            </div>
          </Grid>
        </CardBody>
      </Card>
    </Layout>
  );
};
export default CrefManage;
