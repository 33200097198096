import React, { useEffect, useState } from "react";
// import mazars from "./../../assets/images/mazars-logo.png";
import mazars from "../../../assets/images/mazars-logo.png";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
// import MainHeading from "../Common/MainHeading";
import MainHeading from "../../../components/Common/MainHeading.js";
import { useHistory } from "react-router-dom";
import CustomTypography from "../../../components/Common/CustomTypography";
// import CustomTypography from "../Common/CustomTypography";
import CustomHeading from "../../../components/Common/CustomHeading";
import tlLogout from "../../TL/TlLogout/TlLogout.js";
// import CommonServices from "../../common/common";
import CommonServices from "../../../common/common";

const LogoutHeader = (props) => {
  const history = useHistory();
  const clName = JSON.parse(localStorage.getItem("clientLoginId"));
  const CustEmail = window.localStorage.getItem("custEmail");

  const [atHome, setAtHome] = useState(false);

  useEffect(() => {
    if (props.name == "Mazars Advisory Solutions") {
      setAtHome(true);
    }
  }, [props]);

  const tpEmail = window.localStorage.getItem("tpEmail");
  const name = "Team Leader";

  return (
    <>
      <Grid fluid>
        <Grid item lg={12}>
          <div className="headerMin">
            <Link to="/taxprofessional/dashboard">
              <img src={mazars} alt="mazar" className="logo" />
            </Link>
            <>
              <div className="d-flex profileDetail">
                <CustomTypography
                  font={18}
                  margin="20px 20px"
                >{`Tax professional: `}</CustomTypography>
                {/* <CustomHeading>
                    {CommonServices.capitalizeFirstLetter(name)}:{" "}
                    {JSON.parse(tlEmail)}
                  </CustomHeading> */}
                <div>
                  <CustomTypography style={{ paddingBottom: "0px" }}>
                    {JSON.parse(tpEmail)}
                  </CustomTypography>
                  {/* <CustomTypography margin="0px" style={{ paddingTop: "0px" }}>
                    
                  </CustomTypography> */}

                  {/* <CustomHeading>
                    {CommonServices.capitalizeFirstLetter(name)}:{" "}
                    {JSON.parse(tlEmail)}
                  </CustomHeading> */}
                </div>
              </div>
            </>
            <span className="loginHeading onTop" style={{ width: "55%" }}>
              <MainHeading style={{ position: "absolute", top: "0" ,marginTop : "20px" }}>
                {props.name}
              </MainHeading>
            </span>

            {atHome ? (
              ""
            ) : (
              <Link to="/customer/masterdashboard">
                <button class="customBtn" style={{ margin: "18px" }}>
                  Go Back
                </button>
              </Link>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default LogoutHeader;
