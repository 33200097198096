import React, { useState, useEffect, useLayoutEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { Container, Grid, Paper, Box } from "@material-ui/core";
// import CrefPage from "./CrefPage";
import { Tab, Tabs, TabPanel, TabList } from "react-tabs";
import { useHistory } from "react-router-dom";
import LogoutHeader from "../CompliAny/LogoutHeader";
import CustomHeading from "../Common/CustomHeading";
import CompCard from "./CompCard";
// import Cref from "../CompliAny/Cref";
// import CrefDec from "../CompliAny/CrefDec";
// import CrefPending from "../CompliAny/CrefPending";
// import CrefRec from "../CompliAny/CrefRec";
import { clientLogout } from "../Logout/ClientLogout";

function DirectCustComp(props) {
  let history = useHistory();
  const userId = window.localStorage.getItem("userid");
  const [tabIndex, setTabIndex] = useState(0);
  const [bgColor, setbgColor] = useState("#42566a");
  const custAccess = JSON.parse(window.localStorage.getItem("cust_access"));

  const tableIndex = (index) => {
    setTabIndex(index);
    // console.log(index);
    if (index === 0) {
      setbgColor("#42566a");
    } else if (index === 1) {
      setbgColor("#5f7b97");
    } else if (index === 2) {
      setbgColor("#5f7b97");
    } else if (index === 3) {
      setbgColor("#5f7b97");
    }
  };
  const myStyle1 = {
    margin: "10px auto",
    fontSize: "14px",
  };
  const myStyle2 = {
    margin: "10px auto",

    color: "#42566a",
    fontWeight: 1000,
  };

  useEffect(() => {
    console.log("custAccess..", custAccess);
  }, [custAccess]);

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default right-click context menu
  };

  return (
    <>
      {/* <Grid container spacing={2}>
        <Grid item lg={12}>
          <LogoutHeader name="Compliance Dashboard" />
        </Grid>
      </Grid> */}
      <Layout compDashboard="compDashboard" custUserId={userId}>
        <Container maxWidth="xl" onContextMenu={handleContextMenu}>
          <div class="row mt-3">
            <div class="col-xl-12 col-lg-12 col-md-12">
              <div class="card">
                <CompCard compPosition="direct_tax" />
              </div>
            </div>
          </div>
        </Container>
      </Layout>
    </>
  );
}

export default DirectCustComp;
