import React from "react";

function Mandatory(props) {
  return (
    <>
      <span className="declined mr-4">*Mandatory</span>
    </>
  );
}

export default Mandatory;
