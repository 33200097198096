import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { baseUrl } from "../../config/config";
import classNames from "classnames";
import Swal from "sweetalert2";
import { clientLogout } from "../Logout/ClientLogout";
import { DeleteIcon, EditQuery } from "../Common/MessageIcon";
import DataTablepopulated from "../DataTablepopulated/DataTabel";
import Alerts from "../../../src/common/Alerts"
import { Spinner } from "reactstrap";

const Schema = yup.object().shape({
  message_type: yup.string().required(""),
  address: yup.string().required(""),
});
const CrefPage = (props) => {
  const history = useHistory();
  const selectRef = useRef(null);
  const token = window.localStorage.getItem("clientToken");
  const myConfig = {
    headers: {
      uit: token,
    },
  };
  const [data, setData] = useState([]);
  const [randomNum, setRandomNum] = useState(null);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [entityName, setEntityName] = useState("");
  const [entityType, setEntityType] = useState("");
  const [gst, setGst] = useState("");
  const [cin, setCin] = useState("");
  const [pan, setPan] = useState("");
  const [address, setAddress] = useState("");
  const [editId, setEditId] = useState("");
  const [userError, setUserError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [addError, setAddError] = useState("");
  const [gstError, setGstError] = useState("");
  const [panError, setPanError] = useState("");
  const [formData, setFormData] = useState({
    entity_name: "",
    entity_type: "",
    gst: "",
    cin: "",
    pan: "",
    address: "",
    identifier: "",
  });

  let indent =localStorage.getItem("crefIdentifier");
  let paydone =localStorage.getItem("paydone")

  const columns = [
    {
      text: "S.No",
      dataField: "",
      headerStyle: () => {
        return { width: "50px" };
      },
      formatter: (cellContent, row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      text: "Entity name",
      dataField: "",
      formatter: (cellContent, row, rowIndex) => {
        return <div>{row.entity_name}</div>;
      },
    },
    {
      text: "Entity type",
      dataField: "",
      formatter: (cellContent, row, rowIndex) => {
        return <div>{row.entity_type}</div>;
      },
    },
    {
      text: "PAN",
      dataField: "",
      formatter: (cellContent, row, rowIndex) => {
        return <div>{row.pan}</div>;
      },
    },
    {
      text: "CIN",
      dataField: "",
      formatter: (cellContent, row, rowIndex) => {
        return <div>{row.cin}</div>;
      },
    },
    {
      text: "GST",
      dataField: "",
      formatter: (cellContent, row, rowIndex) => {
        return <div>{row.gst}</div>;
      },
    },
    {
      text: "Amount",
      dataField: "",
      formatter: (cellContent, row, rowIndex) => {
        return <div>&#8377;{row.amount}</div>;
      },
    },
    {
      text: "Action",
      headerStyle: () => {
        return { width: "150px" };
      },
      formatter: function CmsAction(cell, row) {
        return (
          <>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <span title="Edit Articles" onClick={() => edit(row)}>
                <EditQuery />
              </span>
              <span
                title="Delete Articles"
                onClick={() => del(row)}
                className="mx-2"
              >
                <DeleteIcon />
              </span>
            </div>
          </>
        );
      },
    },
  ];

  const validateUser = (e) => {
    var specialChars = /[^a-zA-Z0-9 ]/g;
    var matches = e?.match(specialChars);
    if (e?.length === 0) {
      setUserError("Please enter Entity Name");
    } else if (matches != null) {
      setUserError("Please Enter valid Entity Name");
    } else {
      setUserError("");
      // setOffError("");
    }
  };

  const validateType = (e) => {
    if (e?.length === 0) {
      setTypeError("Please enter valid user id");
    } else {
      setTypeError("");
      // setOffError("");
    }
  };

  const validateAdd = (e) => {
    if (e?.length === 0) {
      setAddError("Please enter Address");
    } else {
      setAddError("");
      // setOffError("");
    }
  };

  const validatepan = (e) => {
    if((e?.length === 0)||(e?.length === 10)){
    setPanError("");
    }else{
      setPanError("Pan")
      // console.log('ePan==>',e?.length);
    }
  };

  const validategst = (e) => {
    if((e?.length === 0)||(e?.length === 15)){
      setGstError("");
      }else{
        setGstError("Gst")
        // console.log('eGst==>',e?.length);
      }
  };


  const generateRandomNumber = () => {
    localStorage.removeItem("crefIdentifier")
    // console.log('removeeee');
    let time =Date.now();
    const userId = JSON.parse(window.localStorage.getItem("userid"))
    const usernum = parseInt(userId);
    const randomNumber = `${usernum}${time}`;
    // localStorage.setItem("crefIdentifier",randomNumber);
    // console.log('random==>',randomNumber);
    setRandomNum(randomNumber);
    localStorage.removeItem("paydone");
  };

  useEffect(() => {
    getData();
    setLoading(false)
    if((!indent) || (paydone)){
      // console.log('new or done');
    generateRandomNumber();
    }else{
      // console.log('old and working');
      setRandomNum(indent);
      getTableData(indent);
    }

  }, []);

  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(Schema),
  });

  useEffect(() => {
    handletotal();
  }, [data]);

  const handletotal = () => {
    let i = 0;
    let sum = 0;
    let tot = data.map((data) => data.amount).map(Number);
    for (i = 0; i < tot.length; i++) {
      sum += tot[i];
      setTotal(sum);
    }
  };

  // const handlePay = () => {
  //   setPaymentModal(!addPaymentModal);
  // };


  const onSubmit = (e) => {
    e.preventDefault();

    validateUser(entityName);
    validateType(entityType);
    validateAdd(address);
    validategst(gst);
    validatepan(pan);

    // let data = formData;
    let data = new FormData();
    data.append("entity_name", entityName);
    data.append("entity_type", entityType);
    data.append("cin", cin);
    data.append("pan", pan);
    data.append("gst", gst);
    data.append("identifier", randomNum);
    data.append("address", address);

    if (
      typeError.length === 0 &&
      userError.length === 0 &&
      address.length !== 0  && 
      ((gst?.length === 0)||(gst?.length === 15)) && 
      ((pan.length === 0)||(pan.length === 10))
    ) {
      axios({
        method: "POST",
        url: `${baseUrl}/crfcustomers/addprequery`,
        headers: {
          uit: token,
        },
        data: data,
      }).then((res) => {
        // console.log("response", res);
        if (res.data.code === 1) {
          Swal.fire({
            title: "success",
            html: "Query submitted successfully",
            icon: "success",
          });
          getTableData(randomNum);
          localStorage.setItem("crefIdentifier", randomNum);
          setEntityName("");
          setEntityType("");
          setCin("");
          setPan("");
          setAddress("");
          // setOffError("Reset Done");
          // history.push("/customer/crefpage");
        } else if (res.data.code === 0) {
          Swal.fire({
            title: "error",
            html: `${res.data.message}`,
            icon: "error",
          });
        } else if (res.data.code === 102) {
          clientLogout(axios, history);
        }
      });
    }
  };

  const onEdit = (e) => {
    e.preventDefault();

    validateUser(entityName);
    validateType(entityType);
    validateAdd(address);

    let data = new FormData();
    data.append("entity_name", entityName);
    data.append("entity_type", entityType);
    data.append("cin", cin);
    data.append("pan", pan);
    data.append("gst", gst);
    data.append("identifier", randomNum);
    data.append("address", address);
    data.append("id", editId);

    if (
      ((typeError.length === 0)||(entityName.length !== 0)) &&
      ((userError.length === 0 )|| (entityType.length !== 0))&&
      addError.length === 0 && 
      ((gst?.length === 0)||(gst?.length === 15)) && 
      ((pan.length === 0)||(pan.length === 10))
    ) {
      axios({
        method: "POST",
        url: `${baseUrl}/crfcustomers/addprequery`,
        headers: {
          uit: token,
        },
        data: data,
      }).then((res) => {
        // console.log("response", res);
        if (res.data.code === 1) {
          Swal.fire({
            title: "success",
            html: "Query edited successfully",
            icon: "success",
          });
          getTableData(randomNum);
          setEntityName("");
          setEntityType("");
          setCin("");
          setPan("");
          setAddress("");
          // setOffError("Reset Done");
          setEditing(false);
          // history.push("/customer/crefpage");
        } else if (res.data.code === 0) {
          Swal.fire({
            title: "error",
            html: `${res.data.message}`,
            icon: "error",
          });
        } else if (res.data.code === 102) {
          clientLogout(axios, history);
        }
      });
    }
  };

  const resetData = (value) => {
    setFormData((values) => ({
      ...formData,
      entity_name: "",
      entity_type: "",
      gst: "",
      cin: "",
      pan: "",
      address: "",
    }));
    setEntityName("");
    setEntityType("");
    // setOffError("Reset Done");
    setGst("");
    setCin("");
    setPan("");
    setAddress("");
    setEditing(false);
    setUserError("");
    setTypeError("");
    setAddError("");
    setPanError("");
    setGstError("");
  };

  const getData = () => {
    axios.get(`${baseUrl}/customers/getclientdata`, myConfig).then((res) => {
      if (res.data.result.gstin_no) {
        setFormData((values) => ({
          ...formData,
          entity_name: "",
          entity_type: "",
          gst: `${res.data.result.gstin_no}`,
          cin: "",
          pan: "",
          address: "",
        }));
        setGst(`${res.data.result.gstin_no}`);
      }else if (res.data.code === 102) {
        clientLogout(axios, history);
      }else {
        console.log("no new table");
      }
    });
  };

  const del = (value) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete query?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      // console.log('deleteing',value);
      if (result.value) {
        axios
          .get(
            `${baseUrl}/crfcustomers/deleteprequery?identifier=${localStorage.getItem(
              "crefIdentifier"
            )}&id=${value.id}`,
            myConfig
          )
          .then((res) => {
            if (res.data.code === 1) {
              Swal.fire({
                title: "success",
                html: "Query deleted successfully",
                icon: "success",
              });
              getTableData(randomNum);
            } else if (res.data.code === 102) {
              clientLogout(axios, history);
            } else {
              Swal.fire({
                title: "error",
                html: "Something went wrong , please try again",
                icon: "error",
              });
            }
          });
      }
    });
  };

  const edit = (value) => {
    // console.log('editing',value);
    setEntityName(`${value.entity_name}`);
    setEntityType(`${value.entity_type}`);
    setGst(`${value.gst}`);
    setCin(`${value.cin}`);
    setPan(`${value.pan}`);
    setAddress(`${value.address}`);
    setEditId(`${value.id}`);
    setEditing(true);
  };

  const getTableData = (randomNo) => {
    // if (localStorage.getItem("crefIdentifier")) {
      axios
        .get(
          `${baseUrl}/crfcustomers/prequerylist?identifier=${randomNo}`,
          myConfig
        )
        .then((res) => {
          if (res.data.code === 1) {
            let data = res.data.result;
            setData(data);
          } else if (res.data.code === 102) {
            clientLogout(axios, history);
          } else {
            console.log("error", res.data.result);
          }
        });
    // } else {
    //   console.log("no new table");
    // }
  };

  const onPay = (value) => {
    setLoading(true)

    // alert(`${baseUrl}/crfcustomers/generatelink?identifier=${value}`);
    console.log(`${baseUrl}/crfcustomers/generatelink?identifier=${value}`);

    axios.get(`${baseUrl}/crfcustomers/generatelink?identifier=${value}`, myConfig)
    .then(function (response) {
        if (response.data.code === 1) {
          setLoading(false)
          // console.log('response==>',response.data.result);
          let payLink = response.data.result;
          localStorage.setItem("paydone",true);
          localStorage.removeItem("crefIdentifier")
          console.log('paylink',response);
          // Alerts.SuccessNormal("Payment Done")
          // getPaymentStatus();
          // handlePay();
          window.open(`${payLink}`,"_self");
        } else if (response.data.code === 0) {
          setLoading(false);
          Alerts.ErrorNormal("Please try later");
        }else if (response.data.code === 102) {
          clientLogout(axios, history);
        }
      })
      .catch((error) => {Alerts.ErrorNormal("Please try later")});

  };

  // const delid = (value) => {
  //   localStorage.removeItem("crefIdentifier")
  // };

  // useEffect(() => {
  // console.log('randomnumber==>',randomNum);
  // }, [randomNum])


  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <form onSubmit={handleSubmit(onSubmit)} id="crefForm">
            <div
              class="row m-5"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    Entity Name <span className="declined">*</span>
                  </label>
                  <span title="Please enter entity name.">
                    <input
                      type="text"
                      id="entity_name"
                      name="entity_name"
                      className={classNames("form-control", {
                        "is-invalid":
                          // errors.entity_name ||
                          userError.length > 0,
                      })}
                      ref={register}
                      onChange={(e) => setEntityName(e.target.value)}
                      onBlur={(e) => validateUser(e.target.value)}
                      value={entityName}
                    />
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    Entity Type <span className="declined">*</span>
                  </label>
                  <span title="Please select entity type.">
                  <select
                    className={classNames("form-control", {
                      "is-invalid":
                        // errors.entity_type ||
                        typeError.length > 0,
                    })}
                    name="entity_type"
                    id="entity_type"
                    ref={selectRef}
                    onChange={(e) => setEntityType(e.target.value)}
                    defaultValue={""}
                    value={entityType}
                  >
                    <option value="">---Select Entity Type---</option>
                    <option value="Company">Company</option>
                    <option value="LLP">LLP</option>
                    <option value="Partnership">Partnership</option>
                    <option value="Proprietorship">Proprietorship</option>
                    <option value="Others">Others</option>
                  </select>
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>GST</label>
                  <span title="Please enter valid GST number.">
                  <input
                    type="text"
                    name="gst"
                    id="gst"
                    className={classNames("form-control", {
                      "is-invalid":
                        // errors.entity_name ||
                        gstError.length > 0,
                    })}
                    ref={register}
                    onChange={(e) => setGst(e.target.value)}
                    onBlur={(e) => validategst(e.target.value)}
                    value={gst}
                  />
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>CIN</label>
                  <input
                    type="text"
                    name="cin"
                    id="cin"
                    className="form-control"
                    ref={register}
                    onChange={(e) => setCin(e.target.value)}
                    value={cin}
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>PAN</label>
                  <span title="Please enter valid PAN.">
                  <input
                    type="text"
                    name="pan"
                    id="pan"
                    className={classNames("form-control", {
                      "is-invalid":
                        // errors.entity_name ||
                        panError.length > 0,
                    })}
                    ref={register}
                    onChange={(e) => setPan(e.target.value)}
                    onBlur={(e) => validatepan(e.target.value)}
                    value={pan}
                  />
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    Address<span className="declined">*</span>
                  </label>
                  <span title="Enter Address.">
                    <textarea
                      className={classNames("form-control", {
                        "is-invalid":
                          // errors.p_message ||
                          addError.length > 0,
                      })}
                      placeholder="Address here"
                      rows="5"
                      ref={register}
                      onChange={(e) => setAddress(e.target.value)}
                      value={address}
                      name="address"
                      id="address"
                    ></textarea>
                    {errors.address && (
                      <div className="invalid-feedback">
                        {errors.p_message.message}
                      </div>
                    )}
                  </span>
                </div>
              </div>

              {/* <button
                className="autoWidthBtn mx-2"
                type="submit"
                form="crefForm"
                onClick={(e) => onSubmit(e)}
              >
                Save
              </button> */}

              {editing == false ? (
                <button
                  className="customBtn mx-2"
                  type="submit"
                  form="crefForm"
                  onClick={(e) => onSubmit(e)}
                >
                  Save
                </button>
              ) : (
                <button
                  className="customBtn mx-2"
                  type="submit"
                  form="crefForm"
                  onClick={(e) => onEdit(e)}
                >
                  Edit
                </button>
              )}

              <button
                onClick={(e) => resetData()}
                className="customBtn mx-2"
                type="button"
              >
                Cancel
              </button>
            </div>
          </form>
        </Grid>
        {/* <button
                  className="customBtn mx-2"
                  // type="submit"
                  // form="crefForm"
                  onClick={(e) => delid(e)}
                >
                  delid
                </button> */}
      </Grid>
      {data.length > 0 ? (
        <Grid item lg={12} m={2}>
          <div className="mx-5">
            <DataTablepopulated
              height="auto"
              bgColor="#5f7b97"
              keyField={"assign_no"}
              data={data}
              columns={columns}
            ></DataTablepopulated>
            {/* <PaymentModal
              addPaymentModal={addPaymentModal}
              handlePay={handlePay}
              total={total}
              identifier={randomNum}
            /> */}
          </div>
        </Grid>
      ) : (
        ""
      )}
      {data.length > 0 ? (
        <Grid container direction="row-reverse" spacing={2}>
          <Grid item xs={5}>
            <div
              className="mx-0 mt-3"
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "45px",
                alignItems: "center",
              }}
            >
              <div style={{minWidth: "190px", paddingLeft: "60px" }}>Total Payment:</div>
              <div style={{ minWidth: "110px", marginRight: "5px" }}>
                {" "}
                &#8377;{total}{" "}
              </div>
              <div style={{ marginRight: "50px" }}>
                { (loading == true) ? 
                (<Spinner />) :
                (<button
                  className="customBtn mx-2"
                  type="submit"
                  onClick={(e) => onPay(randomNum)}
                >
                  Pay
                </button>)}
              </div>
            </div>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};
export default CrefPage;
