//for server
export const baseUrl = "https://api-advisorysolutions.mazars.co.in/v1";
export const ImageUrl = "https://api-advisorysolutions.mazars.co.in/assets/image";
export const ReportUrl =
"https://api-advisorysolutions.mazars.co.in/assets/upload/report";
export const baseUrl2 = "https://api-advisorysolutions.mazars.co.in/";
export const baseUrl3 = "https://api-advisorysolutions.mazars.co.in/";

//for development
// export const baseUrl = "https://stagingapi-advisorysolutions.mazars.co.in/v1";
// export const ImageUrl =
//   "https://stagingapi-advisorysolutions.mazars.co.in/assets/image";
// export const ReportUrl =
//   "https://stagingapi-advisorysolutions.mazars.co.in/assets/upload/report";
// export const baseUrl2 = "https://stagingapi-advisorysolutions.mazars.co.in";
// export const baseUrl3 = "https://stagingapi-advisorysolutions.mazars.co.in";

// // //for local
// export const baseUrl = "https://mazarstagingapi.videostech.cloud/v1";
// export const ImageUrl = "https://mazarstagingapi.videostech.cloud/assets/image";
// export const ReportUrl =
//   "https://mazarstagingapi.videostech.cloud/assets/upload/report";
// export const baseUrl2 = "https://mazarstagingapi.videostech.cloud";
// export const baseUrl3 = "https://mazarstagingapi.videostech.cloud";