// import { baseUrl } from "../../config/config";
import { baseUrl } from "../../../config/config";
import axios from "axios";


const tlLogout = (history) => {
    const token = window.localStorage.getItem("tlToken");
    const myConfig = {
      headers: {
        uit: token,
      },
    };
    console.log('hereWhy');
    axios.get(`${baseUrl}/tl/logout`, myConfig).then((res) => {
      localStorage.removeItem("tlkey");
      localStorage.removeItem("tlEmail");
      localStorage.removeItem("category");
      localStorage.removeItem("tlToken");
      localStorage.removeItem("TlCompClientId");
      localStorage.removeItem("TlClientPerm");
      localStorage.removeItem("TLViewType");
      localStorage.removeItem("TlClientAccess");
      localStorage.removeItem("TlClientList");
      localStorage.removeItem("searchDatatlquery1");
      localStorage.removeItem("searchDatatlquery2");
      localStorage.removeItem("searchDatatlquery3");
      localStorage.removeItem("searchDatatlquery4");
      localStorage.removeItem("searchDatatlproposal1");
      localStorage.removeItem("searchDatatlproposal2");
      localStorage.removeItem("searchDatatlproposal3");
      localStorage.removeItem("searchDatatlproposal4");
      localStorage.removeItem("searchDatatlpayment1");
      localStorage.removeItem("searchDatatlpayment2");
      localStorage.removeItem("searchDatatlpayment3");
      localStorage.removeItem("searchDatatlAssignment1");
      localStorage.removeItem("searchDatatlAssignment2");
      localStorage.removeItem("searchDatatlAssignment3");
      localStorage.removeItem("searchDatatlAssignment4");
      localStorage.removeItem("tlcreate");
      localStorage.removeItem("tlgenerated");
      localStorage.removeItem("tl_record_per_page");
      axios.get(`${baseUrl}/customers/getCategory?pid=0`).then((res) => {
        if (res.data.code === 1) {
          localStorage.removeItem("tlcategoryData");
          let data = res.data.result;
          data.map((i) => {
            localStorage.removeItem(`tl${i.details}`);
          });
        }
      });
      history.push("/teamleader/login");

      history.push("/teamleader/login");
    });
  };


  export default tlLogout;
