import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Collapse,
} from "reactstrap";
import { Select } from "antd";
import axios from "axios";
import { baseUrl, baseUrl2 } from "../../../config/config";
import Swal from "sweetalert2";
import { startCase } from "lodash";

function Tlcustedit({ hist, toggle, modal }) {

    const custId =hist?.id;
  const { Option } = Select;
  const token = window.localStorage.getItem("adminToken");
  const myConfig = {
    headers: {
      uit: token,
    },
  };

//   console.log('hist=>',hist);

  const [count, setCount] = useState("");
  const [sub, setSub] = useState([]);
  const [primecrif, setPrimecrif] = useState(false);
  const [secCrif, setSecCrif] = useState([]);
  const [permOption, setPermOption] = useState([]);
  const [stats, setStats] = useState(false);
  const [showSubCat, setShowSubCat] = useState([]);
  const { handleSubmit, register, errors, reset } = useForm();
  const [disable, setDisable] = useState(false);
  const [allSub, setAllsub] = useState([]);
//   const [ hist,setHistory]=useState([]);

  const selectInputRef = useRef();
  var subPerval;

  const options2 = permOption.map((v) => ({
    value: v.id,
    label: v.details,
  }));

  //change date format
  function ChangeFormateDate(oldDate) {
    if (oldDate == null) {
      return null;
    }
    return oldDate.toString().split("-").reverse().join("-");
  }
  const clientEnable = (e, value) => {
    let formData = new FormData();
    if (e.target.checked === true) {
      formData.append("status", "1");
    } else {
      formData.append("status", "0");
    }

    // formData.append("id", value);

    // axios({
    //   method: "POST",
    //   url: `${baseUrl}/admin/clientstatus`,
    //   headers: {
    //     uit: token,
    //   },
    //   data: formData,
    // }).then((res) => {
    //   if (res.data.code === 1) {
    //     // getCustomer();
    //   } else {
    //     // getCustomer();
    //     Swal.fire({
    //       title: "error",
    //       html: "Something went wrong, please try again",
    //       icon: "error",
    //     });
    //   }
    // });
  };

//   const getClientData = () => {

//     axios
//     .get(`${baseUrl}/admin/getAllList?id=${custId}`, myConfig)
//     .then((res) => {
//       console.log("getting=>", res.data.result);
//       let result =res.data.result
//       setHistory(result[0]);
//     });
//   }

  const crifperm = (e, f) => {
    console.log("e=>", e.target.checked, "f=>", f);
    setPrimecrif(!primecrif);

    let formData = new FormData();
    formData.append("id",  hist.id);
    if (e.target.checked === true) {
      let perm = 1;
      formData.append("complianceservice", perm);
    } else {
      let perm = 0;
      formData.append("complianceservice", perm);
    }

    axios
      .post(`${baseUrl2}/v1/admin/complianceservice`, formData, {
        headers: {
          "content-type": "multipart/form-data",
          uit: token,
        },
      })
      .then((res) => {
        if (res.data.code === 1) {
          if(stats == true){
            Swal.fire({
              title: "success",
              html: "Compliance status changed to deactive successfully",
              icon: "success",
            });
          }else{
            Swal.fire({
              title: "success",
              html: "Compliance status changed to active successfully",
              icon: "success",
            });
          }
          setStats(!stats);
        } else if (res.data.code === 0) {
          // Swal.fire({
          //   title: "success",
          //   html: "Compliance status changed successfully",
          //   icon: "success",
          // });
          // setStats(false);
        } else {
        }
      });
  };



  function splitString(str) {
    const substrings = str?.split(",");
    return substrings;
  }

  useEffect(() => {
    
    setAllsub([
      {
        id: "1",
        value: "Direct Tax",
        name: "direct_tax",
        status: true,
      },
      {
        id: "2",
        value: "Indirect Tax",
        name: "indirect_tax",
        status: false,
      },
      {
        id: "3",
        value: "Corporate",
        name: "corporate",
        status: false,
      },
      {
        id: "4",
        value: "Accounting",
        name: "accounting",
        status: false,
      },
      { id: "5", value: "Others", name: "other", status: false },
    ]);
  }, []);
  useEffect(() => {
    // getClientData()
  }, [custId]);

  useEffect(() => {
    if ( hist) {
    //   console.log(" hist",  hist);
      if (Number( hist.compliance_module) === 1) {
        setStats(true);
      } else {
        setStats(false);
      }
      getinfo();
      setSub(splitString( hist.secondary_email));
    }
  }, [ hist]);

  const getinfo = () => {
    axios.get(`${baseUrl}/admin/tlcompliancelist?tl_id=${hist.id}`, myConfig).then((res) => {
        console.log("getting=>", res.data.result);
        let allSub = res.data.result;
        allSub.forEach((obj) => {
          obj.status = false;
        });
        setSecCrif(allSub);
      });
  };


  useEffect(() => {
    console.log("stats=>", stats);
  }, [stats]);

  useEffect(() => {
    console.log("secCrif", secCrif);
  }, [secCrif]);

  const showsub = (e) => {
    // console.log("e=>", e);
    if(stats === true){
    const fo = secCrif.find(item => item.id === e);
    console.log('fo=>',fo);
    setSecCrif((prevState) =>
      prevState.map((item) => {
        if (item.id === e) {
          return { ...item, status: true };
        }
        return { ...item, status: false };
      })
    );
    // setAllsub((prevState) =>
    //   prevState.map((item) => {
    //     return  { ...item, status: false };
    //   })
    // );
    setAllsub((prevState) =>
      prevState.map((item) => {
        if (item.name === 'direct_tax') {
          if(fo?.direct_tax == 1){
            return { ...item, status: true, };
          }else{
            return{ ...item, status: false, };
          }
        }
        else if(item.name === 'indirect_tax'){
          if(fo?.indirect_tax == 1){
            return { ...item, status: true, };
          }else{
            return{ ...item, status: false, };
          }
        }else if(item.name === 'accounting'){
          if(fo?.accounting == 1){
            return { ...item, status: true, };
          }else{
            return{ ...item, status: false, };
          }
        }else if(item.name === 'corporate'){
          if(fo?.direct_tax == 1){
            return { ...item, status: true, };
          }else{
            return{ ...item, status: false, };
          }
        }else{
          if(fo?.other == 1){
            return { ...item, status: true, };
          }else{
            return{ ...item, status: false, };
          }
        }
        
      })
    );
    }else{}
  };

  const hidesub = (e) => {
    console.log("e=>", e);
    setSecCrif((prevState) =>
      prevState.map((item) => {
        if (item.id === e) {
          return { ...item, status: false };
        }
        return item;
      })
    );
  };

  const handleSubCategory = (e) => {
    // console.log("subcatID=>", name, "subcat=>", value);
    // setShowSubCat(value);
    const { name, checked } = e.target;
    let allId = [];
    setAllsub((prevState) =>
      prevState.map((item) => {
        if (item.name === name) {
          return { ...item, status: checked, };
        }
        return item;
      })
    );
  };

  useEffect(() => {
    console.log("allSub=>", allSub);
  }, [allSub]);
  // console.log('allSub=>',allSub);


  const onSubmit = (value) => {
    // preventDefault()
    console.log("f0",
    value
    );

    let formData = new FormData();
    if(value?.id?.length > 0){
      formData.append("id",Number(value?.id));
    }else{
      formData.append("id",null);
    }
    
    formData.append("client_id", hist.id);
    formData.append("subclient_id",value.subclient_id);

    const addSub = (e) => {
      const foundObject = allSub.find(item => item.name === e);
      if (foundObject) {
        const subStatus = foundObject.status;
        if(subStatus == true){
          return 1
        }else{
          return 0
        }
      } else {
        return 0
      }
    }
    // const direct = allSub.filter(item => item.name === "direct_tax");

      formData.append("direct_tax",addSub('direct_tax'));
      formData.append("indirect_tax",addSub("indirect_tax"));
      formData.append("corporate",addSub("corporate"));
      formData.append("accounting",addSub("accounting"));
      formData.append("other",addSub("other"));

    
    axios
      .post(`${baseUrl2}/v1/admin/updatesubcustomer`, formData, {
        headers: {
          "content-type": "multipart/form-data",
          uit: token,
        },
      })
      .then((res) => {
        if (res.data.code === 1) {
          Swal.fire({
            title: "success",
            html: "Compliance Permission changed successfully",
            icon: "success",
          });
          // setStats(true);
        } else if (res.data.code === 0) {
          // Swal.fire({
          //   title: "success",
          //   html: "Compliance status changed successfully",
          //   icon: "success",
          // });
          // setStats(false);
        } else {
        }
      });
  };

  return (
    <div>
      <Modal isOpen={modal} fade={false} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}>
          <span className="ComplianceHeader">
            Client List Edits
            {/* <span className="ComplianceHeaderSpan">
              <span> Complinace : </span>
              <label
                className="switch primeCrifSwitch"
                onClick={(e) => crifperm(e, custId)}
              >
                <input type="checkbox" name="hide" checked={stats} id="hide" />
                <span className="slider round"></span>
              </label>
            </span> */}
          </span>
        </ModalHeader>
        <ModalBody>
          <div className="compPrime row justify-content-start">
            <div className="compPrimeDet col-md-5">
              Client Userid-{" "}
              <span className="detailSpan">{ hist?.name}</span>
            </div>
            <div className="compPrimeDet col-md-5">
              Client Email-
              <span className="detailSpan">{ hist?.email}</span>
            </div>
          </div>

          { hist != undefined ? (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="row" style={{ width: "30px" }}>
                      S.No
                    </th>
                    <th scope="row" style={{ width: "300px" }}>
                      Sub-Client Name
                    </th>
                    <th scope="row" style={{ width: "560px" }}>
                      E-mail
                    </th>
                    <th scope="row" style={{ width: "150px" }}>
                      Phone-number
                    </th>
                    <th scope="row" style={{ width: "98px" }}>
                    Permissions
                    </th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>

              {secCrif?.map((item, index) => (
                <>
                  <table className="table">
                    <thead></thead>
                    <tbody>
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td style={{ width: "52px" }}>{index + 1}</td>
                          <td style={{ width: "300px" }}>{item.name}</td>
                          <td style={{ width: "560px" }}>
                            {item.client_email}
                          </td>
                          <td style={{ width: "150px" }}>{item.phone}</td>
                          <td style={{ width: "98px" }}>
                            <div className="subcatCom">
                              {item.status == true ? (
                                <button
                                  className="compbtn"
                                  onClick={() => hidesub(item.id)}
                                  key={item.id}
                                >
                                  <i
                                    class="fa fa-caret-up"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              ) : (
                                <button
                                  className="compbtn"
                                  onClick={() => showsub(item.id)}
                                  key={item.id}
                                >
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </tbody>
                  </table>

                  {(item.status === true) ? (
                    <fieldset className="my-fieldset subClientdiv">
                      <legend className="login-legend" style={{"fontSize":"1.3rem"}}>Compliance Permissions</legend>
                      <div className="container">
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          autocomplete="off"
                        >
                          {(item?.module_id?.length > 0)  ? (
                                <input type="hidden" ref={register} value={item.module_id} name="id" />
                              ) : (
                                <input type="hidden" ref={register} value={``} name="id" />
                              )}
                          <input type="hidden" ref={register} value={item.id} name="subclient_id" />
                          <input type="hidden" ref={register} value={ hist.id} name="client_id" />
                          <div className="basicFeild row justify-content-around">
                            <div className="sub col-md-10 mb-3">
                              {allSub?.map((i) => (
                                <span>
                                  <input
                                    type="checkbox"
                                    name={i.name}
                                    checked={i.status}
                                    // value={i.name}
                                    ref={register}
                                    id="hide"
                                    disabled={disable}
                                    onClick={(e) =>
                                      handleSubCategory(e)
                                    }
                                  ></input>
                                  <label
                                    htmlFor="hide"
                                    style={{ margin: "1px" }}
                                  >
                                    {i.value}
                                  </label>
                                </span>
                              ))}
                            </div>
                            <button
                              type="submit"
                              className="customBtn mx-sm-1 mb-2"
                              style={{height: "50%",width: "5rem"}}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </fieldset>
                  ) : ("")} 
                </>
              ))}
            </>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <button className="searchBtn mx-sm-1 mb-2">Cancel</button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Tlcustedit;
