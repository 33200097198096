
export const purpose = [
    { value: "Assessment", label: "Assessment" },
    { value: "Appeal", label: "Appeal" },
    { value: "Filing before any Court", label: "Filing before any Court" },
    {
        value: "Filing before any Authority",
        label: "Filing before any Authority",
    },
    {  value : "For internal use", 
label : "For compliance"},
    {  value : "For compliance", 
label : "For internal use"},

    { value: "Others", label: "Others" },
];


