import React, { useState, useEffect, useRef } from "react";
// import Layout from "../../../components/Layout/Layout";
import Layout from "../Layout/Layout";
import axios from "axios";
import { baseUrl } from "../../config/config";
import RightClickComponent from "../RightClick/RightClickOne";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
// import newFolder from "../../../assets/images/newfolder.svg";
import { useForm, useFieldArray } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";
import { Dropdown, Menu } from "antd";
import "antd/dist/antd.css";
import { Select } from "antd";
// import CreateFolder from "../Compliace/CreateFolder";
import PublishIcon from "@material-ui/icons/Publish";
// import Rename from "../Compliace/RenameFolder";
// import MessageIcon, {
//   EyeIcon,
//   ViewDiscussionIcon,
//   EditQuery,
//   ActionIcon,
// } from "../../../components/Common/MessageIcon";
import { makeStyles } from "@material-ui/core/styles";
// import { FileIcon } from "../../../components/Common/MessageIcon";
import FolderIcon from "@mui/icons-material/Folder";
import { Box } from "@mui/material";
import styled from "styled-components";
// import FolderBredcrumb from "../../../components/FolderWrapper/FolderBredcrumb";
import FolderBredcrumb from "../FolderWrapper/FolderBredcrumb";
// import FolderWrappers from "./FolderWrapper";
import FolderWrappers from "./FolderWrappers";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
// import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const FolderWrapper = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  flexWrap: "wrap",
  margin: "0px 20px 0px 0px",
});

const FolderDetails = styled(Box)({
  display: "flex",
  width: "100%",
  alignItems: "flex-start",
  flexWrap: "wrap",
});

const useStyles = makeStyles((theme) => ({
  isActive: {
    backgroundColor: "green",
    color: "#fff",
    margin: "0px 10px",
  },
}));
function CompCard(props) {
  const compPosition = props.compPosition;
  const compPer = JSON.parse(window.localStorage.getItem("compliance_module"));
  const classes = useStyles();
  const { Option } = Select;
  const history = useHistory;
  const uid = localStorage.getItem("tlkey");
  const userid = JSON.parse(window.localStorage.getItem("userid"));
  const [clientList, setClientList] = useState([]);
  const [ViewDiscussion, setViewDiscussion] = useState(false);
  const [viewProposalModal, setViewProposalModal] = useState(false);
  const [scrolledTo, setScrolledTo] = useState("");
  const [lastDown, setLastDown] = useState("");
  const myRefs = useRef([]);
  const myRef = useRef([]);
  // const qid = useParams();
  const qid = window.localStorage.getItem("TlCompClientId");
  const accessCheck = JSON.parse(window.localStorage.getItem("TlClientAccess"));

  const { handleSubmit, getValue, register, errors, control } = useForm();

  const { append, remove, fields } = useFieldArray({
    control,
    name: "users",
  });

  // const { cust_accept_date } = diaplayProposal;
  const [createFoldernew, setCreateFolder] = useState(false);
  const [folder, setFolder] = useState([]);
  const [color, setColor] = useState(0);
  const [innerFiles, setInnerFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [movedFolder, setMovedFolder] = useState([
    {
      label: "...(root)",
      value: "0",
    },
  ]);

  const [sub_folder, set_sub_folder] = useState([]);
  const [subFile, setSubFile] = useState([]);
  const [showSubfolderData, setShowSubFolderData] = useState(false);
  const [renameValue, setRenameValue] = useState("");
  const [rename, setRename] = useState(false);
  const [renameFile, setRenameFile] = useState(false);
  const [mainFolderId, setMainFolderId] = useState("");
  const [currentFold, setCurrentFolder] = useState();
  const [curFoldDetail, setCurFoldDetail] = useState();
  const [saveChildren, setSaveChildren] = useState([]);
  const [currentChild, setCurrentChild] = useState([]);
  const [currentTab, setCurrentTab] = useState();
  const [onLevel, setOnlevel] = useState(1);
  const [crumb, setCrumb] = useState([]);
  const [gotoCrumb, setGotoCrumb] = useState([]);
  const [newCreated, setNewCreated] = useState(false);
  const [createdAt, setCreatedAt] = useState([]);
  const [torename, setTorename] = useState();
  const [torenameFile, setTorenameFile] = useState();
  const [newName, setNewName] = useState();
  const [newFileName, setNewFileName] = useState();
  const [renameTrigger, setRenameTrigger] = useState(false);
  const [uploadTrigger, setUploadTrigger] = useState(false);
  const [needtoJump, setNeedtoJump] = useState(false);
  const [crumbStart, setCrumbStart] = useState();
  const [tomove, setToMove] = useState();
  const [todo, setTodo] = useState(1);
  const [moveFol, setMoveFol] = useState();
  const [folderFrom, setfolderFrom] = useState();
  const [nameError, setNameError] = useState(false);
  const [stats, setStats] = useState(false);

  const tlperm = JSON?.parse(
    window.localStorage.getItem("cust_rootfolderlist")
  );
  const token = window.localStorage.getItem("clientToken");
  const custRoots = JSON?.parse(
    window.localStorage.getItem("cust_rootfolderlist")
  );

  const ViewType = JSON?.parse(window.localStorage.getItem("ClientViewType"));

  const myConfig = {
    headers: {
      uit: token,
    },
  };

  useEffect(() => {
    // console.log("compPosition==>", compPosition);
    if (custRoots) {
      // console.log("custRoots==>>", custRoots);
      const foundItem = custRoots.find((item) => item.folder === compPosition);
      // console.log("foundItem==>>", foundItem);
      const present = foundItem?.id;
      setCurrentTab(present);
      showFolder(present);
      showfile(present);
    }
  }, [compPosition]);

  useEffect(() => {
    // if(compPer == 0){
    //   history.push('/customer/masterdashboard')
    // }
    if (ViewType) {
      setStats(true);
    }
  }, []);

  useEffect(() => {
    if (stats === true) {
      localStorage.setItem("ClientViewType", JSON.stringify(stats));
    } else {
      localStorage.removeItem("ClientViewType");
    }
  }, [stats]);

  useEffect(() => {
    console.log("crumbzz==>", crumb);
  }, [crumb]);

  useEffect(() => {
    console.log("CURRENTAT==>", curFoldDetail);
  }, [curFoldDetail]);

  useEffect(() => {
    console.log("folder==>", folder);
  }, [folder]);

  useEffect(() => {
    // console.log("GotoCrumb=>", gotoCrumb.length);
    if (!(gotoCrumb.length === 0) && newCreated === false) {
      gotoFolder(gotoCrumb);
    }
  }, [gotoCrumb]);

  useEffect(() => {
    console.log("currentChild==>", currentChild);
  }, [currentChild]);

  useEffect(() => {
    if (createdAt?.id && newCreated === true) {
      console.log("currentTab", currentTab);
      showFolder(currentTab);
      showfile(currentTab);
    }
  }, [newCreated]);

  useEffect(() => {
    console.log("saveChildren==>>", saveChildren);
    if (needtoJump === true && newCreated === false) {
      console.log("createdAT==>", createdAt);
      gotoFolder(createdAt);
    }

    if (createdAt?.id && newCreated === true) {
      console.log("currentTab", currentTab);
      gotoFolder(createdAt);
      showFolder(currentTab);
      showfile(currentTab);
    }
  }, [saveChildren]);

  useEffect(() => {
    console.log("createdAt==>", createdAt);
  }, [createdAt]);

  // useEffect(() => {
  //   var element = document.getElementById(scrolledTo);
  //   if (element) {
  //     let runTo = myRef.current[scrolledTo];
  //     runTo?.scrollIntoView(false);
  //     runTo?.scrollIntoView({ block: "center" });
  //   }
  // }, [ViewDiscussion]);

  useEffect(() => {
    let runTo = myRefs.current[lastDown];
    runTo?.scrollIntoView(false);
    runTo?.scrollIntoView({ block: "center" });
  }, [viewProposalModal]);

  useEffect(() => {
    console.log("torename=>", torename);
  }, [torename]);

  useEffect(() => {
    if (torename) {
      renameModal();
    }
  }, [renameTrigger]);

  useEffect(() => {
    if (newName?.length > 64) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  }, [newName]);

  useEffect(() => {
    if (newFileName?.length > 64) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  }, [newFileName]);

  // useEffect(() => {
  //   if (createdAt && newCreated === true && saveChildren) {
  //     gotoFolder(createdAt);
  //   }
  // }, [saveChildren]);

  // const clicking = () => {
  //   console.log("clicked");
  // };

  // const getTlinfo = () => {
  //   const a = localStorage.getItem("tlkey");
  //   const par = parseInt(a);
  //   const tlid = Number.par;
  //   // let tlid = parseInt(userid)
  //   console.log("tlid", tlid);
  //   axios
  //     .get(
  //       `${baseUrl}/tl/tlcompliancelist?tl_id=${JSON.parse(userid)}`,
  //       myConfig
  //     )
  //     .then((res) => {
  //       console.log("gettingTLaccess=>", res.data.result);
  //       let allac = res.data.result;
  //       setClientList(allac);
  //     });
  // };

  const showFolder = (id) => {
    console.log("CALLLLLLING");
    axios
      .get(
        `${baseUrl}/compliance/getfolderstructure?rootfolder_id=${id}`,
        myConfig
      )
      .then((res) => {
        if (res.data.code === 1) {
          const currentId = res.data.result.structure.parent;
          // console.log("curr==>", currentId);

          const parentId = Number(res.data.result.parent_id);
          setCurrentFolder(parentId);
          setCurFoldDetail(currentId);
          console.log("CurrentId");
          setCrumbStart(currentId);

          // console.log("parentId", parentId);
          const desk = res.data.result.structure.child;
          // console.log("desk==/>", desk);
          const dataArray = Object.values(desk).map((item) => item.info);
          const filteredArray = dataArray.filter((item) => item !== undefined);
          setSaveChildren(desk);
          setCurrentChild(desk);
          console.log("desk=>>", desk);
          // console.log("dataArray=>", filteredArray);

          const actual = [res.data.result.structure.parent];
          // setFolder(filteredArray);
          if (newCreated === true) {
            gotoFolder(createdAt);
          } else {
            setFolder(filteredArray);
            setCrumb([currentId]);
          }
        }
      });
  };

  const showfile = (id) => {
    axios
      .get(
        `${baseUrl}/compliance/getcompliancefile?rootfolder_id=${id}`,
        myConfig
      )
      .then((res) => {
        const temp = [
          { id: "1", filename: "abc.pdf" },
          { id: "2", filename: "xyz.docx" },
        ];
        // setFiles(temp);
        if (res.data.code === 1) {
          console.log("resFile==>", res.data);
          const data = res.data.result;
          if (data.length > 0) {
            // alert("getting");
          }
          setFiles(data);
        }
      });
  };

  const handleCrumb = () => {};

  const renameFold = () => {
    var confToken = "";
    setTorename();
    confToken = window.localStorage.getItem("tlToken");
    const myConfig = {
      headers: {
        uit: confToken,
      },
    };
    let formData = new FormData();
    if (newName?.length > 64) {
      console.log("TOOOOLONG.....");
    } else {
      formData.append("folder", newName);
      formData.append("id", torename?.id);
      formData.append("parent_folder", curFoldDetail?.id);
      //   axios({
      //     method: "POST",
      //     url: `${baseUrl}/tl/createqompliancefolder`,
      //     headers: {
      //       uit: confToken,
      //     },
      //     data: formData,
      //   })
      //     .then((res) => {
      //       if (res.data.code === 1) {
      //         Swal.fire({
      //           title: "success",
      //           html: `Renamed to ${newName} successfully`,
      //           icons: "success",
      //         });
      //         setCreatedAt(curFoldDetail);
      //         // setNeedtoJump(true);
      //         setNewCreated(true);
      //         // showFolder(currentTab);
      //         setRename(!rename);
      //         setNewName();
      //       } else if (res.data.code === 0) {
      //         Swal.fire({
      //           title: "error",
      //           html: "Something went wrong, please try again",
      //           icons: "error",
      //         });
      //       }
      //     })
      //     .catch((error) => {
      //       Swal.fire({
      //         title: "error",
      //         html: error,
      //         icons: "error",
      //       });
      //     });
    }
  };

  const changeFileName = () => {
    var confToken = "";
    setTorename();
    confToken = window.localStorage.getItem("tlToken");
    const myConfig = {
      headers: {
        uit: confToken,
      },
    };
    let formData = new FormData();

    formData.append("file_id", torenameFile?.id);
    formData.append("file_name", newFileName);
    formData.append("client_id", tlperm?.client_id);
    // axios({
    //   method: "POST",
    //   url: `${baseUrl}/tl/editcompliancefile`,
    //   headers: {
    //     uit: confToken,
    //   },
    //   data: formData,
    // })
    //   .then((res) => {
    //     if (res.data.code === 1) {
    //       Swal.fire({
    //         title: "success",
    //         html: "Renamed successfully",
    //         icons: "success",
    //       });
    //       setCreatedAt(curFoldDetail);
    //       // setNeedtoJump(true);
    //       setNewCreated(true);
    //       // showFolder(currentTab);
    //       // getFile();
    //       showfile();
    //       setRenameFile(!renameFile);
    //     } else if (res.data.code === 0) {
    //       Swal.fire({
    //         title: "error",
    //         html: "Something went wrong, please try again",
    //         icons: "error",
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     Swal.fire({
    //       title: "error",
    //       html: error,
    //       icons: "error",
    //     });
    //   });
  };

  const onSubmit = (value) => {
    // console.log("submmmmiting", value);
    var uploadImg = value.uploadImg;
    setTorename();

    let formData = new FormData();
    formData.append("rootfolder_id", currentTab);
    formData.append("folder_id", curFoldDetail?.id);
    if (uploadImg) {
      for (var i = 0; i < uploadImg.length; i++) {
        let file = uploadImg[i];
        formData.append("report[]", file);
      }
    }

    axios({
      method: "POST",
      url: `${baseUrl}/compliance/uploadfile4compliance`,
      headers: {
        "content-type": "multipart/form-data",
        uit: token,
      },
      data: formData,
    }).then((res) => {
      if (res.data.code === 1) {
        const faillMessage = res.data.message.faill;
        const sucMessage = res.data.message.success;
        if (faillMessage && sucMessage){
          Swal.fire({
            title: "success",
            html: `${faillMessage}`,
            icon: "error",
          });
        }
        else if (faillMessage && !sucMessage) {
          Swal.fire({
            title: "error",
            html: `${faillMessage}`,
            icon: "error",
          });
        }else if (!faillMessage && sucMessage){
          Swal.fire({
            title: "success",
            html: `${sucMessage}`,
            icon: "success",
          });
        }
         else {
          Swal.fire({
            title: "success",
            html: "Files successfullly uploaded",
            icon: "success",
          });
        }
        setCreatedAt(curFoldDetail);
        setUploadTrigger(false);
        setNewCreated(true);
      } else if (res.data.code === 0) {
        // setFolderId("");
        Swal.fire({
          title: "error",
          html: "Folder name already exits",
          icon: "error",
        });
      }
    });
  };

  const rightClick = (e, a, b, c) => {
    e.preventDefault();
    const clinetId = tlperm?.client_id;
    console.log("ClientNo", clinetId, "Fileno", b, "ClientAccess", c);
    downloadpdf(clinetId, b, c);
  };

  const downloadpdf = (id, fileno, name) => {
    console.log("id==>", id, "fileno==>", fileno, "name==>", name);
    let userId;

    userId = window.localStorage.getItem("tlkey");
    const token = window.localStorage.getItem("clientToken");
    const myConfig2 = {
      headers: {
        uit: token,
      },
      responseType: "blob",
    };
    axios
      .get(
        `${baseUrl}/compliance/downloadcompliancefile?file_id=${fileno}`,
        myConfig2
      )
      .then((res) => {
        if (res.status === 200) {
          window.URL = window.URL || window.webkitURL;
          var url = window.URL.createObjectURL(res.data);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = name;
          a.target = "_blank";
          a.click();
        }
      });
  };

  const getFolderOther = (e) => {
    setCreateFolder(!createFoldernew);
  };
  const upload = () => {
    setUploadTrigger(!uploadTrigger);
  };

  // const get_sub_innerFile = (e) => {
  //   var confToken = "";
  //   setSubFolderId(e);
  //   if (window.location.pathname.split("/")[1] === "teamleader_queries") {
  //     confToken = window.localStorage.getItem("tlToken");
  //   } else if (
  //     window.location.pathname.split("/")[1] === "taxprofessional_queries"
  //   ) {
  //     confToken = window.localStorage.getItem("tptoken");
  //   }
  //   const myConfig = {
  //     headers: {
  //       uit: confToken,
  //     },
  //   };
  //   if (
  //     window.location.pathname.split("/")[1] === "teamleader_queries" ||
  //     window.location.pathname.split("/")[1] === "taxprofessional_queries"
  //   ) {
  //     axios
  //       .get(
  //         `${baseUrl}/tl/documentlistbyfolderreport?q_id=${qid.id}&folder_id=${
  //           e.id
  //         }&uid=${JSON.parse(uid)}`,
  //         myConfig
  //       )
  //       .then((res) => {
  //         if (res.data.code === 1) {
  //           setMainFolderId(Number(e.id));
  //           setFolderName(e.folder);
  //           setSubFile(res.data.result);
  //           setShowSubFolderData(true);
  //         } else {
  //           setShowSubFolderData(false);
  //         }
  //       });
  //   }
  // };

  const closeModal = () => {
    setRename("");
  };

  function findParentStructure(childId, parent) {
    // console.log("PPP", childId, parent);
    // console.log("currentTab==>", currentTab);
    for (const key in parent) {
      if (currentTab === childId) {
        return { data: parent, main: true };
      } else if ([key] == childId) {
        return { data: parent, main: true };
      } else if (parent[key].child && parent[key].child[childId]) {
        return parent[key];
      } else if (parent[key].child && !parent[key].child[childId]) {
        const result = findParentStructure(childId, parent[key].child);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  function findChild(data, dest) {
    for (const obj of data) {
      for (const key in obj) {
        if (key === dest.id && obj[key].info.name === dest.name) {
          // console.log('1if...',obj[key]);
          return obj[key];
        } else if (obj[key].child) {
          const result = findChild([obj[key].child], dest);
          // console.log('2if...',obj[key],result);
          if (result) return result;
        }
      }
    }
    return null;
  }

  const removecrumb = (i) => {
    const indexToRemove = crumb.findIndex((item) => item.id === i.id);
    const newCrumb =
      indexToRemove !== -1 ? crumb.slice(0, indexToRemove + 1) : crumb;

    // console.log('newCrumb==>',newCrumb);
    setCrumb(newCrumb);
  };

  function findPathToJumpTo(obj, target, path = [crumbStart]) {
    // console.log("target=>", target, "obj=>", obj);
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const current = obj[key];
        if (
          current.info &&
          current.info.id === target.id &&
          current.info.name === target.name &&
          current.info.upload_folder === target.upload_folder
        ) {
          console.log("path1", path);
          return [...path, current.info];
        }
        if (current.child) {
          const result = findPathToJumpTo(current.child, target, [
            ...path,
            current.info,
          ]);
          if (result) {
            console.log("result2", result);
            return result;
          }
        }
      }
    }
    return null;
  }

  const addCrumb = (i) => {
    let allData = saveChildren;
    if (newCreated === true) {
      const allCrumb = findPathToJumpTo(allData, i);
      setCrumb(allCrumb);
      console.log("allCrumb", allCrumb);
    }
  };

  const gotoFolder = (dest) => {
    console.log("dest==>", dest);
    let allD = saveChildren;
    let allData = [saveChildren];
    console.log("allDataOfGOtotFolder=>", allData);
    console.log("allDOfGOtotFolder=>", allD);
    if (newCreated === true && dest?.id) {
      addCrumb(dest);
    }
    if (dest.id == currentTab) {
      const dataArray = Object.values(allD).map((item) => item.info);
      const filteredArray = dataArray.filter((item) => item !== undefined);
      setFolder(filteredArray);
      setCurFoldDetail(dest);
      removecrumb(dest);
      setCurrentChild(allD);
    } else {
      const gettingFoldermain = findChild(allData, dest);
      if (gettingFoldermain?.info) {
        console.log("gettingFoldermain==>", gettingFoldermain);
        const gettingFolder = gettingFoldermain[`child`];
        console.log("gettingFolder==>", gettingFolder);
        const foundItem = gettingFoldermain[`info`];
        // console.log("foundItem==>", foundItem);
        if (gettingFolder) {
          const dataArray = Object.values(gettingFolder).map(
            (item) => item.info
          );
          const filteredArray = dataArray.filter((item) => item !== undefined);
          // console.log("foundItemAfterFilter==>", filteredArray);
          setFolder(filteredArray);
          setCurrentChild(gettingFolder);
          setCurFoldDetail(foundItem);
          if (needtoJump == false) {
            removecrumb(foundItem);
          }
        } else {
          // const gettingFolder =
          setFolder([]);
          setCurFoldDetail(dest);
          setCurrentChild(gettingFolder);
          // setCurFoldDetail(foundItem);
        }
      }
    }
    setNewCreated(false);
    setNeedtoJump(false);
  };

  const renameModal = () => {
    setRename(!rename);

    // if(rename  === false){
    //   setTorename();
    // }
  };

  const renameFileModal = (props) => {
    // console.log(renameFile, props);
    if (renameFile == true) {
      // console.log("Rename This", props);
      setTorenameFile("");
    } else {
      // console.log("not to rename");
      setTorenameFile(props);
    }
    setRenameFile(!renameFile);
  };

  const deleteFile = (value, curr) => {
    const clinetId = tlperm?.client_id;
    console.log("VValue", clinetId, value);
    Swal.fire({
      title: "Are you sure?",
      text: "Want to delete file? Yes, delete it!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        // axios
        //   .get(
        //     `${baseUrl}/tl/deletecompliancefile?file_id=${value.id}&client_id=${clinetId}`,
        //     myConfig
        //   )
        //   .then((res) => {
        //     if (res.data.code === 1) {
        //       Swal.fire({
        //         title: "success",
        //         html: "File deleted successfully",
        //         icon: "success",
        //       });
        //       setCreatedAt(curFoldDetail);
        //       // setNeedtoJump(true);
        //       setNewCreated(true);
        //     } else {
        //       Swal.fire({
        //         title: "error",
        //         html: "Something went wrong , please try again",
        //         icon: "error",
        //       });
        //     }
        //   });
      }
    });
  };

  const moveFile = (value, to) => {
    setToMove(value);
    setTodo(to);
  };

  const pasteFile = (value) => {
    console.log("pastehere", curFoldDetail);
    console.log("workwith==>", tomove);

    var confToken = "";
    confToken = window.localStorage.getItem("tlToken");
    let formData = new FormData();
    formData.append("file_id", tomove?.id);
    formData.append("client_id", tlperm?.client_id);
    formData.append("folder_id", tomove?.folder_id);
    formData.append("move_type", todo);
    formData.append("newfolder_id", curFoldDetail?.id);
    if (tomove?.folder_id === curFoldDetail?.id) {
      Swal.fire({
        title: "error",
        html: "Can't Paste on same location",
        icon: "error",
      });
    } else {
      //   axios({
      //     method: "POST",
      //     url: `${baseUrl}/tl/movecompliancefile`,
      //     headers: {
      //       uit: confToken,
      //     },
      //     data: formData,
      //   }).then((res) => {
      //     if (res.data.code === 1) {
      //       Swal.fire({
      //         title: "success",
      //         html: "File moved successfullly",
      //         icon: "success",
      //       });
      //       setCreatedAt(curFoldDetail);
      //       // setNeedtoJump(true);
      //       setNewCreated(true);
      //       setToMove();
      //     } else if (res.data.code === 0) {
      //       Swal.fire({
      //         title: "error",
      //         html: "Please Try Again",
      //         icon: "error",
      //       });
      //     }
      //   });
    }
  };

  const moveFolder = (value, from) => {
    console.log("move this ==>", value);
    setMoveFol(value);
    setfolderFrom(from);
  };

  const pasteFolder = (value) => {
    console.log('from',folderFrom,'in',curFoldDetail?.id);
    if (folderFrom === curFoldDetail?.id) {
      Swal.fire({
        title: "error",
        html: "Can't Paste on same location",
        icon: "error",
      });
    } else {
    }
  };

  const getChildren = (id) => {
    // console.log("currentShowing=>", props.curFoldDetail);
    console.log("getChildren=>", id);
    // console.log("AllTheDataInFunction=>", allData);
    // if (onLevel > 0) {
    //   setOnlevel(onLevel + 1);
    // }
    // console.log('id=>',id);
    // console.log("saveAllChild==>", saveAllChild);
    const only = currentChild[id];
    // console.log("only==>", only);
    const onlyInfo = only[`info`];
    // console.log("onlyInfo==>", onlyInfo);
    // props.setCurFoldDetail(onlyInfo);
    // props.setCrumb((prevCrumb) => [...prevCrumb, onlyInfo]);
    // props.setGotoCrumb([])
    const onlygrandchild = only[`child`];

    if (onlygrandchild) {
      // props.setCurrentChild(onlygrandchild);
      // console.log("onlygrandchild", onlygrandchild);
      const dataArray = Object.values(onlygrandchild).map((item) => item.info);
      const filteredArray = dataArray.filter((item) => item !== undefined);

      return filteredArray;
      //   console.log("dataArray", filteredArray);
      // props.setFolder(filteredArray);
    } else {
      return [];
    }
  };

  const deleteFolder = (value) => {
    console.log("deleteFolder==>", value);
    const id = value.id;
    const clinetId = tlperm?.client_id;
    const getchild = getChildren(id);
    function searchForFolderId(array, id) {
      for (let obj of array) {
        if (obj.folder_id === id) {
          return obj;
        }
      }
      return null; // Return null if no match is found
    }
    const find = searchForFolderId(files, id);
    console.log("finding..", find);
    console.log("files", files);
    console.log("getttt===>>", getchild);
    if (getchild.length !== 0 || find) {
      Swal.fire({
        title: "error",
        html: "The folder is not empty. Please remove all content before deleting the folder.",
        icon: "error",
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "Want to delete file? Yes, delete it!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        // console.log('deleteing',value);
        if (result.value) {
          console.log("Deleting......");
          //   axios
          //     .get(
          //       `${baseUrl}/tl/deletecompliancefolder?folder_id=${id}&client_id=${clinetId}`,
          //       myConfig
          //     )
          //     .then((res) => {
          //       if (res.data.code === 1) {
          //         Swal.fire({
          //           title: "success",
          //           html: "File deleted successfully",
          //           icon: "success",
          //         });
          //         setCreatedAt(curFoldDetail);
          //         // setNeedtoJump(true);
          //         setNewCreated(true);
          //       } else {
          //         Swal.fire({
          //           title: "error",
          //           html: "Something went wrong , please try again",
          //           icon: "error",
          //         });
          //       }
          //     });
        }
      });
    }
  };

  const ChangeView = () => {
    setStats(!stats);
  };

  return (
    <>
      <Card>
        <CardHeader style={{ backgroundColor: "#fff" }}>
          <Breadcrumb
            crumb={crumb}
            curFoldDetail={curFoldDetail}
            gotoCrumb={gotoCrumb}
            handleCrumb={handleCrumb}
            setCrumb={setCrumb}
            setGotoCrumb={setGotoCrumb}
          />
          <span className="ComplianceHeaderSpan complianceView">
            <span className="viewIcon viewLarge">
              <i
                class="fa fa-th-large"
                title="for file view,toggle here"
                aria-hidden="true"
              ></i>
            </span>
            <label
              className="switch primeCrifSwitch"
              id="primeCrifSwitch"
              onChange={(e) => ChangeView(e)}
            >
              <input type="checkbox" name="hide" checked={stats} id="hide" />
              <span className="slider round viewSlider"></span>
            </label>
            <span className="viewIcon viewList">
              <i
                class="fa fa-th-list"
                title="for list view,toggle here"
                aria-hidden="true"
              ></i>
            </span>
          </span>
        </CardHeader>
        <CardBody style={{ minHeight: "250px" }}>
          {/* <Dropdown overlay={menu} trigger={["contextMenu"]}> */}
          <span>
            <div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                {/* <button
                    className="autoWidthBtn createFolder"
                    onClick={(e) => getParentFolder()}
                  >
                    Back
                  </button> */}
                {/* <span style={{ fontSize: "16px", fontWeight: "300" }}></span> */}
                {moveFol ? (
                  <button
                    className="autoWidthBtn mr-3 createFolder"
                    onClick={(e) => pasteFolder(e)}
                  >
                    Paste Folder
                  </button>
                ) : (
                  ""
                )}
                {tomove ? (
                  <button
                    className="autoWidthBtn mr-3 createFolder"
                    onClick={(e) => pasteFile()}
                  >
                    Paste
                  </button>
                ) : (
                  ""
                )}
                {curFoldDetail?.name === "upload" ||
                curFoldDetail?.name === "clientupload" ? (
                  <button
                    className="autoWidthBtn createFolder"
                    title="Upload Files"
                    onClick={(e) => upload()}
                  >
                    <i class="fa fa-upload" aria-hidden="true"></i>
                  </button>
                ) : (
                  ""
                )}
              </div>
              <FolderWrappers
                handleCrumb={handleCrumb}
                folder={folder}
                setFolder={setFolder}
                color={color}
                // getFolerSubFile={getInnerFileFile}
                setCurFoldDetail={setCurFoldDetail}
                curFoldDetail={curFoldDetail}
                currentChild={currentChild}
                setCurrentChild={setCurrentChild}
                onLevel={onLevel}
                setOnlevel={setOnlevel}
                file={files}
                downloadFile={rightClick}
                // moveTo={handleFile}
                basePath={true}
                isSubFolder={false}
                panel="tl"
                here="1"
                rename={rename}
                setRename={setRename}
                setRenameValue={setRenameValue}
                renameValue={renameValue}
                closeModal={closeModal}
                // renameFolder={renameFolder}
                // getFile={getFile}
                saveChildren={saveChildren}
                crumb={crumb}
                gotoCrumb={gotoCrumb}
                setCrumb={setCrumb}
                setGotoCrumb={setGotoCrumb}
                torename={torename}
                setTorename={setTorename}
                renameTrigger={renameTrigger}
                setRenameTrigger={setRenameTrigger}
                deleteFile={deleteFile}
                renameFileModal={renameFileModal}
                moveFile={moveFile}
                deleteFolder={deleteFolder}
                moveFolder={moveFolder}
                stats={stats}
              />
            </div>

            <div className="d-flex flex-column">
              {showSubfolderData === true ? (
                <FolderWrapper
                  handleCrumb={handleCrumb}
                  //   getFolerSubFile={goBackFun}
                  currentFold={currentFold}
                  setCurrentFolder={setCurrentFolder}
                  setCurFoldDetail={setCurFoldDetail}
                  curFoldDetail={curFoldDetail}
                  currentChild={currentChild}
                  setCurrentChild={setCurrentChild}
                  onLevel={onLevel}
                  setOnlevel={setOnlevel}
                  file={subFile}
                  downloadFile={rightClick}
                  //   moveTo={handleFile}
                  basePath={false}
                  isSubFolder={true}
                  rename={rename}
                  here="2"
                  setRename={setRename}
                  setRenameValue={setRenameValue}
                  renameValue={renameValue}
                  closeModal={closeModal}
                  mainFolderId={mainFolderId}
                  // renameFolder={renameFolder}
                  // getFile={getFile}
                  crumb={crumb}
                  gotoCrumb={gotoCrumb}
                  setCrumb={setCrumb}
                  setGotoCrumb={setGotoCrumb}
                  panel="tl"
                  torename={torename}
                  setTorename={setTorename}
                  renameTrigger={renameTrigger}
                  setRenameTrigger={setRenameTrigger}
                  deleteFile={deleteFile}
                  renameFileModal={renameFileModal}
                  moveFile={moveFile}
                  deleteFolder={deleteFolder}
                  moveFolder={moveFolder}
                  stats={stats}
                />
              ) : (
                <FolderWrapper
                  handleCrumb={handleCrumb}
                  crumb={crumb}
                  curFoldDetail={curFoldDetail}
                  gotoCrumb={gotoCrumb}
                  setCrumb={setCrumb}
                  setGotoCrumb={setGotoCrumb}
                  mainFolderId={mainFolderId}
                  folder={sub_folder}
                  here="3"
                  currentFold={currentFold}
                  setCurrentFolder={setCurrentFolder}
                  setCurFoldDetail={setCurFoldDetail}
                  currentChild={currentChild}
                  setCurrentChild={setCurrentChild}
                  onLevel={onLevel}
                  setOnlevel={setOnlevel}
                  // getFolerSubFile={get_sub_innerFile}
                  file={innerFiles}
                  downloadFile={rightClick}
                  //   moveTo={handleFile}
                  basePath={false}
                  isSubFolder={false}
                  rename={rename}
                  setRename={setRename}
                  setRenameValue={setRenameValue}
                  renameValue={renameValue}
                  closeModal={closeModal}
                  // renameFolder={renameFolder}
                  // getFile={getFile}
                  panel="tl"
                  torename={torename}
                  setTorename={setTorename}
                  renameTrigger={renameTrigger}
                  setRenameTrigger={setRenameTrigger}
                  deleteFile={deleteFile}
                  renameFileModal={renameFileModal}
                  moveFile={moveFile}
                  deleteFolder={deleteFolder}
                  moveFolder={moveFolder}
                  stats={stats}
                />
              )}
            </div>

            {rename === true && torename ? (
              <Modal isOpen={rename} toggle={renameModal} size="xs">
                <ModalHeader toggle={renameModal}>Rename to</ModalHeader>
                <ModalBody>
                  <>
                    <div className="row">
                      <div className="col-md-12">
                        <label>
                          <strong>Folder to rename:</strong>
                        </label>
                        <label className="ml-2">{torename?.name}</label>
                      </div>
                      <div className="col-md-12">
                        <label>New folder name</label>
                        {nameError === true ? (
                          <span className="nameError">Name is too long</span>
                        ) : (
                          ""
                        )}
                        <input
                          name="p_name"
                          className={classNames("form-control", {
                            "is-invalid": errors.p_name,
                          })}
                          ref={register({ required: true })}
                          type="text"
                          value={newName}
                          onChange={(e) => setNewName(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                  <button
                    type="button"
                    onClick={(e) => renameFold(e)}
                    className="autoWidthBtn my-2"
                  >
                    Submit
                  </button>
                </ModalBody>
              </Modal>
            ) : (
              " "
            )}
            {renameFile === true && torenameFile ? (
              <Modal isOpen={renameFile} toggle={renameFileModal} size="xs">
                <ModalHeader toggle={renameFileModal}>Rename to</ModalHeader>
                <ModalBody>
                  <>
                    <div className="row">
                      <div className="col-md-12">
                        <label>
                          <strong>File to rename:</strong>
                        </label>
                        <label className="ml-2">{torenameFile?.filename}</label>
                      </div>
                      <div className="col-md-12">
                        <label>New File name</label>
                        {nameError === true ? (
                          <span className="nameError">Name is too long</span>
                        ) : (
                          ""
                        )}
                        <input
                          name="p_name"
                          className={classNames("form-control", {
                            "is-invalid": errors.p_name,
                          })}
                          ref={register({ required: true })}
                          type="text"
                          value={newFileName}
                          onChange={(e) => setNewFileName(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                  <button
                    type="button"
                    onClick={(e) => changeFileName(e)}
                    className="autoWidthBtn my-2"
                  >
                    Submit
                  </button>
                </ModalBody>
              </Modal>
            ) : (
              " "
            )}
            {uploadTrigger === true ? (
              <Modal isOpen={uploadTrigger} toggle={upload} size="xs">
                <ModalHeader toggle={upload}>Upload File</ModalHeader>
                <ModalBody>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <>
                      <div className="row">
                        <div className="col-md-12">
                          <label>
                            <strong>Upload File in:</strong>
                          </label>
                          <label className="ml-2">{curFoldDetail?.name}</label>
                        </div>
                        <div className="col-md-12">
                          <ImageUploads register={register} control={control} />
                        </div>
                      </div>
                    </>
                    <button
                      type="submit"
                      // onClick={(e) => UploadSubmit(e)}
                      className="autoWidthBtn my-2"
                    >
                      Submit
                    </button>
                  </form>
                </ModalBody>
              </Modal>
            ) : (
              " "
            )}
            {/* <CreateFolder
              addPaymentModal={createFoldernew}
              setCreateFolder={setCreateFolder}
              id={qid}
              // getList={getList}
              currentFold={currentFold}
              setCurrentFolder={setCurrentFolder}
              setCurFoldDetail={setCurFoldDetail}
              curFoldDetail={curFoldDetail}
              rejectHandler={getFolderOther}
              onLevel={onLevel}
              setOnlevel={setOnlevel}
              tab="assignment"
              movedFolder={movedFolder}
              set_sub_folder={set_sub_folder}
              setColor={setColor}
              setInnerFiles={setInnerFiles}
              setShowSubFolderData={setShowSubFolderData}
              // getInnerFileFile={getInnerFileFile}
              // getMoveToList={getMoveToList}
              direct_tax={compPosition}
            //   allList={allList}
              showFolder={showFolder}
              setFolder={setFolder}
              newCreated={newCreated}
              setNewCreated={setNewCreated}
              createdAt={createdAt}
              setCreatedAt={setCreatedAt}
              currentTab={currentTab}
              setNeedtoJump={setNeedtoJump}
            /> */}

            {/* <div className="myFolderWrapper">
                <FileIcon
                  name="Test"
                  sx={{ fontSize: "2.5rem", pointer: "cursor" }}
                />
                <FolderIcon
                  sx={{ fontSize: "2.5rem" }}
                  // onClick={(e) => props.getFolerSubFile(i)}
                  className={
                    // props.color === Number(i.id) ?
                    "folderActive"
                    // :
                    // "folderColor"
                  }
                />
                <FolderIcon
                  sx={{ fontSize: "2.5rem" }}
                  // onClick={(e) => props.getFolerSubFile(i)}
                  className={
                    // props.color === Number(i.id) ?
                    // "folderActive"
                    // :
                    "folderColor"
                  }
                />
              </div> */}
          </span>
          {/* </Dropdown> */}
        </CardBody>
      </Card>
    </>
  );
}

export default CompCard;

const ImageUploads = ({ register, control }) => {
  // const { append, fields, remove } = useFieldArray({
  //   control,
  //   name: "upload",
  // });

  // useEffect(() => {
  //   console.log("fields", fields);
  // }, [fields]);

  return (
    <>
      <div className="question_query mb-2">
        <label className="form-label">Upload your document</label>
        <div className="btn">
          <PublishIcon color="secondary" />
        </div>
      </div>
        <>
          <div className="question_query_field mb-2">
            <input
              type="file"
              name={`uploadImg`}
              ref={register}
              className="form-control-file manage_file"
              multiple={true}
              // defaultValue={pics}
            />
          </div>
        </>
    </>
  );
};
