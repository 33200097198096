import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { baseUrl } from "../../../config/config";
import { useForm } from "react-hook-form";
import { Select } from "antd";
import "antd/dist/antd.css";
import { DatePicker } from "antd";
import moment from "moment";

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

function CrefFilter(props) {
  // const { Option } = Select;
  const { handleSubmit, register, errors, reset } = useForm();

  const {
    records,
    setRecords,
    setData,
    getData,
    allCref,
    index,
    resetPaging,
    setCountNotification,
    setDefaultPage,
    page,
    setBig,
    setEnd,
  } = props;

  const [selectedData, setSelectedData] = useState([]);
  const [tax2, setTax2] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [queryNo, setQueryNo] = useState("");
  const [entityNo, setEntityNo] = useState("");
  const [status, setStatus] = useState("");
  const [userId,setUserId]=useState("");
  const maxDate = moment(new Date().toISOString().slice(0, 10)).add(1, "days");
  const dateValue = useRef();
  var allEnd = 50;
//   var allEnd = Number(localStorage.getItem("cust_record_per_page"));
const token = localStorage.getItem("token");

  const myConfig = {
    headers: {
      uit: token,
    },
  };


  useEffect(() => {
    let date = moment().format("DD-MM-YYYY");
    let fullDate = date;
    setToDate(fullDate);
  }, []);

  useEffect(() => {
    if(toDate.length == 0){
    let date = moment().format("DD-MM-YYYY");
    let fullDate = date;
    setToDate(fullDate);
    }
  }, [toDate]);


  //reset date
  const resetData = () => {
    localStorage.removeItem(`search${index}`);
    setEnd(allEnd);
    reset();
    setSelectedData([]);
    setTax2([]);
    setStatus("");
    setUserId("");
    setFromDate("");
    setQueryNo("");
    setEntityNo("");
    resetPaging();
    //remove this after complete
    setCountNotification(100);
    setDefaultPage(["1", "2", "3", "4", "5"]);
    
    // let date = moment().format("DD-MM-YYYY");
    // let fullDate = date;
    setToDate("");
    
    getData(1);
    // dateValue.current.clearValue();
  };
  
  const updateResult = (res) => {
    let droppage = [];
    let customId = 1;
    if (res.data.code === 1) {
      let all = [];
      let data = res.data.result;
      data.map((i) => {
        let data = {
          ...i,
          cid: customId,
        };
        customId++;
        all.push(data);
      });
      let end = allEnd;

      if (allEnd > res.data.total) {
        end = res.data.total;
      }

      setEnd(end);
      let dynamicPage = Math.ceil(res.data.total / allEnd);

      let rem = (page - 1) * allEnd;

      if (page === 1) {
        setBig(rem + page);
      } else {
        setBig(rem + 1);
      }
      for (let i = 1; i <= dynamicPage; i++) {
        droppage.push(i);
      }
      setDefaultPage(droppage);
      setData(all);
      setCountNotification(res.data.total);
      setRecords(res.data.total);

      setDefaultPage(droppage);
    }
  };
  const onSubmit = (data) => {

    let obj = {};
    if (data.route) {
      obj = {
        fromDate: data.fromDate,
        toDate: data.toDate,
        query_no: data?.query_no,
        entity_no:data?.entity_no,
        p_status: data?.p_status,
        user_id:data?.user_id,
        route: window.location.pathname,
        index: index,
      };
    } else {
      obj = {
        fromDate: fromDate,
        toDate: toDate,
        query_no: data?.query_no,
        entity_no:data?.entity_no,
        user_id:data?.user_id,
        p_status: data?.p_status,
        route: window.location.pathname,
        index: index,
      };
    }
    
    localStorage.setItem(`search${index}`, JSON.stringify(obj));

    // console.log('searchCref',index,obj);

    if (allCref == "allCref") {
      if (data.route) {
        axios
          .get(
            `${baseUrl}/cms/crfquerylist?page=1&from=${data.fromDate
              ?.split("-")
              .reverse()
              .join("-")}&to=${data.toDate
              ?.split("-")
              .reverse()
              .join("-")}&status=${data?.p_status}&qno=${data?.query_no}&entity_name=${data?.entity_no}&user_id=${data?.user_id}`,
            myConfig
          )
          .then((res) => {
            if (res.data.code === 1) {
              updateResult(res);
              localStorage.removeItem("prevcmscref1");
              resetPaging();
            }
          });
      } else {
        axios
          .get(
            `${baseUrl}/cms/crfquerylist?page=1&from=${fromDate
              ?.split("-")
              .reverse()
              .join("-")}&to=${toDate?.split("-").reverse().join("-")}&status=${
              data?.p_status
            }&qno=${data?.query_no}&entity_name=${data?.entity_no}&user_id=${data?.user_id}`,
            myConfig
          )
          .then((res) => {
            if (res.data.code === 1) {
              updateResult(res);
              resetPaging();
            }
          });
      }
    }

    
  };

  const Reset = () => {
    return (
      <>
        <button
          type="submit"
          className="searchBtn mx-sm-1 mb-2"
          onClick={() => resetData()}
        >
          Reset
        </button>
      </>
    );
  };


  return (
    <>
      <div className="row mx-4 my-2">
        <div className="col-sm-12 d-flex">
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-inline">
                
              <div className="form-group mx-sm-1  mb-2">
                  <input
                    type="text"
                    name="query_no"
                    ref={register}
                    placeholder="Enter Query Number"
                    className="form-control"
                    onChange={(e) => setQueryNo(e.target.value)}
                    value={queryNo}
                  />
                </div>

                <div className="form-group mx-sm-1  mb-2">
                  <input
                    type="text"
                    name="user_id"
                    ref={register}
                    placeholder="Enter User id"
                    className="form-control"
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                </div>

                <div className="form-group mx-sm-1  mb-2">
                  <input
                    type="text"
                    name="entity_no"
                    ref={register}
                    placeholder="Enter Entity Name"
                    className="form-control"
                    onChange={(e) => setEntityNo(e.target.value)}
                    value={entityNo}
                  />
                </div>

              <div className="form-group mx-sm-1  mb-2">
                    <select
                      className="form-select form-control"
                      name="p_status"
                      ref={register}
                      onChange={(e) => setStatus(e.target.value)}
                      value={status}
                      style={{ height: "33px" }}
                    >
                      <option value="">--Status--</option>
                      <option value="0">Pending</option>
                      {/* <option value="1">Sent</option> */}
                      <option value="2">Received</option>
                      <option value="3">Declined</option>
                    </select>
                </div>
                
                <div className="form-group mx-sm-1  mb-2">
                  <label className="form-select form-control">From</label>
                </div>

                {fromDate.length > 0 ? (
                  <div className="form-group mx-sm-1  mb-2">
                    <DatePicker
                      ref={dateValue}
                      onChange={(e) =>
                        setFromDate(moment(e).format("DD-MM-YYYY"))
                      }
                      disabledDate={(d) => !d || d.isAfter(maxDate)}
                      format={dateFormatList}
                      defaultValue={moment(fromDate, dateFormatList)}
                    />
                  </div>
                ) : (
                  ""
                )}
                {fromDate.length === 0 ? (
                  <div className="form-group mx-sm-1  mb-2">
                    <DatePicker
                      ref={dateValue}
                      onChange={(e) =>
                        setFromDate(moment(e).format("DD-MM-YYYY"))
                      }
                      disabledDate={(d) => !d || d.isAfter(maxDate)}
                      format={dateFormatList}
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="form-group mx-sm-1  mb-2">
                  <label className="form-select form-control">To</label>
                </div>

                <div className="form-group mx-sm-1  mb-2">
                  {toDate.length > 0 ? (
                    <DatePicker
                      ref={dateValue}
                      onChange={(e) =>
                        setToDate(moment(e).format("DD-MM-YYYY"))
                      }
                      disabledDate={(d) => !d || d.isAfter(maxDate)}
                      format={dateFormatList}
                      defaultValue={moment(toDate, dateFormatList)}
                    />
                  ) : (
                    ""
                  )}
                  {toDate.length === 0 ? (
                    <DatePicker
                      onChange={(e) =>
                        setToDate(moment(e).format("DD-MM-YYYY"))
                      }
                      disabledDate={(d) => !d || d.isAfter(maxDate)}
                      defaultValue={moment(new Date(), "DD MM, YYYY")}
                      format={dateFormatList}
                    />
                  ) : (
                    ""
                  )}
                </div>

                
                <button type="submit" className="searchBtn mx-sm-1 mb-2">
                  Search
                </button>
                <Reset />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CrefFilter;
