import React, { useState, useEffect, useLayoutEffect } from "react";
import Layout from "../../../components/Layout/Layout";
import { Container, Grid, Paper, Box } from "@material-ui/core";
import { Tab, Tabs, TabPanel, TabList } from "react-tabs";
import { useHistory } from "react-router-dom";
import LogoutHeader from "../Masterdash/LogoutHeader";
import CustomHeading from "../../../components/Common/CustomHeading";
import CompCard from "./CompCard";



function AccountComp(props) {
  let history = useHistory();
  const userId = window.localStorage.getItem("tpkey");
  const [tabIndex, setTabIndex] = useState(0);
  const [bgColor, setbgColor] = useState("#42566a");

  const tableIndex = (index) => {
    setTabIndex(index);
    // console.log(index);
    if (index === 0) {
      setbgColor("#42566a");
    } else if (index === 1) {
      setbgColor("#5f7b97");
    } else if (index === 2) {
      setbgColor("#5f7b97");
    } else if (index === 3) {
      setbgColor("#5f7b97");
    }
  };
  const myStyle1 = {
    margin: "10px auto",
    fontSize: "14px",
  };
  const myStyle2 = {
    margin: "10px auto",

    color: "#42566a",
    fontWeight: 1000,
  };
  return (
    <>
      {/* <Grid container spacing={2}>
        <Grid item lg={12}>
          <LogoutHeader name="Compliance Dashboard" />
        </Grid>
      </Grid> */}
      <Layout TpcompDashboard="TpcompDashboard" TPuserId={userId}>
        <Container maxWidth="xl">
        <div class="row mt-3">
        <div class="col-xl-12 col-lg-12 col-md-12">
          <div class="card">
            <CompCard 
            compPosition="accounting"
            />
            </div>
        </div>
      </div>
        </Container>
        </Layout>
    </>
  );
}

export default AccountComp;
