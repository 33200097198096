import React, { useEffect } from "react";
import classNames from "classnames";
import { useHistory,} from "react-router";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl} from "../../../config/config";
import Swal from "sweetalert2";
import { Modal, ModalHeader, ModalBody} from "reactstrap";
import { Spinner } from "reactstrap";

const CrefUpload = ({
    showUploadModal,
    uploadModal,
    selectedData,
    trigger,
}
) => {
  let history = useHistory();
  const [status,setStatus]= useState([]);
  const [dateError,setDateError]= useState("");
  const [statusError,setStatusError]= useState("");
  const [fileError,setFileError]= useState("");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  // const myConfig = {
  //   headers: {
  //     uit: token,
  //   },
  // };
  const { handleSubmit, register} = useForm();
  var current_date =
    new Date().getFullYear() +
    "-" +
    ("0" + (new Date().getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + new Date().getDate()).slice(-2);
  const [item] = useState(current_date);

  useEffect(() => {
    // getData();
    setLoading(false)
  }, []);

  // useEffect(() => {
  //   console.log('selectedData',selectedData);
  // }, [selectedData]);

const validateDate = (e) => {
  if (e.length === 0) {
    setDateError("Please enter Date");
  } else {
    setDateError("");
    // setOffError("");
  }
};

const validateType = (e) => {
  if (e.length === 0) {
    setStatusError("Please enter Cref Status");
  } else {
    setStatusError("");
    // setOffError("");
  }
};

const validateFile = (e) => {
  if (e.length === 0) {
    setFileError("Please select the report");
  } else {
    setFileError("");
    // setOffError("");
  }
};

  
  const onSubmit = (value) => {
    // setLoading(true);

    validateDate(value.date_event);
    validateType(status);
    validateFile(value.uploadImg);

    let formData = new FormData();
    let file;
    formData.append("report_received_date", value.date_event);
    formData.append("id", selectedData.id);
    formData.append("status", status);
    var uploadImg = value.uploadImg;
    if (uploadImg) {
      for (var i = 0; i < uploadImg.length; i++) {
        file = uploadImg[i];
        formData.append("content", file);
      }
    }
    console.log('dateError',(dateError.length === 0),'statusError',(statusError.length === 0),'fileError',(fileError.length === 0));
    if (
      dateError.length === 0 &&
      status.length !== 0 &&
      uploadImg.length !== 0 ){
    axios({
      method: "POST",
      url: `${baseUrl}/cms/uploadcrfreport`,
      headers: {
        uit: token,
      },
      data: formData,
    }).then((res) => {

      if (res.data.code === 1) {
        // setLoading(false);
        Swal.fire({
          title: "success",
          html: "Cref Report uploaded successfully",
          icon: "success",
        });
        setStatus([]);
        setStatus("");
        setStatusError("");
        setFileError("");
        setDateError("");
        // history.push("/cms/crefmanage");
        trigger();
        showUploadModal();
      } else if (res.data.code === 0) {
        // setLoading(false);
        Swal.fire({
          title: "error",
          html: "Something went wrong",
          icon: "error",
        });
        setStatusError("");
        setFileError("");
        setDateError("");
      } else if (res.data.code === 102) {
        history.push("/cms/login");
      }
    });
    }
  };
  return (
    <div>
      <Modal isOpen={uploadModal} toggle={showUploadModal} size="md">
        <ModalHeader toggle={showUploadModal}>Cref Report Uploads</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="row">
                <div className="col-md-12 col-sm-12">
                  <label className="form-label">Date</label>
                  <input
                    type="date"
                    defaultValue={current_date}
                    name="date_event"
                    ref={register}
                    className={classNames("form-control", {
                      "is-invalid":
                        // errors.entity_name ||
                        dateError.length > 0,
                    })}
                    max={item}
                  />
                </div>
              </div>
              <div className="row">
              <div className="col-md-12 col-sm-12 mt-3 ">
                  <label className="form-label">Status</label>
              <select
                      className={classNames("form-select form-control", {
                        "form-select is-invalid":
                          // errors.entity_name ||
                          statusError.length > 0,
                      })}
                      name="p_status"
                      ref={register}
                      defaultValue={``}
                      onChange={(e) => setStatus(e.target.value)}
                      value={status}
                      style={{ height: "33px" }}
                    >
                      <option value="">--Status--</option>
                      <option value="2">Received</option>
                      <option value="3">Declined</option>
                    </select>
                </div>
                </div>
              <div className="row mt-4">
                <div className="col-md-12 col-sm-12">
                  <label className="form-label">
                    Reports <sup className="declined"> *</sup> 
                  </label>
                  {fileError.length !== 0 ? (
                    <span className="errorSpan">Please upload Report</span>
                    ) : ("")}
                  <input
                    type="file"
                    name="uploadImg"
                    ref={register}
                    className={classNames("form-control-file manage_file", {
                      "is-invalid form-control-file manage_file":
                        // errors.entity_name ||
                        fileError.length > 0,
                    })}
                    multiple
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  mmargin: "5px 0",
                  justifyContent: "flex-end",
                }}
              >
                <p>
                  {" "}
                  <sup className="declined"> *</sup>Pdf,doc,docx,xls only
                </p>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {loading === false ? (
                    <button className="customBtn mt-5">Submit</button>
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default CrefUpload;
