// import { baseUrl } from "../../config/config";
import { baseUrl } from "../../../config/config";
import axios from "axios";

const tpLogout = (history) => {
  // console.log("tpLogout");
  const token = window.localStorage.getItem("tptoken");
  const myConfig = {
    headers: {
      uit: token,
    },
  };
  axios.get(`${baseUrl}/tp/logout`, myConfig).then((res) => {
    localStorage.removeItem("tpkey");
    localStorage.removeItem("tpEmail");
    localStorage.removeItem("category");
    localStorage.removeItem("tptoken");
    localStorage.removeItem("searchDatatpquery1");
    localStorage.removeItem("searchDatatpquery2");
    localStorage.removeItem("searchDatatpquery3");
    localStorage.removeItem("searchDatatpquery4");
    localStorage.removeItem("searchDatatpproposal1");
    localStorage.removeItem("searchDatatpproposal2");
    localStorage.removeItem("searchDatatpproposal3");
    localStorage.removeItem("searchDatatpproposal4");
    localStorage.removeItem("searchDatatppayment1");
    localStorage.removeItem("searchDatatppayment2");
    localStorage.removeItem("searchDatatppayment3");
    localStorage.removeItem("searchDatatpAssignment1");
    localStorage.removeItem("searchDatatpAssignment2");
    localStorage.removeItem("searchDatatpAssignment3");
    localStorage.removeItem("searchDatatpAssignment4");
    localStorage.removeItem("tp_record_per_page");
    localStorage.removeItem("tpMessage1");
    localStorage.removeItem("tpcreate");
    localStorage.removeItem("tpFeedback");
    localStorage.removeItem("preMessage");
    localStorage.removeItem("prevtpmsg");
    localStorage.removeItem("freezetpFeedback");
    localStorage.removeItem("tpArrowFeed");
    localStorage.removeItem("tpMessage");
    localStorage.removeItem("freezetpMsg");
    localStorage.removeItem("tpArrowMsg");
    localStorage.removeItem("tpgenerated");
    localStorage.removeItem(`freezetpQuery1`);
    localStorage.removeItem(`freezetpQuery2`);
    localStorage.removeItem(`freezetpQuery3`);
    localStorage.removeItem("freezetpProposal1");
    localStorage.removeItem("freezetpProposal2");
    localStorage.removeItem("freezetpProposal3");
    localStorage.removeItem("freezetpProposal4");
    localStorage.removeItem("freezetpPayment1");
    localStorage.removeItem("freezetpPayment2");
    localStorage.removeItem("freezetpPayment3");
    localStorage.removeItem("freezetpAssignment1");
    localStorage.removeItem("freezetpAssignment2");
    localStorage.removeItem("freezetpAssignment3");
    localStorage.removeItem("freezetpAssignment4");
    localStorage.removeItem(`freezetpInvoice1`);
    localStorage.removeItem(`freezetpInvoice2`);
    localStorage.removeItem("tpQuery1");
    localStorage.removeItem("tpQuery2");
    localStorage.removeItem("tpQuery3");
    localStorage.removeItem("tpQuery4");
    localStorage.removeItem("tpProposal1");
    localStorage.removeItem("tpProposal2");
    localStorage.removeItem("tpProposal3");
    localStorage.removeItem("tpProposal4");
    localStorage.removeItem("tpPayment1");
    localStorage.removeItem("tpPayment2");
    localStorage.removeItem("tpPayment3");
    localStorage.removeItem("tpAssignment1");
    localStorage.removeItem("tpAssignment2");
    localStorage.removeItem("tpAssignment3");
    localStorage.removeItem("tpAssignment4");
    localStorage.removeItem("tpInvoice1");
    localStorage.removeItem("tpInvoice2");
    localStorage.removeItem("tpArrowQuery1");
    localStorage.removeItem("tpArrowQuery2");
    localStorage.removeItem("tpArrowQuery3");
    localStorage.removeItem("tpArrowQuery4");
    localStorage.removeItem("tpArrowProposal1");
    localStorage.removeItem("tpArrowProposal2");
    localStorage.removeItem("tpArrowProposal3");
    localStorage.removeItem("tpArrowProposal4");
    localStorage.removeItem("tpArrowPayment1");
    localStorage.removeItem("tpArrowPayment2");
    localStorage.removeItem("tpArrowPayment3");
    localStorage.removeItem("tpInvoice2");
    localStorage.removeItem("tpArrowInvoice2");
    localStorage.removeItem("prevtpInvoice2");
    localStorage.removeItem("freezetpInvoice2");
    localStorage.removeItem("tpcreate");
    localStorage.removeItem("tpArrowAs1");
    localStorage.removeItem("tpArrowAs2");
    localStorage.removeItem("tpArrowAs3");
    localStorage.removeItem("tpArrowAs4");
    localStorage.removeItem("tpArrowInvoice1");
    localStorage.removeItem("tpArrowInvoice2");
    localStorage.removeItem("prevtpq1");
    localStorage.removeItem("prevtpq2");
    localStorage.removeItem("prevtpq3");
    localStorage.removeItem("prevtpq4");
    localStorage.removeItem("prevtppro1");
    localStorage.removeItem("prevtppro2");
    localStorage.removeItem("prevtppro3");
    localStorage.removeItem("prevtppro4");
    localStorage.removeItem("prevtppay1");
    localStorage.removeItem("prevtppay2");
    localStorage.removeItem("prevtppay3");
    localStorage.removeItem("prevtpAs1");
    localStorage.removeItem("prevtpAs2");
    localStorage.removeItem("prevtpAs3");
    localStorage.removeItem("prevtpAs4");
    localStorage.removeItem("tpInvoice1");
    localStorage.removeItem("tpArrowInvoice1");
    localStorage.removeItem("prevtpInvoice1");
    localStorage.removeItem("freezetpInvoice1");
    localStorage.removeItem("tpgenerated");
    localStorage.removeItem("recordingSorttp");
    localStorage.removeItem("TpCompClientId");
    localStorage.removeItem("TpClientPerm");
    localStorage.removeItem("TpClientAccess");
    localStorage.removeItem("TpClientList");

    axios.get(`${baseUrl}/customers/getCategory?pid=0`).then((res) => {
      if (res.data.code === 1) {
        localStorage.removeItem("tpcategoryData");
        let data = res.data.result;
        data.map((i) => {
          localStorage.removeItem(`tp${i.details}`);
        });
      }
    });
    history.push("/taxprofessional/login");
  });
};

export default tpLogout;
