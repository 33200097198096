import React from 'react';

function PageNotFound() {
    return (
        <div style={{ fontSize: "60px", textAlign: "center" }}>
            PageNotFound
        </div>
    );
}

export default PageNotFound;