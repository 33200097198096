import React, { useState, useEffect, useRef } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Collapse,
} from "reactstrap";
import { Select } from "antd";
import axios from "axios";
import { baseUrl, baseUrl2 } from "../../../config/config";
import Swal from "sweetalert2";
import classNames from "classnames";
import { object } from "yup";

function ModuleMod({ history, toggle, modal }) {
  const { Option } = Select;
  const token = window.localStorage.getItem("adminToken");
  const myConfig = {
    headers: {
      uit: token,
    },
  };

  const [count, setCount] = useState("");
  const [btn,setBtn]=useState(false)
  const [clientmulti, setClientmulti] = useState();
  const [compMulti, setCompMulti] = useState([]);
  const [formCont, setFormCont] = useState([]);
  const [clinetList, setClinetList] = useState([]);
  const [formarray, setFormarray] = useState([]);
  const [secCrif, setSecCrif] = useState([]);
  const [permOption, setPermOption] = useState([]);
  const [stats, setStats] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [allList, setAllList] = useState([]);
  const { handleSubmit, register, errors, reset, control } = useForm();
  const [disable, setDisable] = useState(false);
  const [allSub, setAllsub] = useState([]);
  const [oneData, setOneData] = useState([]);
  const [accessAr, setAccessAr] = useState([]);
  const [tempList, setTempList] = useState([]);
  const [noAdded, setNoAdded] = useState(0);

  //add form field
  const addFormField = () => {
    setNoAdded(noAdded + 1);

    if (clinetList) {
      const extractedIds = oneData.map((data) => data.client_id);
      const filteredClientList = clinetList.filter(
        (client) => !extractedIds.includes(client.id)
      );

      // console.log('filteredClientList',filteredClientList);
      // console.log('formFields',formFields);

      const filteredArray = filteredClientList.filter(
        (obj) => !formFields.some((item) => item.custId === obj.id)
      );
      setAllList(filteredArray);
      console.log("filteredArray=>", filteredArray);

      const arrayOfArrays = [];

      // if(tempList.length === 0){
      //   const arrayOfArrays = [filteredClientList];
      //   setTempList(arrayOfArrays)
      //   console.log('00000');
      // }else{
      //   const arrayOfArrays = [...tempList];
      //   arrayOfArrays.push(filteredArray);
      //   setTempList(arrayOfArrays)
      //   console.log('11111');
      // }

      if (permOption.length === 0) {
        const arrayOfArrays = [filteredClientList];
        setPermOption(arrayOfArrays);
        console.log("00000", arrayOfArrays);
      } else {
        const arrayOfArrays = [...permOption];
        arrayOfArrays.push(filteredArray);
        setPermOption(arrayOfArrays);
        console.log("11111", arrayOfArrays);
      }
      setFormFields([
        ...formFields,
        {
          custId: "",
          direct: "",
          indirect_tax: "",
          corporate: "",
          accounting: "",
          other: "",
        },
      ]);
    }
  };

  useEffect(() => {
    console.log("history, toggle, modal=>", history, modal);
  }, [history, modal]);

  useEffect(() => {
    console.log("noAdded=>", noAdded);
  }, [noAdded]);

  useEffect(() => {
    console.log("tempList=>", tempList);
  }, [tempList]);

  useEffect(() => {
    console.log("permOption=>", permOption);
  }, [permOption]);

  useEffect(() => {
    console.log("accessAr=>", accessAr);
    if (accessAr.length > 0) {
      mix();
    }
  }, [accessAr]);

  useEffect(() => {
    console.log("formFields==>", formFields);
  }, [formFields]);

  const removeFormField = (index) => {
    setNoAdded(noAdded - 1);
    // console.log("index=>", index);
    setFormFields((prevFormFields) => {
      const updatedFormFields = [...prevFormFields];
      updatedFormFields.splice(index, 1);
      return updatedFormFields;
    });
    const newArray = [...permOption];
    newArray.splice(index, 1);
    setPermOption(newArray);
  };
  //   console.log('permOption=>',permOption);

  useEffect(() => {
    if (modal == true) {
      getClientData();
      getTlinfo();
    } else {
      setOneData([]);
      setFormFields([]);
      setAccessAr([]);
      setPermOption([]);
    }
    setAllsub([
      {
        id: "1",
        value: "Direct Tax",
        name: "direct_tax",
        status: true,
      },
      {
        id: "2",
        value: "Indirect Tax",
        name: "indirect_tax",
        status: false,
      },
      {
        id: "3",
        value: "Corporate",
        name: "corporate",
        status: false,
      },
      {
        id: "4",
        value: "Accounting",
        name: "accounting",
        status: false,
      },
      { id: "5", value: "Others", name: "other", status: false },
    ]);
    setFormarray([
      {
        clientId: "",
        direct_tax: "",
        indirect_tax: "",
        corporate: "",
        accounting: "",
        other: "",
      },
    ]);
  }, [modal]);

  useEffect(() => {
    if (history) {
      //   console.log("history", history);
      //   getTlinfo();
    }
  }, [history]);

  //   useEffect(() => {
  //     console.log('formfiled',formFields);
  //   }, [formFields])

  //   useEffect(() => {
  //     console.log("secCrif", secCrif);
  //   }, [secCrif]);

  //   useEffect(() => {
  //     console.log('clinetList=>',clinetList);
  //    }, [clinetList])

  //    useEffect(() => {
  //     console.log("compMulti=>", compMulti);
  //   }, [compMulti]);

  // useEffect(() => {
  //   console.log("UseaccessAr=>", accessAr);
  //   console.log("UseclinetList=>", clinetList);
  //   if (accessAr && clinetList) {
  //   }
  // }, [accessAr, clinetList]);

  useEffect(() => {
    console.log("oneData==>", oneData);
  }, [oneData]);

  useEffect(() => {
    //   console.log("clinetList=>", clinetList);
    if (oneData) {
      const extractedIds = oneData.map((data) => data.id);
      const filteredClientList = clinetList.filter(
        (client) => !extractedIds.includes(client.id)
      );
    }
  }, [clinetList]);

  const mix = () => {
    if (accessAr) {
      const newArray2 = accessAr;

      console.log("newarray==>", newArray2);

      const arrayWith9Length = newArray2.filter(
        (obj) => Object.keys(obj).length > 4
      );
      const arrayWithout9Length = newArray2.filter(
        (obj) => Object.keys(obj).length < 5
      );

      const updatedArray = arrayWith9Length.map((obj) => {
        const updatedObj = { ...obj };
        if (obj.pother === "1") {
          updatedObj.pother = true;
        } else {
          updatedObj.pother = false;
        }
        if (obj.paccounting === "1") {
          updatedObj.paccounting = true;
        } else {
          updatedObj.paccounting = false;
        }
        if (obj.pcorporate === "1") {
          updatedObj.pcorporate = true;
        } else {
          updatedObj.pcorporate = false;
        }
        if (obj.pdirect_tax === "1") {
          updatedObj.pdirect_tax = true;
        } else {
          updatedObj.pdirect_tax = false;
        }
        if (obj.pindirect_tax === "1") {
          updatedObj.pindirect_tax = true;
        } else {
          updatedObj.pindirect_tax = false;
        }
        return updatedObj;
      });

      const withStatus = updatedArray.map((obj) => {
        return {
          ...obj,
          status: false,
        };
      });
      setOneData(withStatus);
    } else {
      // getTlinfo();
    }
  };

  useEffect(() => {
    console.log('history__',history);
  }, [history])
  

  const getTlinfo = () => {
    axios
      .get(
        `${baseUrl}/admin/tltpcompliancelist?tp_id=${history.id}`,
        myConfig
      )
      .then((res) => {
        console.log("gettingTLaccess=>", res.data.result);
        if (res.data.code) {
          let allac = res.data.result;
          setAccessAr(allac);
          if (allac.length > 0) {
          }
        }
      });
  };

  const getClientData = () => {
    axios.get(`${baseUrl}/admin/allcomplianceclient`, myConfig).then((res) => {
      console.log("gettingClientList=>", res.data.result);
      let resultList = res.data.result;
      setClinetList(resultList);
    });
  };

  const gettldata = (id) => {
    axios
      .get(`${baseUrl}/admin/tlcompliancelist?tl_id=${history.tl_id}`, myConfig)
      .then((res) => {
        console.log("gettingClientList=>", res.data.result);
        let resultList = res.data.result;
      });
  };

  const handleChange = (index, e) => {
    const value = e.target.value;
    const updatedFormFields = [...formFields];
    updatedFormFields[index] = {
      ...updatedFormFields[index],
      custId: e.target.value,
    };
    setFormFields(updatedFormFields);
  };

  const handleSubCategory = (f, e, iden) => {
    const { name, checked } = e.target;

    // console.log('f==>',f,'e==>',e,'iden==>',iden);

    const updateValueForKey = (obj, keyToUpdate, newValue) => {
      console.log(
        "obj==>",
        obj,
        "keyToUpdate==>",
        keyToUpdate,
        "newValue==>",
        newValue
      );
      return Object.keys(obj).reduce((acc, key) => {
        acc[key] = key === keyToUpdate ? newValue : obj[key];
        return acc;
      }, {});
    };

    setOneData((prevState) =>
      prevState.map((item) => {
        let key = name;
        const keys = Object.keys(item);
        const updatedItem = { ...keys };
        if (item.id === iden) {
          const updatedObjArray = updateValueForKey(item, name, checked);
          console.log("updatedObjArray", updatedObjArray);
          return updatedObjArray;
        } else {
          return item;
        }
      })
    );
  };

  const handleDirect = (index, e) => {
    let value = e.target.checked;
    console.log("value", value);
    const updatedFormFields = [...formFields];
    updatedFormFields[index] = {
      ...updatedFormFields[index],
      pdirect: value,
    };
    setFormFields(updatedFormFields);
  };
  const handleIndirect = (index, e) => {
    let value = e.target.checked;
    const updatedFormFields = [...formFields];
    updatedFormFields[index] = {
      ...updatedFormFields[index],
      pindirect_tax: value,
    };
    setFormFields(updatedFormFields);
  };
  const handleCorporate = (index, e) => {
    let value = e.target.checked;
    const updatedFormFields = [...formFields];
    updatedFormFields[index] = {
      ...updatedFormFields[index],
      pcorporate: value,
    };
    setFormFields(updatedFormFields);
  };
  const handleaccount = (index, e) => {
    let value = e.target.checked;
    const updatedFormFields = [...formFields];
    updatedFormFields[index] = {
      ...updatedFormFields[index],
      paccounting: value,
    };
    setFormFields(updatedFormFields);
  };
  const handleother = (index, e) => {
    let value = e.target.checked;
    const updatedFormFields = [...formFields];
    updatedFormFields[index] = {
      ...updatedFormFields[index],
      pother: value,
    };
    setFormFields(updatedFormFields);
  };

  useEffect(() => {
    console.log("formFields=>", formFields);
  }, [formFields]);

  const onSubmiting = (index) => {
    console.log("f0", index);
    const newData = formFields[index];

    console.log(" ", newData);
    let formData = new FormData();
    formData.append("client_id", newData.client_id);
    formData.append("tp_id", history.id);
    formData.append("tl_id", history.tl_id);
    if (newData.pdirect === true) {
      formData.append("pdirect_tax", 1);
    } else {
      formData.append("pdirect_tax", 0);
    }
    if (newData.pindirect_tax === true) {
      formData.append("pindirect_tax", 1);
    } else {
      formData.append("pindirect_tax", 0);
    }
    if (newData.pcorporate === true) {
      formData.append("pcorporate", 1);
    } else {
      formData.append("pcorporate", 0);
    }
    if (newData.paccounting === true) {
      formData.append("paccounting", 1);
    } else {
      formData.append("paccounting", 0);
    }
    if (newData.pother === true) {
      formData.append("pother", 1);
    } else {
      formData.append("pother", 0);
    }

    axios
      .post(`${baseUrl2}/v1/admin/tpcomplianceassing`, formData, {
        headers: {
          "content-type": "multipart/form-data",
          uit: token,
        },
      })
      .then((res) => {
        if (res.data.code === 1) {
          Swal.fire({
            title: "success",
            html: "Compliance status changed successfully",
            icon: "success",
          });
          setStats(true);
          getTlinfo();
          removeFormField(index);
        } else if (res.data.code === 0) {
          // Swal.fire({
          //   title: "success",
          //   html: "Compliance status changed successfully",
          //   icon: "success",
          // });
          // setStats(false);
        } else {
        }
      });
  };

  const save = (id) => {
    let idToFilter = id;
    const filterObjectById = (array, idToFilter) => {
      return array.filter((obj) => obj.client_id === idToFilter);
    };

    const filteredArray = filterObjectById(oneData, idToFilter);
    const tosave = filteredArray[0];

    console.log("tosave==>", idToFilter, tosave);

    Swal.fire({
      title: "Are you sure?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then((result) => {
      if (result.isConfirmed == true) {
        setBtn(true)
        let formData = new FormData();
        if (tosave.pid) {
          formData.append("pid", tosave.pid);
        }
        formData.append("client_id", tosave.client_id);
        formData.append("tp_id", history.id);
        formData.append("tl_id", history.tl_id);
        if (tosave.pdirect_tax === true) {
          formData.append("pdirect_tax", 1);
        } else {
          formData.append("pdirect_tax", 0);
        }
        if (tosave.pindirect_tax === true) {
          formData.append("pindirect_tax", 1);
        } else {
          formData.append("pindirect_tax", 0);
        }
        if (tosave.pcorporate === true) {
          formData.append("pcorporate", 1);
        } else {
          formData.append("pcorporate", 0);
        }
        if (tosave.paccounting === true) {
          formData.append("paccounting", 1);
        } else {
          formData.append("paccounting", 0);
        }
        if (tosave.pother === true) {
          formData.append("pother", 1);
        } else {
          formData.append("pother", 0);
        }

        axios
          .post(`${baseUrl2}/v1/admin/tpcomplianceassing`, formData, {
            headers: {
              "content-type": "multipart/form-data",
              uit: token,
            },
          })
          .then((res) => {
            if (res.data.code === 1) {
              Swal.fire({
                title: "success",
                html: "Compliance status changed successfully",
                icon: "success",
              });
              setBtn(false)
              //   setStats(true);
              getTlinfo();
            } else if (res.data.code === 0) {
              setBtn(false)
              // setStats(false);
            } else {
            }
          });
      }
    });
  };

  const showsub = (e) => {
    console.log("e=>", e);

    const fo = oneData.find((item) => item.id === e);
    console.log("fo=>", fo);
    setOneData((prevState) =>
      prevState.map((item) => {
        if (item.id === e) {
          return { ...item, status: true };
        }
        return { ...item, status: false };
      })
    );
    // setAllsub((prevState) =>
    //   prevState.map((item) => {
    //     return { ...item, status: false };
    //   })
    // );
    setAllsub((prevState) =>
      prevState.map((item) => {
        if (item.name === "direct_tax") {
          return { ...item, direct_tax: item.direct_tax };
        } else if (item.name === "indirect_tax") {
          return { ...item, indirect_tax: item.indirect_tax };
        } else if (item.name === "accounting") {
          return { ...item, accounting: item.accounting };
        } else if (item.name === "corporate") {
          return { ...item, corporate: item.corporate };
        } else {
          return { ...item, other: item.other };
        }
      })
    );
  };

  const hidesub = (e) => {
    console.log("e=>", e);
    setOneData((prevState) =>
      prevState.map((item) => {
        if (item.id === e) {
          return { ...item, status: false };
        }
        return item;
      })
    );
  };

  const deleteCust = (e) => {
    console.log("deteteing==>", e);

    Swal.fire({
      title: "Are you sure?",
      text: "It will permanently deleted !",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        const client_id = e.client_id;
        const tl_id = e.tl_id;
        const id = e.id;
        console.log("Deleting......");
        axios
          .get(
            `${baseUrl}/admin/tlcomplianceassingdelete?client_id=${client_id}&tl_id=${tl_id}&id=${id}`,
            myConfig
          )
          .then((res) => {
            if (res.data.code === 1) {
              Swal.fire({
                title: "success",
                html: "Client deleted successfully",
                icon: "success",
              });
              getTlinfo();
            } else {
              Swal.fire({
                title: "error",
                html: "Something went wrong , please try again",
                icon: "error",
              });
            }
          });
      }
    });
  };

  return (
    <div>
      <Modal isOpen={modal} fade={false} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}>
          <span className="ComplianceHeader">
            Tax professional compliance access module.
            {/* <span className="ComplianceHeaderSpan">
              <span> Complinace : </span>
            </span> */}
          </span>
        </ModalHeader>
        <ModalBody
          style={{ maxHeight: "570px", overflowY: "auto", overflowX: "hidden" }}
        >
          <div className="compPrime row justify-content-start">
            <div className="compPrimeDet col-md-5">
              Tax Professional Post-
              <span className="detailSpan">{history?.post_name}</span>
            </div>
            <div className="compPrimeDet col-md-4">
              Tax Professional Name-{" "}
              <span className="detailSpan">{history?.name}</span>
            </div>

            <div className="compPrimeDet col-md-4 mt-2">
              Team Leader Post-{" "}
              <span className="detailSpan">{history?.tl_post_name}</span>
            </div>
          </div>

          <>
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: "50px" }}>S.No</th>
                  <th style={{ width: "140px" }}>Client</th>
                  <th style={{ width: "660px" }}>Compliance Category</th>
                  <th style={{ width: "50px" }}></th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
            <div className="modsavediv">
              {oneData?.map((item, index) => (
                <>
                  <table className="table">
                    <thead></thead>
                    <tbody>
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td style={{ width: "52px" }}>{index + 1}</td>
                          <td style={{ width: "140px" }}>{item?.user_id}</td>
                          <td style={{ width: "850px" }}>
                            <form onSubmit={handleSubmit()} autocomplete="off">
                              {item?.module_id?.length > 0 ? (
                                <input
                                  type="hidden"
                                  ref={register}
                                  value={item?.module_id}
                                  name="id"
                                />
                              ) : (
                                <input
                                  type="hidden"
                                  ref={register}
                                  value={``}
                                  name="id"
                                />
                              )}
                              <input
                                type="hidden"
                                ref={register}
                                value={item?.id}
                                name="subclient_id"
                              />
                              <input
                                type="hidden"
                                ref={register}
                                value={history.id}
                                name="client_id"
                              />
                              <div className="basicFeild compField row justify-content-around">
                                <div className="sub col-md-10 mb-3">
                                  <span>
                                    <input
                                      type="checkbox"
                                      name="pdirect_tax"
                                      checked={item?.pdirect_tax}
                                      // value={item.direct_tax}
                                      ref={register}
                                      id="hide"
                                      disabled={
                                        item?.direct_tax == 1 ? false : true
                                      }
                                      onClick={(e) =>
                                        handleSubCategory(
                                          "pdirect_tax",
                                          e,
                                          item?.id
                                        )
                                      }
                                    ></input>
                                    <label
                                      htmlFor="hide"
                                      style={{
                                        margin: "1px",
                                        pointerEvents: "none",
                                      }}
                                    >
                                      Direct Tax
                                    </label>
                                  </span>
                                  <span>
                                    <input
                                      type="checkbox"
                                      name="pindirect_tax"
                                      checked={item?.pindirect_tax}
                                      ref={register}
                                      id="hide"
                                      disabled={
                                        item?.indirect_tax == 1 ? false : true
                                      }
                                      onClick={(e) =>
                                        handleSubCategory(
                                          "pindirect_tax",
                                          e,
                                          item?.id
                                        )
                                      }
                                    ></input>
                                    <label
                                      htmlFor="hide"
                                      style={{
                                        margin: "1px",
                                        pointerEvents: "none",
                                      }}
                                    >
                                      Indirect Tax
                                    </label>
                                  </span>
                                  <span>
                                    <input
                                      type="checkbox"
                                      name="pcorporate"
                                      checked={item?.pcorporate}
                                      // value={item.corporate}
                                      ref={register}
                                      id="hide"
                                      disabled={
                                        item?.corporate == 1 ? false : true
                                      }
                                      onClick={(e) =>
                                        handleSubCategory(
                                          "pcorporate",
                                          e,
                                          item?.id
                                        )
                                      }
                                    ></input>
                                    <label
                                      htmlFor="hide"
                                      style={{
                                        margin: "1px",
                                        pointerEvents: "none",
                                      }}
                                    >
                                      Corporate
                                    </label>
                                  </span>
                                  <span>
                                    <input
                                      type="checkbox"
                                      name="paccounting"
                                      checked={item?.paccounting}
                                      // value={item.accounting}
                                      ref={register}
                                      id="hide"
                                      disabled={
                                        item?.accounting == 1 ? false : true
                                      }
                                      onClick={(e) =>
                                        handleSubCategory(
                                          "paccounting",
                                          e,
                                          item?.id
                                        )
                                      }
                                    ></input>
                                    <label
                                      htmlFor="hide"
                                      style={{
                                        margin: "1px",
                                        pointerEvents: "none",
                                      }}
                                    >
                                      Accounting
                                    </label>
                                  </span>
                                  <span>
                                    <input
                                      type="checkbox"
                                      name="pother"
                                      checked={item?.pother}
                                      // value={item.other}
                                      ref={register}
                                      id="hide"
                                      disabled={item?.other == 1 ? false : true}
                                      onClick={(e) =>
                                        handleSubCategory("pother", e, item?.id)
                                      }
                                    ></input>
                                    <label
                                      htmlFor="hide"
                                      style={{
                                        margin: "1px",
                                        pointerEvents: "none",
                                      }}
                                    >
                                      Others
                                    </label>
                                  </span>
                                </div>
                                <button
                                  type="button"
                                  className="customBtn mx-sm-1 mb-2"
                                  disabled={btn}
                                  style={{ height: "50%", width: "5rem" }}
                                  onClick={() => save(item.client_id)}
                                >
                                  Save
                                </button>
                              </div>
                            </form>
                          </td>
                        </tr>
                      </tbody>
                    </tbody>
                  </table>

                  {/* {item.status === true ? (
                    <fieldset className="my-fieldset subClientdiv">
                      <legend
                        className="login-legend"
                        style={{ fontSize: "1.3rem" }}
                      >
                        Compliance Permissions
                      </legend>
                      <div className="container">
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          autocomplete="off"
                        >
                          {item?.module_id?.length > 0 ? (
                            <input
                              type="hidden"
                              ref={register}
                              value={item.module_id}
                              name="id"
                            />
                          ) : (
                            <input
                              type="hidden"
                              ref={register}
                              value={``}
                              name="id"
                            />
                          )}
                          <input
                            type="hidden"
                            ref={register}
                            value={item.id}
                            name="subclient_id"
                          />
                          <input
                            type="hidden"
                            ref={register}
                            value={history.id}
                            name="client_id"
                          />
                          <div className="basicFeild row justify-content-around">
                            <div className="sub col-md-10 mb-3">
                              {allSub?.map((i) => (
                                <span>
                                  <input
                                    type="checkbox"
                                    name={i.name}
                                    checked={i.status}
                                    // value={i.name}
                                    ref={register}
                                    id="hide"
                                    disabled={disable}
                                    onClick={(e) => handleSubCategory(e)}
                                  ></input>
                                  <label
                                    htmlFor="hide"
                                    style={{ margin: "1px" }}
                                  >
                                    {i.value}
                                  </label>
                                </span>
                              ))}
                            </div>
                            <button
                              type="submit"
                              className="customBtn mx-sm-1 mb-2"
                              style={{ height: "50%", width: "5rem" }}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </fieldset>
                  ) : (
                    ""
                  )} */}
                </>
              ))}
            </div>
          </>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
}

export default ModuleMod;
