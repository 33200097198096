import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { baseUrl } from "../../config/config";
import Swal from "sweetalert2";
import axios from "axios";
const AddModal = ({ addEmailFun, addEmail, addedEmail, token, getData }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [nameError, setnameError] = useState(null);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [mobileno, setMobileNo] = useState("")
  const [wEmail, setWemail] = useState();
  const [wname, setWname] = useState();
  const [valiEmail, setValiemail] = useState(null);
  const [validname, setValidname] = useState(null);
  const [invalid, setInvalid] = useState(null);
  const [invalidname, setInvalidName] = useState(null);
  const [countryCode, setCountryCode] = useState('91');
  const [phoneLength, setPhoneLength] = useState(10);
  const [indNumError, setIndNumError] = useState(null);
  const [numAvail, setNumAvail] = useState(null);
  const [numExist, setNumExist] = useState(null);
  const [phoneError, setPhoneError] = useState(null);

  const { handleSubmit, register, errors, reset, getValues, control } =
    useForm();

  const emailHandler = (e) => {
    // console.log("eee", e.target.value);s
    setEmail(e.target.value);
    if (e.target.value.length < 1) {
      setWemail("");
    }
  };
  const onSubmit = (value) => {
    if (emailError === false) {
      let formData = new FormData();
      formData.append("email", email);
      formData.append("name", name);
      formData.append("contact", mobileno);
      axios({
        method: "POST",
        url: `${baseUrl}/customers/addoptionalemail`,
        headers: {
          uit: token,
        },
        data: formData,
      }).then((res) => {
        addEmailFun();
        if (res.data.code === 1) {
          Swal.fire({
            title: "success",
            html: "email added successfully",
            icon: "success",
          });
        } else if (res.data.code === 0) {
          Swal.fire({
            title: "error",
            html: `${res.data.message}`,
            icon: "error",
          });
        }
        getData();
      });
    }
  };
  const emailValidation = (key) => {
    var validRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.length > 0 && email.match(validRegex)) {
      setWemail("");
      setEmailError(false);
    } else {
      setEmailError(true);
      setWemail("Invalid email");
    }
  };
  const checkSpecial = (e) => {
    var regEx = /^[0-9a-zA-Z .]+$/;
    if (e.target.value.match(regEx)) {
      setName(e.target.value);
      setWname("");
      setnameError(false);
    } else if (e.target.value.length === 0) {
      setName("");
      setWname("Invalid name");
      setnameError(false);
    }
  };

  const phoneHandler = (e) => {
    console.log('eee=>',e.length);
    if (isNaN(e)) {
      setIndNumError("")
      setNumAvail("");
      setNumExist('Please enter number only')
      setPhone("")
      setPhoneError(true)
    }
    else {
      setPhoneError(false)
      setNumAvail("");
      setNumExist("");
      setMobileNo(e)
    }
  }

  const phoneBlur = (e) => {
    if (e.length < 10) {
      setIndNumError("")
      setNumAvail("");
      setNumExist('Contact Number should be 10 digit')
      setPhone("")
      setPhoneError(true)
    }
  }

  return (
    <Modal isOpen={addEmail} toggle={addEmailFun} size="sm">
      <ModalHeader toggle={addEmailFun}>
        <h4>Add Sub-Client Details</h4>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="row">
              <div className="col-lg-12">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  name="p_name"
                  value={name}
                  maxLength="100"
                  onChange={(e) => checkSpecial(e)}
                  ref={register({ required: true })}
                  placeholder="Enter Name"
                  className={classNames("form-control", {
                    "is-invalid": errors.p_name,
                  })}
                />
                {wname ? (
                <p className="declined">{wname}</p>
              ) : (
                <>
                  {valiEmail ? (
                    <p className="completed">{validname}</p>
                  ) : (
                    <p className="declined">{invalidname}</p>
                  )}
                </>
              )}
                </div>
                </div>
          <div className="row">
            <div className="col-lg-12">
              <label className="form-label">Secondary Email</label>

              <input
                type="email"
                maxLength="100"
                name="p_email"
                className={classNames("form-control", {
                  "is-invalid": errors.p_email,
                })}
                value={email}
                onChange={(e) => emailHandler(e)}
                onBlur={(e) => emailValidation(e)}
                placeholder="Enter Your Email"
                ref={register({ required: true })}
              />
             
              {wEmail ? (
                <p className="declined">{wEmail}</p>
              ) : (
                <>
                  {valiEmail ? (
                    <p className="completed">{valiEmail}</p>
                  ) : (
                    <p className="declined">{invalid}</p>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="row">
          <div className="col-lg-12">
                  <div className="mb-3">
                      <label className="form-label">Mobile number<span className="declined">*</span></label>
                      <div className="mobNumber" style={{ "display": "flex" }}>
                        <select
                          name="p_code"
                          disabled={true}
                          ref={register({ required: true })}
                        >
                          <option>
                            { "+" + countryCode}
                          </option>
                        </select>
                        <input
                        type="text"
                        autoComplete="off"
                        maxLength={phoneLength}
                        onBlur={(e) => phoneBlur(e.target.value)}
                        onChange={(e) => phoneHandler(e.target.value)}  
                        name="p_mobile"
                        value={mobileno}
                        ref={register({required : true})}
                        placeholder="Enter Mobile Number"
                        className={classNames("form-control", {
                          "is-invalid": errors.p_mobile 
                        })}
                        />

                      </div>
                      {indNumError ? <p className="declined">{indNumError}</p> : <>
                        {
                          numAvail ?
                            <p className="completed"> {numAvail}
                            </p>
                            :
                            <p className="declined">{numExist}</p>
                        }
                      </>}
                    </div>
                  </div>
          </div>
          <button className="customBtn" type="submit">
            Add
          </button>
        </form>
      </ModalBody>
    </Modal>
  );
};
export default AddModal;
