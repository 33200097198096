import React, { useState, useEffect, useRef } from "react";
import Layout from "../../../components/Layout/Layout";
import axios from "axios";
import { baseUrl } from "../../../config/config";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import { Select } from "antd";
import BootstrapTable from "react-bootstrap-table-next";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DiscardReport from "../AssignmentTab/DiscardReport";
import CommonShowProposal from "../../../components/commonShowProposal/CommonShowProposal";
import DataTablepopulated from "../../../components/DataTablepopulated/DataTabel";
import MessageIcon, {
  EyeIcon,
  ViewDiscussionIcon,
  EditQuery,
  ActionIcon,
} from "../../../components/Common/MessageIcon";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { makeStyles } from "@material-ui/core/styles";
import { FileIcon } from "../../../components/Common/MessageIcon";
import FolderIcon from "@mui/icons-material/Folder";
// import RightClickComponent from "../../../components/RightClick/RightClick";
import RightClickComponent from "../../../components/RightClick/RightClickOne";
// import ClientSelect from "../../../components/CompClient/CompSelect";

const useStyles = makeStyles((theme) => ({
  isActive: {
    backgroundColor: "green",
    color: "#fff",
    margin: "0px 10px",
  },
}));
function CompBody() {
  const classes = useStyles();
  const { Option } = Select;
  const userId = window.localStorage.getItem("adminkey");
  const [clientList, setClientList] = useState([]);
  const [ViewDiscussion, setViewDiscussion] = useState(false);
  const [viewProposalModal, setViewProposalModal] = useState(false);
  const [scrolledTo, setScrolledTo] = useState("");
  const [lastDown, setLastDown] = useState("");
  const myRefs = useRef([]);
  const myRef = useRef([]);

  useEffect(() => {
    var element = document.getElementById(scrolledTo);
    if (element) {
      let runTo = myRef.current[scrolledTo];
      runTo?.scrollIntoView(false);
      runTo?.scrollIntoView({ block: "center" });
    }
  }, [ViewDiscussion]);
  useEffect(() => {
    let runTo = myRefs.current[lastDown];
    runTo?.scrollIntoView(false);
    runTo?.scrollIntoView({ block: "center" });
  }, [viewProposalModal]);

  const token = window.localStorage.getItem("tlToken");
  const myConfig = {
    headers: {
      uit: token,
    },
  };

  useEffect(() => {
    getTlinfo();
  }, []);
  // console.log("userid", userid);

  const getTlinfo = () => {
    const a = localStorage.getItem("tlkey");
    const par = parseInt(a);
    const tlid = Number.par;
    // let tlid = parseInt(userid)
    console.log("tlid", tlid);
    // axios
    //   .get(
    //     `${baseUrl}/tl/tlcompliancelist?tl_id=${JSON.parse(userId)}`,
    //     myConfig
    //   )
    //   .then((res) => {
    //     console.log("gettingTLaccess=>", res.data.result);
    //     let allac = res.data.result;
    //     setClientList(allac);
    //   });
  };

  const handleClient = () => {};

  return (
    <>
        {/* <RightClickComponent> */}
        <div className="myFolderWrapper">
        
          <FileIcon
            name="Test"
            sx={{ fontSize: "2.5rem", pointer: "cursor" }}
          />
          <FolderIcon
            sx={{ fontSize: "2.5rem" }}
            // onClick={(e) => props.getFolerSubFile(i)}
            className={
              // props.color === Number(i.id) ? 
              "folderActive" 
              // : 
              // "folderColor"
            }
          />
          <FolderIcon
            sx={{ fontSize: "2.5rem" }}
            // onClick={(e) => props.getFolerSubFile(i)}
            className={
              // props.color === Number(i.id) ? 
              // "folderActive" 
              // : 
              "folderColor"
            }
          />
          </div>
          {/* </RightClickComponent> */}
    </>
  );
}

export default CompBody;
