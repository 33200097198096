import React, { useState,useEffect,useRef } from "react";
import { Grid } from "@mui/material";
import LogoutHeader from "./LogoutHeader";
import { Link,useHistory } from "react-router-dom";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { DeleteIcon, EditQuery,EyeIcon,FileIconSmaller } from "../Common/MessageIcon";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import DetailModal from "../crefPage/DetailModal";
import { clientLogout } from "../Logout/ClientLogout";
import Swal from "sweetalert2";
import axios from "axios";
import DataTablepopulated from "../DataTablepopulated/DataTabel";
import { baseUrl,baseUrl2 } from "../../config/config";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { error } from "jquery";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  isActive: {
    backgroundColor: "green",
    color: "#fff",
    margin: "0px 2px",
  },
}));

const CrefRec = (props) => {
  const myRef = useRef([]);
  const [data, setData] = useState([]);
  const [copy, setCopy] = useState(0);
  const [detailModal, setDetailModal] = useState(false);
  const [modalData,setModalData] = useState({});
  const [prev, setPrev] = useState("");
  const [accend, setAccend] = useState(false);
  const [page, setPage] = useState(1);
  const [atPage, setAtpage] = useState(1);
  const [big, setBig] = useState(1);
  const [end, setEnd] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [records, setRecords] = useState([]);
  const [orderVal,setOrderVal]=useState("0");
  const [orderField,setOrderField]=useState("2");
  const [countNotification, setCountNotification] = useState('0');
  const [turnGreen, setTurnGreen] = useState(false);
  const [resetTrigger, setresetTrigger] = useState(false);
  const [isActive, setIsActive] = useState("");
  const [defaultPage, setDefaultPage] = useState(["1", "2", "3", "4", "5"]);
  const [scrolledTo, setScrolledTo] = useState("");


  let allEnd = Number(localStorage.getItem("cust_record_per_page"));
  const token = window.localStorage.getItem("clientToken")
  const myConfig = {
    headers : {
     "uit" : token
    }
  }
  let history = useHistory();

  const classes = useStyles()

  function headerLabelFormatter(column, colIndex) {
    let isActive = true;

    if (
      localStorage.getItem("custArrowCref4") === column.dataField ||
      localStorage.getItem("prevcustcref4") === column.dataField
    ) {
      isActive = true;
      setPrev(column.dataField);
      localStorage.setItem("prevcustcref4", column.dataField);
    } else {
      isActive = false;
    }
    return (
      <div className="d-flex text-white w-100 flex-wrap">
        <div style={{ display: "flex", color: "#fff" }}>
          {column.text}
          {localStorage.getItem("custArrowCref4") === column.dataField ? (
            <ArrowDropUpIcon
              className={isActive === true ? classes.isActive : ""}
            />
          ) : (
            <ArrowDropDownIcon
              className={isActive === true ? classes.isActive : ""}
            />
          )}
        </div>
      </div>
    );
  }

  const firstChunk = () => {
    setAtpage(1);
    setPage(1);
    getTableData(1);
  };
  const prevChunk = () => {
    if (atPage > 1) {
      setAtpage((atPage) => atPage - 1);
    }
    setPage(Number(page) - 1);
    getTableData(page - 1);
  };
  const nextChunk = () => {
    if (atPage < totalPages) {
      setAtpage((atPage) => atPage + 1);
    }
    setPage(Number(page) + 1);
    getTableData(page + 1);
  };
  const lastChunk = () => {
    setPage(defaultPage.at(-1));
    getTableData(defaultPage.at(-1));
    setAtpage(totalPages);
  }

  const getTableData = (e) => {

    let droppage = [];
    let remainApiPath = "";

    localStorage.setItem("custcref4", e);

    let sort = JSON.parse(localStorage.getItem("sortcref4"));

      let val = sort?.orderBy;
      let field =sort?.fieldBy;

      if(e === 1){
        if(sort){
          remainApiPath = `/crfcustomers/querylist?page=${e}&status=2&orderby=${val}&orderbyfield=${field}`
        }else{
          remainApiPath = `/crfcustomers/querylist?page=${e}&status=2&orderby=0&orderbyfield=0`
        } 
      }else{
        if(sort){
          remainApiPath = `/crfcustomers/querylist?page=${e}&status=2&orderby=${val}&orderbyfield=${field}&total=${countNotification}`
        }else{
          remainApiPath = `/crfcustomers/querylist?page=${e}&status=2&orderby=0&orderbyfield=0&total=${countNotification}`
        } 
      }

    axios.get(`${baseUrl}${remainApiPath}`, myConfig).then((res) => {
      if (res.data.code === 1) {
        let data = res.data.result;
        setCountNotification(res.data.total);
        setRecords(res.data.total);

        let all = [];
          let customId = 1;
          if (e > 1) {
            customId = allEnd * (e - 1) + 1;
          }

          data.map((i) => {
            let data = {
              ...i,
              cid: customId,
            };
            customId++;
            all.push(data);
          });
          let end = e * allEnd;
          if (end > res.data.total) {
            end = res.data.total;
          }
          let dynamicPage = Math.ceil(res.data.total / allEnd);

          let rem = (e - 1) * allEnd;

          if (e === 1) {
            setBig(rem + e);
            setEnd(end);
          } else {
            setBig(rem + 1);
            setEnd(end);
          }
          for (let i = 1; i <= dynamicPage; i++) {
            droppage.push(i);
          }
          setPage(e)
          setDefaultPage(droppage);
          setData(all);
      }else if (res.data.code === 102) {
        clientLogout(axios, history);
      }
      else{
        console.log('error',res.data.result);
      }
    });
  }

  const handleobject = (row) => {
    let stage = "";
    const substring = row.created_date;
    if (row.created_date && row.report_received_date) {
      let rec = row.report_received_date;
      var onlyDate = substring.split(" ");
      var fields = onlyDate[0].split("-");
      let result = `${fields[2]}-${fields[1]}-${fields[0]}`;

      var onlyDaterec = rec.split(" ");
      var fieldsrec = onlyDaterec[0].split("-");
      let retrec = `${fieldsrec[2]}-${fieldsrec[1]}-${fieldsrec[0]}`;

      if (row.status == 0) {
        stage = "Pending";
      } else if (row.status == 1) {
        stage = "Sent to be Prepared";
      } else if (row.status == 2) {
        stage = "Recived";
      } else {
        stage = "Declined";
      }

      setModalData({
        ...row,
        created_date: result,
        status: stage,
        report_received_date: retrec,
      });
    } else if (row.created_date && row.report_decline_date) {
      let dec = row.report_decline_date;

      var onlyDate = dec.split(" ");
      var fields = onlyDate[0].split("-");
      let result = `${fields[2]}-${fields[1]}-${fields[0]}`;

      var onlyDatedec = dec.split(" ");
      var fieldsdec = onlyDatedec[0].split("-");
      let resultdec = `${fieldsdec[2]}-${fieldsdec[1]}-${fieldsdec[0]}`;

      if (row.status == 0) {
        stage = "Pending";
      } else if (row.status == 1) {
        stage = "Sent to be Prepared";
      } else if (row.status == 2) {
        stage = "Received";
      } else {
        stage = "Declined";
      }

      setModalData({ ...row, created_date: result, status: stage,report_decline_date : resultdec,});
    } else {
      var onlyDate = substring.split(" ");
      var fields = onlyDate[0].split("-");
      let result = `${fields[2]}-${fields[1]}-${fields[0]}`;

      if (row.status == 0) {
        stage = "Pending";
      } else if (row.status == 1) {
        stage = "Sent to be Prepared";
      } else if (row.status == 2) {
        stage = "Received";
      } else {
        stage = "Declined";
      }

      setModalData({ ...row, created_date: result, status: stage });
    }
  };

  const showDetailModal = (row) => {
    setDetailModal(!detailModal);
    handleobject(row);
    setScrolledTo(row.query_id);
    if(detailModal === 'true'){
    // setSelectedData(row);
    console.log('this is true');
    
    }
  };

  useEffect(()=>{
    localStorage.removeItem("sortcref4");
    localStorage.removeItem("custArrowCref4");
    localStorage.removeItem("prevcustcref4");
    getTableData(1);
  },[])

  useEffect(() => {
    var element = document.getElementById(scrolledTo);
    if (element) {
      let runTo = myRef.current[scrolledTo];
      runTo?.scrollIntoView(false);
      runTo?.scrollIntoView({ block: "center" });
    }
  }, [detailModal])

  useEffect(()=>{
    console.log('dataRec',data);
  },[data])

  const sortMessage = (val, field) => {
    let droppage = [];
    let e =1;

    let sort = {
      orderBy: val,
      fieldBy: field,
    };

    setOrderVal(val)
    setOrderField(field)

    localStorage.setItem("custcref4", 1);
    localStorage.setItem("sortcref4", JSON.stringify(sort));

    let remainApiPath = `/crfcustomers/querylist?status=2&page=1&orderby=${val}&orderbyfield=${field}&total=${countNotification}`;

    axios.get(`${baseUrl}/${remainApiPath}`, myConfig).then((res) => {
      if (res.data.code === 1) {
        let data = res.data.result;
        setCountNotification(res.data.total);
        setRecords(res.data.total);

        let all = [];
          let customId = 1;
          if (e > 1) {
            customId = allEnd * (e - 1) + 1;
          }

          data.map((i) => {
            let data = {
              ...i,
              cid: customId,
            };
            customId++;
            all.push(data);
          });
          let end = e * allEnd;
          if (end > res.data.total) {
            end = res.data.total;
          }
          let dynamicPage = Math.ceil(res.data.total / allEnd);

          let rem = (e - 1) * allEnd;

          if (e === 1) {
            setBig(rem + e);
            setEnd(end);
          } else {
            setBig(rem + 1);
            setEnd(end);
          }
          for (let i = 1; i <= dynamicPage; i++) {
            droppage.push(i);
          }
          setPage(e)
          setDefaultPage(droppage);
          setData(all);
      }
    });
  };

  const columns = [
    {
      text: "S.No",
      dataField: "",
      headerStyle: () => {
        return { width: "50px" };
      },
      formatter: (cellContent, row, rowIndex) => {
        return ( <div
          id={row.query_id}
          ref={(el) => (myRef.current[row.query_id] = el)}
        >
          {row.cid}
        </div>)
      },
    },
    {
      text: "Query Number",
      dataField: "query_number",
      headerStyle: () => {
        return { width: "100px" };
      },

      sort: true,
      headerFormatter: headerLabelFormatter,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          localStorage.setItem("custArrowCref4", field);
        } else {
          setAccend("");
          localStorage.removeItem("custArrowCref4");
        }

        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 0);
      },
      formatter: (cellContent, row, rowIndex) => {
        return <div onClick={(e) => showDetailModal(row)} className="querydiv">{row.query_id}</div>;
      },
    },
    {
      text: "	Query Date",
      dataField: "query_date",
      headerStyle: () => {
        return { width: "100px" };
      },
      sort: true,
      headerFormatter: headerLabelFormatter,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          localStorage.setItem("custArrowCref4", field);
        } else {
          setAccend("");
          localStorage.removeItem("custArrowCref4");
        }

        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 2);
      },
      formatter: (cellContent, row, rowIndex) => {
        const substring = row.created_date
        var onlyDate=substring.split(' ');
        var fields = onlyDate[0].split('-');
        let result= `${fields[2]}-${fields[1]}-${fields[0]}`
        return <div>{result}</div>;
      },
    },
    {
      text: "Entity Name",
      dataField: "entity_name",
      headerStyle: () => {
        return { width: "200px" };
      },
      sort: true,
      headerFormatter: headerLabelFormatter,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          localStorage.setItem("custArrowCref4", field);
        } else {
          setAccend("");
          localStorage.removeItem("custArrowCref4");
        }

        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 3);
      },
      formatter: (cellContent, row, rowIndex) => {
        return <div>{row.entity_name}</div>;
      },
    },
    {
      text: "Entity Type",
      dataField: "entity_type",
      sort: true,
      headerFormatter: headerLabelFormatter,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          localStorage.setItem("custArrowCref4", field);
        } else {
          setAccend("");
          localStorage.removeItem("custArrowCref4");
        }

        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 4);
      },
      headerStyle: () => {
        return { width: "100px" };
      },
      formatter: (cellContent, row, rowIndex) => {
        return <div>{row.entity_type}</div>;
      },
    },
    {
      text: "Report Status",
      headerStyle: () => {
        return { width: "100px" };
      },
      dataField: "report_status",
      formatter: (cellContent, row, rowIndex) => {
        let status = "";
        if(row.status == 0){
          status = "Pending"
        }else if(row.status == 1){
          status = "Sent to be Prepared"
        }else if(row.status == 2){
          status = "Received"
        }else{
          status = "Declined"
        }
        return <div>{status}</div>;
      },
    },
    {
      text: "Date Received",
      dataField: "date_received",
      headerStyle: () => {
        return { width: "100px" };
      },
      sort: true,
      headerFormatter: headerLabelFormatter,
      onSort: (field, order) => {
        let val = 0;
        if (accend !== field) {
          setAccend(field);
          localStorage.setItem("custArrowCref4", field);
        } else {
          setAccend("");
          localStorage.removeItem("custArrowCref4");
        }

        if (accend === field) {
          val = 0;
        } else {
          val = 1;
        }
        sortMessage(val, 6);
      },
      formatter: (cellContent, row, rowIndex) => {
        if(row.report_received_date){
          const substring = row.report_received_date
          var onlyDate=substring.split(' ');
          var fields = onlyDate[0].split('-');
          let result= `${fields[2]}-${fields[1]}-${fields[0]}`
          return <div>{result}</div>;
          }else{
            return <div></div>;
          }
      },
    },
    {
      text: "Reports",
      headerStyle: () => {
        return { width: "150px" };
      },
      formatter: function CmsAction(cell, row) {
        return (
          <>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              {/* {(row.status == "0")||(row.status == "1") || (row.status == "2") ? (
                <span onClick={(e) => showDetailModal(row) } className="ml-1">
                  <EyeIcon />
                </span>
              ) : <span className="ml-1  pl-3" >
                  </span>} */}
                  {(row.status == "2") ? (
              <span
                onClick={() =>
                  downloadpdf(row)
                }
                style={{ cursor: "pointer" }}
                title="Download Report"
              >
                  <FileIconSmaller name={`name.pdf`}/>
              </span>
              ) : <span className="ml-1  pl-3" >
              </span>}
            </div>
          </>
        );
      },
    },
    {
      text: "Payments",
      headerStyle: () => {
        return { width: "150px" };
      },
      formatter: function CmsAction(cell, row) {
        return (
          <>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <span
                onClick={() =>
                  downloadInvoice(row)
                }
                style={{ cursor: "pointer" }}
                title="Download Invoice"
              >
                  <DescriptionOutlinedIcon color="primary" name={`name.pdf`}/>
              </span>
            </div>
          </>
        );
      },
    },
  ];

  const downloadpdf = (row) => {
    // console.log("DownloadAll",row);

    if(row.file){
      console.log('=>',row.file);
      let location = row.file;
      const file = location.split(".");
      const length = file.length;
      const filetype = file[length - 1];
      let filename =row.query_id

    setCopy(0);
    const myConfig2 = {
      headers: {
        uit: token,
      },
      responseType: "blob",
    };

    // window.open(`${baseUrl2}/${url}`,'_blank', `rel=crefReport_${filename}.pdf`)

    axios.get(`${baseUrl2}/v1/crfcustomers/crfdocument?id=${row.id}`, myConfig2).then((res) => {
      if (res.status === 200) {
        window.URL = window.URL || window.webkitURL;
        var url = window.URL.createObjectURL(res.data);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = `crefReport_${filename}.${filetype}`;
        //    a.download = "invoice"+{qno}+{installmentNumber}+'.pdf';
        a.target = "_blank";
        a.click();
      }
    });

  }
  };

  const downloadInvoice = (row) => {
    console.log("DownloadInvice",row);

    // if(row.file){
      console.log('=>',row.file);
      let url = row.file;
      let filename =row.query_id

    // setCopy(0);
    const myConfig2 = {
      headers: {
        uit: token,
      },
      responseType: "blob",
    };

    // window.open(`${baseUrl2}/${url}`,'_blank', `rel=crefReport_${filename}.pdf`)

    axios.get(`${baseUrl2}/v1/crfcustomers/crfdocument?id=${row.id}&type=2`, myConfig2).then((res) => {
      if (res.status === 200) {
        console.log('==>',res);
        window.URL = window.URL || window.webkitURL;
        var url = window.URL.createObjectURL(res.data);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = `crefInvoice_${filename}.pdf`;
        //    a.download = "invoice"+{qno}+{installmentNumber}+'.pdf';
        a.target = "_blank";
        a.click();
      }
    });

  
  };

  return (
    <Grid container-fluid spacing={2} m={1}>
      <Grid item lg={12} mx={5} mb={1}>
              <Col md="12" align="right">
                { (countNotification == 0) ? (<span className="customPaginationSpan">
                  <div className="ml-auto d-flex  mr-5 pr-5 w-100 align-items-center justify-content-end">
                    <span className="customPaginationSpan">0-0 of 0</span></div></span>): 
                    (
                      <div className="customPagination">
                        <div className="ml-auto d-flex mb-2 mr-4 w-100 align-items-center justify-content-end">
                          <span className="customPaginationSpan">
                            {big}-{end} of {countNotification}
                          </span>
                          <span className="d-flex">
                            {page > 1 ? (
                              <>
                                <button
                                  className="navButton"
                                  onClick={(e) => firstChunk()}
                                >
                                  <KeyboardDoubleArrowLeftIcon />
                                </button>
                                <button
                                  className="navButton"
                                  onClick={(e) => prevChunk()}
                                >
                                  <KeyboardArrowLeftIcon />
                                </button>
                              </>
                            ) : (
                              ""
                            )}
                            <div className="navButtonSelectDiv">
                              <select
                                value={page}
                                onChange={(e) => {
                                  setPage(Number(e.target.value));
                                  getTableData(Number(e.target.value));
                                }}
                                className="form-control"
                              >
                                {defaultPage?.map((i) => (
                                  <option value={i}>{i}</option>
                                ))}
                              </select>
                            </div>
                            {defaultPage?.length > page ? (
                              <>
                                <button
                                  className="navButton"
                                  onClick={(e) => nextChunk()}
                                >
                                  <KeyboardArrowRightIcon />
                                </button>
                                <button
                                  className="navButton"
                                  onClick={(e) => lastChunk()}
                                >
                                  <KeyboardDoubleArrowRightIcon />
                                </button>
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div> )
                }
              </Col>
        </Grid>
      <Grid item lg={12} mx={5}>
        <div className="mx-5">
          <DataTablepopulated
            bgColor="#5f7b97"
            keyField={"assign_no"}
            data={data}
            columns={columns}
          ></DataTablepopulated>
           <DetailModal
          showDetailModal={showDetailModal}
          detailModal={detailModal}
          selectedData={modalData}
          />
        </div>
      </Grid>
    </Grid>
  );
};
export default CrefRec;
