import React, { useState, useEffect, useLayoutEffect } from "react";
import CrefPage from "./CrefPage";
import { Tab, Tabs, TabPanel, TabList } from "react-tabs";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import LogoutHeader from "../CompliAny/LogoutHeader";
import CustomHeading from "../Common/CustomHeading";
import Cref from "../CompliAny/Cref";
import CrefDec from "../CompliAny/CrefDec";
import CrefPending from "../CompliAny/CrefPending";
import CrefRec from "../CompliAny/CrefRec";
import { clientLogout } from "../Logout/ClientLogout";


function CrefAll(props) {
  let history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const [bgColor, setbgColor] = useState("#42566a");

  const tableIndex = (index) => {
    setTabIndex(index);
    // console.log(index);
    if (index === 0) {
      setbgColor("#42566a");
    } else if (index === 1) {
      setbgColor("#5f7b97");
    } else if (index === 2) {
      setbgColor("#5f7b97");
    } else if (index === 3) {
      setbgColor("#5f7b97");
    }
  };
  const myStyle1 = {
    margin: "10px auto",
    fontSize: "14px",
  };
  const myStyle2 = {
    margin: "10px auto",

    color: "#42566a",
    fontWeight: 1000,
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <LogoutHeader name="CRIF Report" />
        </Grid>
      </Grid>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => tableIndex(index)}>
        <TabList className="fixedTab">
          <Tab style={tabIndex == 0 ? myStyle2 : myStyle1} className="tabHover">
            All Query
          </Tab>
          <Tab style={tabIndex == 1 ? myStyle2 : myStyle1} className="tabHover">
            Fresh Query
          </Tab>
          <Tab style={tabIndex == 2 ? myStyle2 : myStyle1} className="tabHover">
            Reports Pending
          </Tab>

          <Tab style={tabIndex == 3 ? myStyle2 : myStyle1} className="tabHover">
            Reports Received
          </Tab>

          <Tab style={tabIndex == 4 ? myStyle2 : myStyle1} className="tabHover">
            Reports Declined
          </Tab>
        </TabList>
        <TabPanel>
          <Cref />
        </TabPanel>
        <TabPanel>
          <CrefPage props={bgColor} />
        </TabPanel>
        <TabPanel>
          <CrefPending />
        </TabPanel>
        <TabPanel>
          <CrefRec />
        </TabPanel>
        <TabPanel>
          <CrefDec />
        </TabPanel>
      </Tabs>
    </>
  );
}

export default CrefAll;
