import React, { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

function Breadcrumb(props) {
  const {
    crumb,
    handleCrumb,
    setCrumb,
    setGotoCrumb,
    gotoCrumb,
    curFoldDetail,
  } = props;

  const crumbLength = crumb?.length;
  const capitalizeFirstWord = (str) => {
    return str.replace(/^\w/, (c) => c.toUpperCase());
  };
  console.log("crumb==>", crumb, crumbLength);
  if (crumb && crumb.length > 0) {
    crumb[0].name = capitalizeFirstWord(crumb[0].name);
  }

  const goto = (item) => {
    console.log("gotoitem==>", item);
    setGotoCrumb(item);
  };

  return (
    <>
      {crumb?.map((item, index) => (
        <>
          {item?.id == curFoldDetail.id ? (
            <>
              <span
                className="crumbName crumbActive"
                id={item.id}
                key={item.id}
                onClick={() => goto(item)}
              >
                {item.name}
              </span>
              {index + 1 == crumbLength ? (
                ""
              ) : (
                <span className="crumbArrow">
                  <KeyboardDoubleArrowRightIcon />
                </span>
              )}
            </>
          ) : (
            <>
              <span
                className="crumbName"
                id={item.id}
                key={item.id}
                onClick={() => goto(item)}
              >
                {item.name}
              </span>
              {index + 1 == crumbLength ? (
                ""
              ) : (
                <span className="crumbArrow">
                  <KeyboardDoubleArrowRightIcon />
                </span>
              )}
            </>
          )}
        </>
      ))}
    </>
  );
}

export default Breadcrumb;
