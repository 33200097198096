import React, { useEffect,useState } from "react";
import mazars from "./../../assets/images/mazars-logo.png";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import MainHeading from "../Common/MainHeading";
import { useHistory } from "react-router-dom";
import CustomTypography from "../Common/CustomTypography";

const LogoutHeader = (props) => {
  const history = useHistory();
  const clName = JSON.parse(localStorage.getItem("clientLoginId"));
  const CustEmail = window.localStorage.getItem("custEmail");

  const [atHome, setAtHome] = useState(false);
  
  useEffect(() => {
    if(props.name == "Mazars Advisory Solutions"){
      setAtHome(true)
    }
  }, [props])
  
  
  

  return (
    <>
      <Grid fluid>
        <Grid item lg={12}>
          <div className="headerMin">
            <Link to="/customer/dashboard">
              <img src={mazars} alt="mazar" className="logo" />
            </Link>
            <>
                      <div className="d-flex profileDetail" style={{"marginLeft":"50px"}}>
                        <CustomTypography
                          font={18}
                          margin="20px 20px"
                        >{`Client : `}</CustomTypography>
                        <div>
                          <CustomTypography style={{"paddingBottom":"0px"}} >{clName}</CustomTypography>
                          <CustomTypography margin="0px" style={{"paddingTop":"0px"}}>
                            {JSON.parse(CustEmail)}
                          </CustomTypography>
                        </div>
                      </div>
                    </>
            <span className="loginHeading onTop" style={{"width":"50%"}}>
              <MainHeading style={{"paddingRight":"300px"}}>{props.name}</MainHeading>
            </span>

            {
               atHome ?
                  ""
                  :
                  <Link to="/customer/masterdashboard">
                  <button class="customBtn"  style={{"margin":"18px"}}>
                  Go Back
                </button>
                </Link>
              } 
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default LogoutHeader;
