import React, { useState } from "react";
import Layout from "../../../components/Layout/Layout";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { CardHeader, Row, Col } from "reactstrap";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import History from "./History";
import CustomHeading from "../../../components/Common/CustomHeading";
import ModuleMod from "./TlModule";
// import Feedback from "./Feedback";

function ModuleTab({
  overDue,
  p,
  diaplaySpecific,
  diaplayProposal,
  diaplayHistory,
  diaplayAssignment,
  assingNo,
  customerQuery,
  queryDocs,
  purpose,
  paymentDetails,
  year,
  feedback,
  reports,
  submitData,
  accept,
  tlName2,
  tp22,
  tpStatus,
  declined2,
  declinedStatus,
  finalDate,
  qstatus,
  panel,
  props,
}) {
  const [tabIndex, setTabIndex] = useState(0);
  const [history, setHistory] = useState([]);
  const userid = window.localStorage.getItem("adminkey");
  const myStyle1 = {
    margin: "10px auto",
    fontSize: "18px",
    cursor: "pointer",
  };
  const myStyle2 = {
    margin: "10px auto",
    color: "#42566a",
    fontSize: "18px",
    cursor: "pointer",
    fontWeight: "bold",
    textDecoration: "underline",
  };



  return (
    <Layout adminDashboard="adminDashboard" adminUserId={userid}>
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="card">
            <CardHeader>
              <Row>
                <Col md="4">
                  {/* {props.location.index ? ( */}
                    <Link
                      to={{
                        pathname: `/admin/teamleaders`,
                        // index: props.location.index,
                      }}
                    >
                      <button class="customBtn">Go Back</button>
                    </Link>
                  {/* ) : ( */}
                    {/* <button class="customBtn" onClick={() => history.goBack()}>
                      Go Back
                    </button> */}
                  {/* )} */}
                </Col>
                <Col md="4">
                  <CustomHeading>Query details</CustomHeading>
                </Col>
                <Col md="4" align="center"></Col>
              </Row>
            </CardHeader>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
            >
              <TabList className="fixedTab">
                <Tab
                  style={tabIndex == 0 ? myStyle2 : myStyle1}
                  className="tabHover"
                >
                  TL Assign
                </Tab>
                <Tab
                  style={tabIndex == 1 ? myStyle2 : myStyle1}
                  className="tabHover"
                >
                  Add New
                </Tab>
              </TabList>

              <TabPanel>
                <Card body>
                  <h1>Setting one</h1>
                  {/* <BasicQuery
              p={p}
              diaplaySpecific={diaplaySpecific}
              purpose={purpose}
              queryDocs={queryDocs}
              year={year}
              declined2={declined2}
              declinedStatus={declinedStatus}
              qstatus={qstatus}
              panel={panel}
            /> */}
                </Card>
              </TabPanel>
              <TabPanel>
                <Card body>
                  {/* <ProposalDetails
              diaplayProposal={diaplayProposal}
              diaplayHistory={diaplayHistory}
              paymentDetails={paymentDetails}
              p={p}
              accept={accept}
              tlName2={tlName2}
              tp22={tp22}
              tpStatus={tpStatus}
              panel={panel}
              overDue={overDue}
            /> */}
            <ModuleMod />
                </Card>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ModuleTab;
