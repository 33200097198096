import React, { useState, useEffect } from "react";
import FolderIcon from "@mui/icons-material/Folder";
// import CustomTypography from "../../../components/Common/CustomTypography";
import CustomTypography from "../Common/CustomTypography";
// import { FileIcon } from "../../../components/Common/MessageIcon";
import { FileIcon } from "../Common/MessageIcon";
// import FolderRename from "../../../components/FolderWrapper/FolderRename";
import axios from "axios";
// import { baseUrl } from "../../../config/config";
// import { DeleteIcon } from "../../../components/Common/MessageIcon";
import { Dropdown, Menu } from "antd";

import Swal from "sweetalert2";
const FolderWrappers = (props) => {
  // console.log('pppp',props);
  let allData = props.saveChildren;
  const onLevel = props.onLevel;
  const setOnlevel = props.setOnlevel;
  const onFolder = props?.curFoldDetail;
  const curretlyAt = props?.curFoldDetail;
  const deleteFile = props?.deleteFile;
  const renameFileModal = props?.renameFileModal;
  const moveFile = props?.moveFile;
  const deleteFolder = props?.deleteFolder;
  const moveFolder = props?.moveFolder;
  const files = props.file;
  const stats = props.stats;
  // console.log("files", files);
  let saveAllChild = props.currentChild;
  //   console.log("saveAllChild", saveAllChild);
  const [show, setShow] = useState(true);
  //   console.log("propsFile", props);
  //   const deleteFile = (e) => {
  //     Swal.fire({
  //       title: "Are you sure?",
  //       text: "Do you want to delete file",
  //       type: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes",
  //     }).then((res) => {
  //       if (res.value === true) {
  //         let formData = new FormData();
  //         formData.append("assign_no", e.assign_no);
  //         formData.append("id", e.id);
  //         formData.append("document", e.document);

  //         axios({
  //           method: "POST",
  //           url: `${baseUrl}/tl/deletereportdocument`,
  //           data: formData,
  //         }).then((res) => {
  //           if (res.data.code === 1) {
  //             props.getFile();
  //             Swal.fire({
  //               title: "success",
  //               html: "File deleted successfully",
  //               icon: "success",
  //             });
  //           }
  //           console.log("Response", res);
  //         });
  //       }
  //     });
  //   };overlay={folderMenu}

  const rename = (tochange) => {
    // console.log('props2',tochange);
    props.setTorename(tochange);
    props.setRenameTrigger(!props.renameTrigger);
  };

  const folderMenu = (props) => {
    // console.log("props1", props);
    // changecolor(props)
    if (props.name === "upload") {
      return (
        // <Menu className="rightMenu">
        //   {/* <Menu.Item key="1" >Move</Menu.Item> */}
        //   <Menu.Item key="2">No Rename</Menu.Item>
        //   {/* <Menu.Item key="3">Paste</Menu.Item> */}
        // </Menu>
        ""
      );
    } else {
      return (
        <Menu className="rightMenu">
          <Menu.Item key="1" onClick={() => moveFolder(props)}>
            Move
          </Menu.Item>
          <Menu.Item key="2" onClick={() => rename(props)}>
            Rename
          </Menu.Item>
          <Menu.Item key="3" onClick={() => deleteFolder(props)}>
            Delete
          </Menu.Item>
        </Menu>
      );
    }
  };

  const fileMenu = (props) => {
    if (props.name === "upload") {
      return "";
    } else {
      return (
        <Menu className="rightMenu">
          <Menu.Item key="1" onClick={() => moveFile(props, 1)}>
            Copy
          </Menu.Item>
          <Menu.Item key="2" onClick={() => moveFile(props, 2)}>
            Move
          </Menu.Item>
          <Menu.Item key="3" onClick={() => renameFileModal(props)}>
            Rename
          </Menu.Item>
          <Menu.Item key="4" onClick={() => deleteFile(props, curretlyAt)}>
            Delete
          </Menu.Item>
        </Menu>
      );
    }
  };

  // const folderMenu = (props) => {
  //   console.log('props',props);
  //   return (
  //     <Menu onClick={props.selection}>
  //       <Menu.Item key="1" onClick={move}>
  //         Move
  //       </Menu.Item>
  //       <Menu.Item key="2">Copy</Menu.Item>
  //       <Menu.Item key="3">Paste</Menu.Item>
  //     </Menu>
  //   );
  // };

  useEffect(() => {
    // console.log("props=>", props);
  }, [props]);

  //     console.log("onLevel=>", onFolder);
  //     if (onLevel >= 0 && props?.curFoldDetail && props.saveChildren) {
  //       getFolder(onLevel);
  //     }
  //   }, [onFolder]);

  //   function findParentStructure(childId, structure) {
  //     console.log('PPP',childId,structure);
  //     for (const key in structure) {
  //       if (key === childId) {
  //         return structure;
  //       } else if (structure[key].child) {
  //         const result = findParentStructure(childId, structure[key].child);
  //         if (result) {
  //           return structure;
  //         }
  //       }
  //     }
  //     return null;
  //   }

  //   const getFolder = (level) => {
  //     if (level >= 0) {
  //       //   console.log("currentShowing=>", props?.curFoldDetail);
  //       console.log("AllTheDataInFunction=>", allData);

  //       const currentFold = props?.curFoldDetail;
  //       const childId = currentFold?.id; // Change this to the desired child ID
  //       //   const childId = '19'
  //       const parent = findParentStructure(childId, allData);
  //       if (parent) {
  //         console.log("Parent ID:", parent);
  //         const dataArray = Object.values(parent).map((item) => item.info);
  //         const filteredArray = dataArray.filter((item) => item !== undefined);
  //         console.log('ParentFolder=>',filteredArray);
  //         props.setFolder(filteredArray);
  //       } else {
  //         console.log("Parent not found for child ID:", childId);
  //       }

  //       //   const only = saveAllChild[id];
  //       //   const onlyInfo = only[`info`];
  //       //   props.setCurFoldDetail(onlyInfo);
  //       //   console.log("only==>", only);
  //       //   console.log("onlyInfo==>", onlyInfo);
  //       //   const onlygrandchild = only[`child`];

  //       //   if (onlygrandchild) {
  //       //     props.setCurrentChild(onlygrandchild);
  //       //     console.log("onlygrandchild", onlygrandchild);
  //       //     const dataArray = Object.values(onlygrandchild).map((item) => item.info);
  //       //     const filteredArray = dataArray.filter((item) => item !== undefined);
  //       //     console.log("dataArray", filteredArray);
  //       //     props.setFolder(filteredArray);
  //       //   } else {
  //       //     props.setFolder([]);
  //       //   }
  //     }
  //   };

  const getChildren = (id) => {
    // console.log("currentShowing=>", props.curFoldDetail);
    console.log("getChildren=>", id);
    // console.log("AllTheDataInFunction=>", allData);
    if (onLevel > 0) {
      setOnlevel(onLevel + 1);
    }
    // console.log('id=>',id);
    // console.log("saveAllChild==>", saveAllChild);
    const only = saveAllChild[id];
    // console.log("only==>", only);
    const onlyInfo = only[`info`];
    // console.log("onlyInfo==>", onlyInfo);
    props.setCurFoldDetail(onlyInfo);
    props.setCrumb((prevCrumb) => [...prevCrumb, onlyInfo]);
    props.setGotoCrumb([]);
    const onlygrandchild = only[`child`];

    if (onlygrandchild) {
      props.setCurrentChild(onlygrandchild);
      // console.log("onlygrandchild", onlygrandchild);
      const dataArray = Object.values(onlygrandchild).map((item) => item.info);
      const filteredArray = dataArray.filter((item) => item !== undefined);
      //   console.log("dataArray", filteredArray);
      props.setFolder(filteredArray);
    } else {
      props.setFolder([]);
    }
  };

  return (
    <>
      {stats === true ? (
        <div className="myFolderWrapper FolderWrapperList">
          <>
            {props?.folder?.map((i) => (
              <Dropdown overlay={folderMenu(i)} trigger={["contextMenu"]}>
                <div className="folderCreatedComp folderView">
                  <FolderIcon
                    sx={{ fontSize: "3.5rem" }}
                    onClick={(e) => getChildren(i.id)}
                    className={
                      props.color === Number(i.id)
                        ? "folderActive"
                        : "folderColor"
                    }
                    id={i.id}
                  />
                  <span className="folderLabel">
                    <span
                      className="folderLabel forBreak"
                      onClick={(e) => getChildren(i.id)}
                    >
                      <CustomTypography>{i.name}</CustomTypography>
                    </span>
                  </span>
                </div>
              </Dropdown>
            ))}
          </>

          <>
            {props?.file?.map((i) => (
              <>
                {curretlyAt?.id == i?.folder_id ? (
                  <div className="folderCreatedComp fileView" id={i.id}>
                    <Dropdown overlay={fileMenu(i)} trigger={["contextMenu"]}>
                      <span
                        className="mt-2 filecomp"
                        // onContextMenu={(e) => props.moveTo(e, i, props.basePath)}
                        onDoubleClick={(e) =>
                          props.downloadFile(e, i.folder_id, i.id, i.filename)
                        }
                      >
                        <span style={{ cursor: "pointer", marginLeft: "3px" }}>
                          <FileIcon
                            name={i.document}
                            sx={{ fontSize: "3.0rem", pointer: "cursor" }}
                          />
                        </span>
                        <span className="folderLabel forBreak mt-1">
                          <CustomTypography> {i.filename}</CustomTypography>
                        </span>
                        <span className="FileDate">
                          {new Date(i.upload_date).toLocaleString()}
                        </span>
                      </span>
                    </Dropdown>
                  </div>
                ) : (
                  ""
                )}
              </>
            ))}
          </>
        </div>
      ) : (
        <div className="myFolderWrapper">
          <>
            {props?.folder?.map((i) => (
              <div className="folderCreatedComp">
                {/* <Dropdown overlay={folderMenu(i)} trigger={["contextMenu"]}> */}
                <FolderIcon
                  sx={{ fontSize: "3.5rem" }}
                  onClick={(e) => getChildren(i.id)}
                  className={
                    props.color === Number(i.id)
                      ? "folderActive"
                      : "folderColor"
                  }
                  id={i.id}
                />
                {/* </Dropdown> */}

                <span className="folderLabel">
                  <span className="folderLabel forBreak">
                    <CustomTypography>{i.name}</CustomTypography>
                  </span>
                </span>
              </div>
            ))}
          </>

          <>
            {props?.file?.map((i) => (
              <>
                {curretlyAt?.id == i?.folder_id ? (
                  <div className="folderCreatedComp" id={i.id}>
                    {/* <Dropdown overlay={fileMenu(i)} trigger={["contextMenu"]}> */}
                    <span
                      className="mt-2 filecomp"
                      // onContextMenu={(e) => props.moveTo(e, i, props.basePath)}
                      onDoubleClick={(e) =>
                        props.downloadFile(e, i.folder_id, i.id, i.filename)
                      }
                    >
                      <span style={{ cursor: "pointer" }}>
                        <FileIcon
                          name={i.document}
                          sx={{ fontSize: "3.0rem", pointer: "cursor" }}
                        />
                      </span>
                      <span className="folderLabel forBreak mt-2">
                        <CustomTypography> {i.filename}</CustomTypography>
                      </span>
                    </span>
                    {/* </Dropdown> */}
                  </div>
                ) : (
                  ""
                )}
              </>
            ))}
          </>
        </div>
      )}
    </>
  );
};
export default FolderWrappers;
