import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm } from "react-hook-form";
import CustomHeading from "../../../components/Common/CustomHeading";
import classNames from "classnames";
import axios from "axios";
import { baseUrl } from "../../../config/config";
import Swal from "sweetalert2";
import Select from "react-select";
function CreateFolder({
  addPaymentModal,
  rejectHandler,
  id,
  getList,
  tab,
  movedFolder,
  set_sub_folder,
  setColor,
  setInnerFiles,
  setShowSubFolderData,
  getInnerFileFile,
  color,
  getMoveToList,
  newmodal,
  getFolderOther,
  direct_tax,
  allList,
  showFolder,
  setFolder,
  currentFold,
  setCurrentFolder,
  curFoldDetail,
  newCreated,
  setNewCreated,
  createdAt,
  setCreatedAt,
  currentTab,
  setNeedtoJump,
  setCreateFolder,
}) {
  const { handleSubmit, getValue, register, errors } = useForm();

  const [folderid, setFolderId] = useState({
    label: "...root",
    value: "6",
  });

  const access = JSON.parse(localStorage.getItem("TlClientAccess"));

  // const getList = () =>
  const gettingUpdated = (value) => {
    var confToken = "";
    confToken = window.localStorage.getItem("tptoken");

    const myConfig = {
      headers: {
        uit: confToken,
      },
    };
    axios
      .get(`${baseUrl}/tl/getfolderstructure?rfolder_id=${id}`, myConfig)
      .then((res) => {
        if (res.data.code === 1) {
          const parentId = Number(res.data.result.parent_id);
          // setCurrentFolder(parentId);
          console.log("parentId", parentId);
          const desk = res.data.result.structure.child;
          const obj = desk[parentId];
          const next = [obj];

          const dataArray = Object.values(obj).map((item) => item.info);
          const filteredArray = dataArray.filter((item) => item !== undefined);
          // console.log("table=>", desk);
          // console.log("obj=>>", obj);
          // console.log("dataArray=>", filteredArray);

          const actual = [res.data.result.structure.parent];
          setFolder(filteredArray);

          // setAllList(filteredArray);
          // let ad = res.data.result;
          // setMfold(ad);
          // ad.map((i) => {
          //   movedFold = {
          //     label: i.folder,
          //     value: i.id,
          //   };
          //   kk.push(movedFold);
          //   leftFold.push(movedFold);
          // });

          // setMovedFolder(kk);
          // setLeftFolder(leftFold);
        }
      });
  };

  const onSumbit = (value) => {
    console.log(window.location.pathname.split("_"));
    var confToken = "";
    // if (window.location.pathname.split("_")[0] === "/teamleader") {
    confToken = window.localStorage.getItem("tptoken");
    console.log("confToken", confToken);
    // } else if (window.location.pathname.split("_")[1] === "/taxprofessional") {
    //   confToken = window.localStorage.getItem("tptoken");
    //   console.log('tptoken',confToken);
    // }else if (window.location.pathname.split("/")[1] === "taxprofessional_queries") {
    //   confToken = window.localStorage.getItem("tptoken");
    //   console.log('tptoken,under',confToken);
    // }
    let suburl = "createqfolder";
    // if (tab === "assignment") {
    //   suburl = "createqfolderreport";
    // } else {
    // suburl = "createqfolder";
    // }
    console.log("==>", folderid.value);
    let formData = new FormData();
    formData.append("folder", value.p_name);
    // formData.append("q_id", id);
    // formData.append("folder_id", folderid.value);
    formData.append("parent_folder", curFoldDetail?.id);
    // console.log('here');
    axios({
      method: "POST",
      url: `${baseUrl}/tl/createqompliancefolder`,
      headers: {
        uit: confToken,
      },
      data: formData,
    }).then((res) => {
      if (res.data.code === 1) {
        
        setCreatedAt(curFoldDetail);
        // setNeedtoJump(true);
        setNewCreated(true);
        setCreateFolder(false);
        Swal.fire({
          title: "success",
          html: "Folder created successfullly",
          icon: "success",
        });
        
      } else if (res.data.code === 0) {
        // setFolderId("");
        Swal.fire({
          title: "error",
          html: "Folder name already exits",
          icon: "error",
        });
      }
    });
  };

  return (
    <div>
      <Modal isOpen={addPaymentModal} toggle={rejectHandler} size="md">
        <ModalHeader toggle={rejectHandler}>
          <CustomHeading>Create folder</CustomHeading>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSumbit)}>
            <div className="row">
              <div className="col-md-12">
                <label>
                  <strong>Current Folder:</strong>
                </label>
                <label>
                  {``}
                  {curFoldDetail?.name}
                </label>
                {/* <Select
                  onChange={(e) => setFolderId(e)}
                  value={folderid}
                  options={movedFolder}
                ></Select> */}
              </div>
              <div className="col-md-12">
                <label>Folder name</label>
                <input
                  name="p_name"
                  className={classNames("form-control", {
                    "is-invalid": errors.p_name,
                  })}
                  ref={register({ required: true })}
                  type="text"
                />
              </div>
            </div>
            <ModalFooter>
              <button className="autoWidthBtn mr-auto">Submit </button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default CreateFolder;
